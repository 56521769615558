import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 1C16.0523 1 16.5 1.44772 16.5 2V3.5C16.5 4.05228 16.0523 4.5 15.5 4.5C14.9477 4.5 14.5 4.05228 14.5 3.5V2C14.5 1.44772 14.9477 1 15.5 1ZM11.9951 1.95058C12.0497 2.50016 11.6484 2.98992 11.0988 3.04448C6.55188 3.49593 3 7.33379 3 12C3 12.1122 3.00206 12.224 3.00613 12.3353C3.65407 12.4436 4.32011 12.5 5 12.5C8.3057 12.5 11.2994 11.164 13.4706 9H12C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7H15.6078C15.6163 6.99989 15.6248 6.99989 15.6333 7H16C16.5523 7 17 7.44772 17 8V12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12V10.2979C12.4599 12.8899 8.91798 14.5 5 14.5C4.43304 14.5 3.87361 14.4662 3.3237 14.4006C4.37419 18.2057 7.86077 21 12 21C16.6662 21 20.5041 17.4481 20.9555 12.9012C21.0101 12.3516 21.4998 11.9503 22.0494 12.0049C22.599 12.0595 23.0003 12.5492 22.9457 13.0988C22.3937 18.6585 17.7045 23 12 23C5.92487 23 1 18.0751 1 12C1 6.29551 5.34148 1.60627 10.9012 1.05427C11.4508 0.999702 11.9405 1.40099 11.9951 1.95058ZM21.2071 2.79289C21.5976 3.18342 21.5976 3.81658 21.2071 4.20711L20.1464 5.26777C19.7559 5.65829 19.1228 5.65829 18.7322 5.26777C18.3417 4.87724 18.3417 4.24408 18.7322 3.85355L19.7929 2.79289C20.1834 2.40237 20.8166 2.40237 21.2071 2.79289ZM19.5103 8.5C19.5103 7.94772 19.958 7.5 20.5103 7.5H22.0103C22.5625 7.5 23.0103 7.94772 23.0103 8.5C23.0103 9.05228 22.5625 9.5 22.0103 9.5H20.5103C19.958 9.5 19.5103 9.05228 19.5103 8.5Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;
