import { Grid, Typography } from '@mui/material';
import ThreeSixtyHeaderIcon from "../../../assets/images/dynamicSidebar/threeSixtyHeaderIcon";
import MsgIcon from "../../../assets/images/AskForFeedback/msgIcon.svg";

const HeaderWellcomeCard = () => {
  return (
    <Grid className="wel-come-card">
      <Grid className="ac-center">
        <Typography className="wel-come-msg">{"Welcome to Mid Year 2023-24: 360 Feedback Process"}</Typography>
        {/* <Grid>
            <Typography className="paragraph">
              1. Nomination - Click on Ask for feedback to nominate stakeholders
              for 360 feedback. Once you’re done nominating stakeholders, click
              on send approval request.
            </Typography>
            <Typography className="paragraph">
              2. Evaluation - Under Give feedback to others, click on the
              relevant employee name to fill their 360 feedback form.
            </Typography>
          </Grid> */}
      </Grid>
      <Grid className="msg-icon-grid">
        {/* <ThreeSixtyHeaderIcon style={{ height: "50px" }} /> */}
        <img src={MsgIcon} height={40} alt="msgIcon"></img>
      </Grid>
    </Grid>
  );
};

export default HeaderWellcomeCard;
