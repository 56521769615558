import { Button, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { toast } from 'react-toastify';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import APIList from '../../../../api';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { isEmptyNullUndefined } from '../../../utils/utils';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  ListItemText,
} from '@mui/material';

const SendReq = ({peer, setIsOpen, setSearch, getPageData, getSentCount}) => {  

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [submitLoader, setSubmitLoader] = useState(false);
    const [msgErr, setMsgErr] = useState(false);

    const [totalPeerFormList, setTotalPeerFormList] = useState([])
    const [isCritical, setIsCritical] = useState(false)
    const [feedbackFormId, setFeedbackFormId] = useState('')

    // getThreeSixtyPeerFeedback

    useEffect(() => {
      getThreeSixtyPeerFeedbackApi();
    }, []);

    const getThreeSixtyPeerFeedbackApi = async() => {
      try {
        const response = await APIList.getThreeSixtyPeerFeedback(employeeDetails?.company?.id);
        // const response = await APIList.getThreeSixtyPeerFeedback('ca4aed7d-6289-4840-a9b5-fef5c6626ec0');
        
        if (response.status == 200) {
          setTotalPeerFormList(response.data)
        }
      } catch(error) {
        
      }
    }

    let defaultMsg = `<p>
  <p>Dear ${peer?.employeeName},</p>

  <p> I hope this message finds you well.</p>

  <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

   Best regards,
   <p>${employeeDetails?.name}</p>
  </p>`;

  const [message, setMessage] = useState(defaultMsg);

    const handleChange = (value) => {
        setMessage(value);
        setMsgErr(false);
    }


// console.log("message",message)
    const submitRequest = () => {  
      
// console.log("getIsValid()",getIsValid())    
      if(getIsValid()){
        setSubmitLoader(true);
        APIList.sendFeedbackRequest({
          // APIList.po({
            requestedBy:{ 
               id: employeeDetails?.id
            },
            requestedTo:{
                id: peer?.employeeId
            },
            relationship: "Peer",
            message: message,
            // expiryDate: dateExpected,

            isResend: false,
            managerInitiatesPeerFeedback :false,
            initiateManager :null,

            isCritical: ((isCritical === true) || (isCritical === 'true')) ? true : false,
            feedbackFormId,

        })  
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  {` Request sent successfully`}
                </div>
              );
              setSubmitLoader(false);
              setIsOpen(false);
              setSearch("");
              getPageData();
              getSentCount();
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.message}
                </div>
            );
            setSubmitLoader(false);
            setSearch("");
        })
      }                
    }

    const getIsValid = () => {
      let isValid = true;

      if(isEmptyNullUndefined(message)){
        setMsgErr(true);
        isValid = false;
      }
      return isValid;
    }
    
    return(
        <div className="send-feedback-req-drawer-main">
          <div className="empDetaila">
              <div className="empPic">
              {peer?.profilePhotoPath ? 
                  <img src={peer?.profilePhotoPath} className="userImg" alt="User" />
                  :
                  <Avatar 
                      name={peer?.employeeName} 
                      size="45" 
                      className="userImg"
                      color={"#00425A"}    
                  />
              }
              </div>
              <div className="userInfo">
                  <p className="name">{peer?.employeeName}</p>
                  {/* <p className="designation">{peer?.employeeDesignation ? peer?.employeeDesignation : "Designation"}</p> */}
                  <p className="designation">{peer?.employeeDesignation ? peer?.employeeDesignation : ""}</p>
              </div>
          </div>
            <SunEditor
            //  defaultValue={`<p>
            //  <p>Dear ${peer?.employeeName},</p>

            //  <p> I hope this message finds you well.</p>

            //  <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
            //   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

            //   Best regards,
            //   <p>${employeeDetails?.name}</p>
            //  </p>`}
             defaultValue={message}
             autoFocus={true}
             height="20rem"
             setDefaultStyle="font-family: poppins; font-size: 14px;" 
             onChange={handleChange}
             />
                {msgErr && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    Please write message
                  </FormHelperText>
                )}
       
              <div style={{marginBottom: '1rem'}} />
              <Stack sx={{width: '50%'}}>
                <FormControl>
                  <InputLabel size='small' id='label_employeeTypes'>
                    Select feedback form to be send.
                  </InputLabel>
                  <Select
                    name='feedbackFormId'
                    labelId='label_employeeTypes'
                    label='Selection menu dynamic type'
                    value={feedbackFormId}
                    onChange={(e) => setFeedbackFormId(e.target.value)}
                    variant='outlined'
                    size='small'
                    sx={{
                      backgroundColor: 'white',
                      mb: 2,
                      '& fieldset': {
                        borderLeftColor: 'red',
                        borderLeftWidth: 3,
                      },
                    }}
                  >
                    {totalPeerFormList.map((item) => (
                      <MenuItem key={item.displayName} value={item.id}>
                        <ListItemText primary={item.displayName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                  direction='row'
                  // sx={{mt: 2, ml: 3}}
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={2}
                >
                  <Stack sx={{width: '50%'}}>
                    <FormLabel id='demo-row-radio-buttons-group-label'>
                      <Stack direction='row'>
                        <Stack fontWeight={500}>
                          {'Is this feedback critical ?'}
                        </Stack>
                      </Stack>
                    </FormLabel>
                  </Stack>
                  <Stack sx={{width: '50%'}}>
                    <RadioGroup
                      name='isCritical'
                      value={isCritical}
                      onChange={(e) => setIsCritical(e.target.value)}
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={'yes'}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={'no'}
                      />
                    </RadioGroup>
                  </Stack>
                </Stack>
            <div className="send-btn-div">
             <Button 
                variant="contained"
                sx={{
                    color:"var(--secondary)",
                    backgroundColor:"var(--primary)",
                    borderColor:"var(--primary)",
                    "&:hover":{
                        backgroundColor:"#035c7c",
                        borderColor:"#035c7c",
                    },
                    // marginBottom:"1.5rem",
                    width:"7rem",
                }}
                disabled={submitLoader || isEmptyNullUndefined(feedbackFormId)}
                onClick={submitRequest}
                >
                    Send                                     
                </Button>
                </div>
        </div>
    )
}

SendReq.propTypes = {
  peer: PropTypes.object,
  setIsOpen: PropTypes.func,
  setSearch: PropTypes.func,
  getPageData: PropTypes.func,
  getSentCount: PropTypes.func,
};

export default SendReq;