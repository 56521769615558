import React  from 'react';
import { InputBase, alpha, styled } from "@mui/material";
import moment from "moment";

export const isEmptyNullUndefined = (value) => {
  if (
    value === undefined ||
    value === "" ||
    (value && value.toString().trim() === "") ||
    value === null 
  ) {
    return true;
  } else {
    return false;
  }
};

export function CurrencyFormat(amount,currency) {
  if(amount == null){
    return "";
  }else{
    if (currency === "INR") {
      return `${parseFloat(amount).toLocaleString("en-IN")}`;
    } else {
      return `${parseFloat(amount).toLocaleString("en-US")}`;
    }
  }
  };

export const textAreaStyle = {
  width:"100%",
  // my: 4,
  mt: 2,
  backgroundColor:"#F9F9F9",
  borderRadius:"1rem",
  "& fieldset": {
      border: "1px solid #F9F9F9",
      borderRadius:"1rem",                                    
    },
};

export const ratings = ["Outstanding", "Fantastic", "Average", "Poor"];

export const values = ["value1", "value2", "value3", "value4", "value5", "value6", "value7", "value8", "value9", "value10", "value11", "value12"];

export const valuesFeedback = ["Customer Obsession", "Speed", "End to End Ownership", "Execution Excellence", "Hire & Develop the Best", "Think Big, Think Long Term", "Invent & Simplify to Do More with Less", "Dive Deep, Be Hands On", "Be Bold, Disagree & Commit", "Pride in the Purpose of Our Work","Earn Trust & Be Vocally Self Critical","Make High Quality Decisions"];
export const myAssesmentvalues = ["Customer Obsession", "Speed", "End to End Ownership", "Execution Excellence", "Hire & Develop the Best", "Think Big, Think Long Term", "Invent & Simplify to Do More with Less", "Dive Deep, Be Hands On", "Be Bold, Disagree & Commit", "Pride in the Purpose of Our Work", "Earn Trust & Be Vocally Self Critical", "Make High Quality Decisions"];

export const truncatePath = (url) => {
  // this will replace /manager-feedBack/8278de5d-ac6f-4b89-96bd-f89e05131a51 into --> /manager-feedBack
  const temp = JSON.parse(JSON.stringify(url))

  const lastSlashIndex = temp.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    // temp.substring(0, lastSlashIndex);
    temp.slice(0, lastSlashIndex);
    if(temp.substring(0, lastSlashIndex)) {
      return temp.substring(0, lastSlashIndex)
    } else {
      return url
    }
  }
};

export const getHeaderTitle = () => {

  const path = window.location.pathname;

  if (path === "/self-assesment/allinone") {
    return "My Assessment";
  } else if (path === "/profile") {
    return "My Profile";
  }else if (path === "/dashboard") {
    return "Dashboard";
  }else if (path === "/my-team") {
    return "My Team";
  }else if (path === "/change-password") {
    return "Change Password";
  }else if (path?.includes("emp-feedback")) {
    return "Feedback";
  } else if (path === "/self-assesment/request-feedback") {
    return "Request Feedback";
  } else if (path === "/give-peer-feedback") {
    return "Give Feedback";
  } else if (path === "/add-session") {
    return "Add Session";
  } else if (truncatePath(path) === "/peer-feedBack") {
    return "Peer FeedBack";
  } else if (truncatePath(path) === "/manager-feedBack") {
    return "Manager FeedBack";
  } else if (path === "/hrb") {
    return "Rating Calibration";
  } else if (path === "/self-assesment/aprove") {
    // return "Approve And Suggest";
    return "Approve";
  } else {
    return "";
  }
 
};

export const getHeaderMidTitle = () => {
  const path = window.location.pathname;
  if (path?.includes("emp-feedback")) {
    return "Mid year MBSR Check-in";
  } else {
    // return "H1 2024 MBSR";
    return "Mid year MBSR Check-in";
  }
}

export const accordianSVGstyle = {
  borderRadius:"1rem", 
  backgroundColor:"#ffffff606060", 
  color:"#606060", 
  fontSize:"1.5rem", 
  padding:"0.1rem", 
  margin:"auto 0px",
  transitionDuration:"700ms"
};

export const getFormattedDate = (date, format) => {
  return moment(date).format(format?.toUpperCase());
};

export const getFormattedTime = (date, format) => {
  return moment(date).format(format);
};

function capitalizeEveryWord(str) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words back into a string
  const result = capitalizedWords.join(' ');

  return result;
};

export function breakCamelCase(str) {
  // Use a regular expression to find all uppercase letters and insert a space before them
  return capitalizeEveryWord(str.replace(/([A-Z])/g, ' $1'));
};

export const getListItemDescription = (selectList, optionList, fieldName) => {
  const discriptionList = [];
  if (selectList.length > 0) {
    selectList?.map((item) => {
      let toCompare;
      if (fieldName == 'planName') {
        toCompare = typeof item === 'object' ? item?.plan?.id : item;
      } else {
        toCompare = typeof item === 'object' ? item?.id : item;
      }

      const option = optionList.find((option) => option.id === toCompare);

      // fieldName=="planName" && console.log("tocompare",toCompare , optionList,item);
      if (option != undefined) {
        discriptionList.push(option["name"]);
      }
    });
  }
  return discriptionList.toString();
};

export const assesmentDummyData = {
  "approvalDate": "2023-11-16",
  "employeeId": 1,
  "goalList": [
    {
      "id": "AP1G1",
      "name": "string",
	  comments: []
    },
    {
      "id": "AP1G2",
      "name": "string",
	  comments: []
    }
  ],
  "id": "AP01",
  "status": "string",
  "submittedDate": "2023-11-16",
  "values": 
    {
      "challenges": "string challenges",
      "demonstrated": "string",
      "demonstratedValues": [
        "string","string2"
      ],
      "keyAchievement": "string keyAchievement",
      "learningSupport": "string learningSupport",
      "notDemonstrated": "string",
      "notDemonstratedValues": [
        "stringN"
      ]
    }
}

export const onlyAcceptWholeNumbers = (number) => {
  return number.replace(/[^0-9]/g, '');
};

export const onlyAcceptOneToNineWithDecimal = (number) => {
  return number.replace(/[^0-9.]|(?<=\..*)\./g, '');
};

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  margin:"1rem 0px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: '0px !important',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex:1 ,
  color:"#979797"
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  // border:"1px solid var(--primary)",
  // boxShadow:"0px 0px 6px 1px #e1e1e1",
  height: "3.7rem !important",
  backgroundColor: "#ffffff",
  borderRadius:"10px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    width: '15rem',
    // minWidth:"5rem"
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch',
    //   },
    // },
  },
}));  

export const removeEmptyArrays = (obj) => {
  for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
          delete obj[key];
      }
  }
  return obj;

}

export const camelToCamelCase = (str) => {
  // Insert a space before all uppercase letters and convert the string to lowercase
  const spaced = str.replace(/([A-Z])/g, ' $1');
  // Capitalize the first letter of the string
  const result = spaced.charAt(0).toUpperCase() + spaced.slice(1);
  return result;
}

export const returnNameByKey = (selectedOption) => {
  if(selectedOption == 'employeeLevel') {
      return 'Role Name'
  } else if(selectedOption == 'employeeGrade') {
      return 'Grade'
  } else if(selectedOption == 'employeeSubfunction') {
      return 'Sub Department'
  } else if(selectedOption == 'jobCode') {
      return 'Job Code'
  } else if(selectedOption == 'pod') {
      return 'Pod'
  } else if(selectedOption == 'phonepeTenure') {
      return 'PhonePe Tenure'
  } else if(selectedOption == 'employeeType') {
      return 'Employee Type'
  } else if(selectedOption == 'employementStatus') {
      return 'Employement Status'
  } else if(selectedOption == 'employeeFunction') {
      return 'Employee Function'
  } else if(selectedOption == 'employeeStatus') {
      return 'Employee Status'
  } else if(selectedOption == 'timeSinceLastPromotion') {
    return 'Time Since Last Promotion'
  } else if(selectedOption == 'gender') {
    return 'Gender'
  } else if(selectedOption == 'employeeGender') {
    return 'Employee Gender'
  } else {
    return camelToCamelCase(selectedOption)
  }
}

export const getObjById = (id, array) => {
  let obj = array.find((e,i) => e.id == id)
  console.log('obj', obj)
  if(!isEmptyNullUndefined(obj)) {
    return obj
  } else {
    return null
  }
}

export const sortStringAscending = (list) => {
  return list.sort((a,b) => a.localeCompare(b))
}

export const sortStringAscendingByType = (list, type) => {
  return list.sort((a,b) => a[type].localeCompare(b[type]))
}

export const getListOfArrayFromObjKey = (obj) => {
  let objKey = Object.keys(obj);
  let sortedObjKey = sortStringAscending(objKey)
  return sortedObjKey
}

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getPlainText = (htmlString) => {
 
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  
  return tempDiv.textContent.replace(/\s+/g, '').trim();
};

export const options = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "|",
  "ul",
  "ol",
  "|",
  "font",
  "fontsize",
  "paragraph",
  "|",
  "outdent",
  "indent",
  "align",
  "|",
  "hr",
  "|",
  "brush",
  "|",
  "undo",
  "redo",
];