import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import plusIcon from "../../../../assets/images/pluswhiteicon.svg";
import edit1 from "../../../../assets/images/edit.svg";
import notes1 from "../../../../assets/images/notes.svg";
import close from "../../../../assets/images/close.svg";
import pdfDownload from "../../../../assets/images/pdfDownload.svg";
import dot from "../../../../assets/images/dot.svg";
import Goals from "../../../../assets/images/goals.png";
import { LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import { isEmptyNullUndefined } from "../../../utils/utils";
import OutputGoals from "./OutputGoals";
import GrowthDevelopmentGoals from "./GrowthDevelopmentGoals";

const MyPlan = ({}) => {
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [viewMoreType, setViewMoreType] = useState("");
  const [competencyFeedbackLoader, setCompetencyFeedbackLoader] =
    useState(false);
  const [selectedRole, setSelectedRole] = useState("All");
  const [competencyFeedbackData, setCompetencyFeedbackData] = useState(null);
  const [empId, setEmpId] = useState("");
  const [promisedata, setPromisedata] = useState([]);
  const [profileinfodata, setProfileinfodata] = useState({});
  const [aspirationInterests, setAspirationInterests] = useState(false);

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const [AccordionState, setAccordionState] = useState({
    outputGoals: {
      open: [false, false],
      completed: [false, false, false],
    },
    grwthDev: {
      open: [false, false],
      completed: [false, false],
    },
    goals: {
      open: [false, false, false],
      close: [false, false, false],
    },
  });

  const [active, setActive] = useState("open");
  const [OutputGoalsActiveTab, setOutputGoalsActiveTab] = useState("open");
  const [checkboxState, setCheckboxState] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
  });
  console.log("AccordionState", AccordionState);
  // Handle the change of the checkbox based on id
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: checked, // Update the specific checkbox by its id
    }));
  };

  const handleChangeAccordian =
    (panel, accName, index = 0) =>
    (event, isExpanded) => {
      let tempState = { ...AccordionState };

      let selectedAcc = tempState[accName][panel];
      selectedAcc[index] = isExpanded;

      setAccordionState(tempState);
    };

  const togglePanel = (panel, accName, index) => {
    let temp = { ...AccordionState };

    let selectedAcc = temp[accName][panel];
    //temp[accName][index] = !temp[accName][index]
    selectedAcc[index] = !selectedAcc[index];
    setAccordionState(temp);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedCompetency(null);
    setViewMoreType("");
  };

  const uniqueAndSort = (data, key) => {
    if (!data || !data.length) return [];

    // Combine data by the key (e.g., reviewYear)
    const combinedData = data.reduce((acc, curr) => {
      if (key == "ratingName") {
        console.log("acc --------", acc);
        console.log("curr --------", curr);
      }

      if (!acc[curr[key]]) {
        acc[curr[key]] = { [key]: curr[key], items: [] };
      }
      acc[curr[key]].items.push(curr);
      return acc;
    }, {});

    // Convert the combined object to an array and sort by the key in descending order
    return Object.values(combinedData).sort((a, b) => b[key] - a[key]);
  };

  const handleAspirationInterestsOpen = () => {
    setAspirationInterests(true);
  };

  const handleAspirationInterestsClose = () => {
    setAspirationInterests(false);
  };

  const [goals, setGoals] = useState([]);
  const [key, setKey] = useState(null);

  // Function to handle the click and add a goal
  const handleAddGoal = (goalId) => {
    setGoals((prevGoals) => [...prevGoals, goalId]);
    setKey(null);
  };

  const handleEditClick = (goalIndex, key) => {
    setGoals((prevGoals) => [...prevGoals, goalIndex]);
    setKey(key);
  };

  console.log("__key", {
    goals,
    key,
  });
  const dummyData = [
    {
      id: 1,
      goalName: "Drive closure of IDP Project",
      measureOfSuccess:
        "Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.",
      isComplete: false,
    },
  ];

  const GrowthdummyData = {
    goalName: "Data Engineering - More complex Projects",
    markAsComplete: true,
    taskType: "task",
    taskName: "Build a ToDo App",
    courseName: "React for Beginners",
    courseLink: "https://example.com/react-course",
    courseDetails: "A comprehensive course on React.",
    learningThroughAssistance: "yes",
    courseFeeCurrency: "USD",
    courseFee: 100,
    mentorshipTitle: "Software Development Mentorship",
    mentorDetails: {
      employeeID: "123456",
      name: "John Doe",
      designation: "Senior Developer",
      department: "Engineering",
    },
  };
  const InitialGrowthdummyData = {
    goalName: "",
    markAsComplete: true,
    taskType: "task",
    taskName: "",
    courseName: "",
    courseLink: "",
    courseDetails: "",
    learningThroughAssistance: "yes",
    courseFeeCurrency: "USD",
    courseFee: 100,
    mentorshipTitle: "",
    mentorDetails: {
      employeeID: "",
      name: " ",
      designation: "",
      department: "",
    },
  };

  return (
    <Grid container>
      {goals && goals.length > 0 ? (
        goals?.[0] === 1 ? (
          <>
            <Grid item xs={12} md={12} sm={12}>
              <OutputGoals
                AddInputGoals={goals}
                setGoals={setGoals}
                editedKey={key}
                prevValue={dummyData}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} sm={12}>
              <GrowthDevelopmentGoals
                AddInputGoals={goals}
                setGoals={setGoals}
                editedKey={key}
                prevValue={
                  key === "edit" ? GrowthdummyData : InitialGrowthdummyData
                }
              />
            </Grid>
          </>
        )
      ) : (
        <>
          {/*------ 1. Output Goals ------*/}
          <Grid item xs={12} md={12} sm={12}>
            <div className="my-plan-card">
              <div className="d-flex w-100 justify-content-between">
                <div className="asas ratingTitle">
                  <p className="title">1. Output Goals</p>
                </div>
                <div className="flex-end">
                  <Button
                    className="addgoalbtn"
                    onClick={() => handleAddGoal(1)}
                  >
                    {" "}
                    <img src={plusIcon} /> Add Goal
                  </Button>
                </div>
              </div>
              <div className="btnStrip">
                <div className="btnInline">
                  <Button
                    className={
                      active === "open" ? "openBtn active" : "completedBtn"
                    }
                    onClick={() => setActive("open")}
                  >
                    Open
                  </Button>
                  <Button
                    className={
                      active === "completed" ? "openBtn active" : "completedBtn"
                    }
                    onClick={() => setActive("completed")}
                  >
                    Completed
                  </Button>
                </div>
              </div>
              {active === "open" && (
                <div className="ratingOuter">
                  <div className="rating">
                    <div>
                      {(() => {
                        if (AccordionState["outputGoals"]["open"][0] == true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("open", "outputGoals", 0);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("open", "outputGoals", 0);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["outputGoals"]["open"][0]}
                        onChange={handleChangeAccordian(
                          "open",
                          "outputGoals",
                          0
                        )}
                      >
                        <AccordionSummary
                          //
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              1.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Drive closure of IDP Project 1
                                  </span>
                                </p>
                                <p className="created">
                                  Created on :{" "}
                                  <span className="dateTitle">
                                    March 12, 2024
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div class="form-check checkbox">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="check6"
                                    name="option1"
                                    value="something"
                                    checked={checkboxState.check6}
                                    onChange={handleCheckboxChange}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="check6"
                                  >
                                    Mark as Complete
                                  </label>
                                </div>
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes </p>
                                  </div>
                                </div>
                                <div className="contact">
                                  <div
                                    className="mailOuter"
                                    onClick={() => handleEditClick(1, "edit")}
                                  >
                                    <img src={edit1} />
                                    <p className="mail">Edit</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <p className="created">Goal Name</p>
                            <p className="created">
                              <span className="dateTitle">
                                Drive closure of IDP Project
                              </span>
                            </p>
                          </div>
                          <div className="movementJourney">
                            <p className="created">Measure of Success</p>
                            <p className="created">
                              <span className="dateTitle">
                                Gain an immersive understanding of the practices
                                and skills needed to succeed in an entry-level
                                project management role. Learn how to create
                                effective project documentation and artifacts
                                throughout the various phases of a project.
                              </span>
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {selectedCompetency && (
                      <Dialog
                        open={showPopup}
                        onClose={handleClosePopup}
                        aria-labelledby="popup-title"
                      >
                        {/* <DialogTitle id="popup-title">{selectedCompetency.annualCycleName}</DialogTitle> */}
                        <DialogContent>
                          <DialogActions>
                            <img src={close} onClick={handleClosePopup}></img>
                            {/* <Button onClick={handleClosePopup} color="primary">Close</Button> */}
                          </DialogActions>
                          {viewMoreType === "strengthAreaManager" && (
                            <DialogContentText>
                              <strong>Strength Area by Manager:</strong>{" "}
                              {selectedCompetency.strengthAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "strengthAreaPeers" && (
                            <DialogContentText>
                              <strong>Strength Area by Peers:</strong>{" "}
                              {selectedCompetency.strengthAreaPeers}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaManager" && (
                            <DialogContentText>
                              <strong>Development Area by Manager:</strong>{" "}
                              {selectedCompetency.developmentAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaPeers" && (
                            <DialogContentText>
                              <strong>Development Area by Peers:</strong>{" "}
                              {selectedCompetency.developmentAreaPeers}
                            </DialogContentText>
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                  <div className="rating">
                    <div>
                      {(() => {
                        if (AccordionState["outputGoals"]["open"][1] == true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();

                                togglePanel("open", "outputGoals", 1);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("open", "outputGoals", 1);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner border-0">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["outputGoals"]["open"][1]}
                        onChange={handleChangeAccordian(
                          "open",
                          "outputGoals",
                          1
                        )}
                      >
                        <AccordionSummary
                          //
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              2.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Working on Influencing / Building Consensus
                                  </span>
                                </p>
                                <p className="created">
                                  Created on :{" "}
                                  <span className="dateTitle">
                                    March 12, 2024
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div class="form-check checkbox">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="check5"
                                    name="option1"
                                    value="something"
                                    checked={checkboxState.check5}
                                    onChange={handleCheckboxChange}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="check5"
                                  >
                                    Mark as Complete
                                  </label>
                                </div>
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes ty</p>
                                  </div>
                                </div>
                                <div className="contact">
                                  <div
                                    className="mailOuter"
                                    onClick={() => handleEditClick(1, "edit")}
                                  >
                                    <img src={edit1} />
                                    <p className="mail">Edit</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {competencyFeedbackLoader ? (
                            <>
                              <Skeleton variant="rounded" height={"347px"} />
                            </>
                          ) : selectedRole == "All" ? (
                            <>
                              <div className="movementJourney">
                                <p className="created">Goal Name</p>
                                <p className="created">
                                  <span className="dateTitle">
                                    Drive closure of IDP Project
                                  </span>
                                </p>
                              </div>
                              <div className="movementJourney">
                                <p className="created">Measure of Success</p>
                                <p className="created">
                                  <span className="dateTitle">
                                    Gain an immersive understanding of the
                                    practices and skills needed to succeed in an
                                    entry-level project management role. Learn
                                    how to create effective project
                                    documentation and artifacts throughout the
                                    various phases of a project.
                                  </span>
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              {(() => {
                                const combinedCompetencyYear = uniqueAndSort(
                                  competencyFeedbackData,
                                  "reviewYear"
                                );
                                const combinedCompetencyName = uniqueAndSort(
                                  competencyFeedbackData,
                                  "competencyName"
                                );

                                return (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th className="left">
                                          Input / Competency Name
                                        </th>
                                        {combinedCompetencyYear.length > 0 &&
                                          combinedCompetencyYear.map((yr) => (
                                            <th>{yr.reviewYear}</th>
                                          ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {combinedCompetencyName.length > 0 ? (
                                        combinedCompetencyName.map(
                                          (cfGroup, index) => (
                                            <tr key={`${index}-4`}>
                                              <td className="bg left">
                                                {cfGroup.competencyName}
                                              </td>
                                              {cfGroup.items.map(
                                                (cf) =>
                                                  combinedCompetencyYear.length >
                                                    0 &&
                                                  combinedCompetencyYear.map(
                                                    (yr) =>
                                                      yr.reviewYear ===
                                                        cf.reviewYear && (
                                                        <td>
                                                          {cf.ratingDescription}
                                                        </td>
                                                      )
                                                  )
                                              )}
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No data available</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                );
                              })()}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              )}
              {active === "completed" && (
                <div className="ratingOuter">
                  <div className="rating">
                    <div>
                      {(() => {
                        if (
                          AccordionState["outputGoals"]["completed"][0] == true
                        ) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();

                                togglePanel("completed", "outputGoals", 0);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "outputGoals", 0);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["outputGoals"]["completed"][0]}
                        onChange={handleChangeAccordian(
                          "completed",
                          "outputGoals",
                          0
                        )}
                      >
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              1.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Drive closure of IDP Project
                                  </span>
                                </p>
                                <p className="created">
                                  Created on :{" "}
                                  <span className="dateTitle">
                                    March 12, 2024
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <p className="created">Goal Name</p>
                            <p className="created">
                              <span className="dateTitle">
                                Drive closure of IDP Project
                              </span>
                            </p>
                          </div>
                          <div className="movementJourney">
                            <p className="created">Measure of Success</p>
                            <p className="created">
                              <span className="dateTitle">
                                Gain an immersive understanding of the practices
                                and skills needed to succeed in an entry-level
                                project management role. Learn how to create
                                effective project documentation and artifacts
                                throughout the various phases of a project.
                              </span>
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="rating">
                    <div>
                      {(() => {
                        if (
                          AccordionState["outputGoals"]["completed"][1] === true
                        ) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "outputGoals", 1);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "outputGoals", 1);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["outputGoals"]["completed"][1]}
                        onChange={handleChangeAccordian(
                          "completed",
                          "outputGoals",
                          1
                        )}
                      >
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              2.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Drive closure of IDP Project 1
                                  </span>
                                </p>
                                <p className="created">
                                  Created on :{" "}
                                  <span className="dateTitle">
                                    March 12, 2024
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <p className="created">Goal Name</p>
                            <p className="created">
                              <span className="dateTitle">
                                Drive closure of IDP Project
                              </span>
                            </p>
                          </div>
                          <div className="movementJourney">
                            <p className="created">Measure of Success</p>
                            <p className="created">
                              <span className="dateTitle">
                                Gain an immersive understanding of the practices
                                and skills needed to succeed in an entry-level
                                project management role. Learn how to create
                                effective project documentation and artifacts
                                throughout the various phases of a project.
                              </span>
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {selectedCompetency && (
                      <Dialog
                        open={showPopup}
                        onClose={handleClosePopup}
                        aria-labelledby="popup-title"
                      >
                        {/* <DialogTitle id="popup-title">{selectedCompetency.annualCycleName}</DialogTitle> */}
                        <DialogContent>
                          <DialogActions>
                            <img src={close} onClick={handleClosePopup}></img>
                            {/* <Button onClick={handleClosePopup} color="primary">Close</Button> */}
                          </DialogActions>
                          {viewMoreType === "strengthAreaManager" && (
                            <DialogContentText>
                              <strong>Strength Area by Manager:</strong>{" "}
                              {selectedCompetency.strengthAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "strengthAreaPeers" && (
                            <DialogContentText>
                              <strong>Strength Area by Peers:</strong>{" "}
                              {selectedCompetency.strengthAreaPeers}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaManager" && (
                            <DialogContentText>
                              <strong>Development Area by Manager:</strong>{" "}
                              {selectedCompetency.developmentAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaPeers" && (
                            <DialogContentText>
                              <strong>Development Area by Peers:</strong>{" "}
                              {selectedCompetency.developmentAreaPeers}
                            </DialogContentText>
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                  <div className="rating">
                    <div>
                      {(() => {
                        if (
                          AccordionState["outputGoals"]["completed"][2] === true
                        ) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "outputGoals", 2);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "outputGoals", 2);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner border-0">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["outputGoals"]["completed"][2]}
                        onChange={handleChangeAccordian(
                          "completed",
                          "outputGoals",
                          2
                        )}
                      >
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              3.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Working on Influencing / Building Consensus
                                  </span>
                                </p>
                                <p className="created">
                                  Created on :{" "}
                                  <span className="dateTitle">
                                    March 12, 2024
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {competencyFeedbackLoader ? (
                            <>
                              <Skeleton variant="rounded" height={"347px"} />
                            </>
                          ) : selectedRole == "All" ? (
                            <>
                              <div className="movementJourney">
                                <p className="created">Goal Name</p>
                                <p className="created">
                                  <span className="dateTitle">
                                    Drive closure of IDP Project
                                  </span>
                                </p>
                              </div>
                              <div className="movementJourney">
                                <p className="created">Measure of Success</p>
                                <p className="created">
                                  <span className="dateTitle">
                                    Gain an immersive understanding of the
                                    practices and skills needed to succeed in an
                                    entry-level project management role. Learn
                                    how to create effective project
                                    documentation and artifacts throughout the
                                    various phases of a project.
                                  </span>
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              {(() => {
                                const combinedCompetencyYear = uniqueAndSort(
                                  competencyFeedbackData,
                                  "reviewYear"
                                );
                                const combinedCompetencyName = uniqueAndSort(
                                  competencyFeedbackData,
                                  "competencyName"
                                );

                                return (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th className="left">
                                          Input / Competency Name
                                        </th>
                                        {combinedCompetencyYear.length > 0 &&
                                          combinedCompetencyYear.map((yr) => (
                                            <th>{yr.reviewYear}</th>
                                          ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {combinedCompetencyName.length > 0 ? (
                                        combinedCompetencyName.map(
                                          (cfGroup, index) => (
                                            <tr key={`${index}-4`}>
                                              <td className="bg left">
                                                {cfGroup.competencyName}
                                              </td>
                                              {cfGroup.items.map(
                                                (cf) =>
                                                  combinedCompetencyYear.length >
                                                    0 &&
                                                  combinedCompetencyYear.map(
                                                    (yr) =>
                                                      yr.reviewYear ===
                                                        cf.reviewYear && (
                                                        <td>
                                                          {cf.ratingDescription}
                                                        </td>
                                                      )
                                                  )
                                              )}
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3">No data available</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                );
                              })()}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>

          {/*----- 2. Growth / Development Goals -----*/}
          <Grid item xs={12} md={12} sm={12}>
            <div className="my-plan-card">
              <div className="ratingOuter">
                <div className="d-flex w-100 justify-content-between">
                  <div className="asas ratingTitle">
                    <p className="title">2. Growth / Development Goals</p>
                  </div>
                  <div className="flex-end">
                    <Button
                      className="addgoalbtn"
                      onClick={() => handleAddGoal(2)}
                    >
                      {" "}
                      <img src={plusIcon} /> Add Goal
                    </Button>
                  </div>
                </div>

                <div className="btnStrip">
                  <div className="btnInline">
                    <Button
                      // className="openBtn"
                      className={
                        OutputGoalsActiveTab === "open"
                          ? "openBtn active"
                          : "completedBtn"
                      }
                      onClick={() => setOutputGoalsActiveTab("open")}
                    >
                      Open
                    </Button>
                    <Button
                      // className="completedBtn"
                      className={
                        OutputGoalsActiveTab === "completed"
                          ? "openBtn active"
                          : "completedBtn"
                      }
                      onClick={() => setOutputGoalsActiveTab("completed")}
                    >
                      Completed
                    </Button>
                  </div>
                </div>

                {(() => {
                  if (
                    isEmptyNullUndefined(employeeDetails?.company?.id) ||
                    isEmptyNullUndefined(profileinfodata)
                  ) {
                    return;
                  }

                  if (employeeDetails.id == empId) {
                    if (profileinfodata.employeeLevel == "Tech") {
                      return;
                    }
                  }

                  return (
                    <>
                      {OutputGoalsActiveTab === "open" && (
                        <div className="rating   ">
                          <div>
                            {(() => {
                              if (
                                AccordionState["grwthDev"]["open"][0] === true
                              ) {
                                return (
                                  <LuMinusCircle
                                    style={{
                                      cursor: "pointer",
                                      width: "25px",
                                      color: "#7F56D9",
                                      height: "25px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      togglePanel("open", "grwthDev", 0);
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <LuPlusCircle
                                    style={{
                                      cursor: "pointer",
                                      width: "25px",
                                      color: "#7F56D9",
                                      height: "25px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      togglePanel("open", "grwthDev", 0);
                                    }}
                                  />
                                );
                              }
                            })()}
                          </div>
                          <div className="ratingInner">
                            <Accordion
                              sx={{
                                boxShadow: "none !important",
                                cursor: "no",
                                "& .MuiAccordionSummary-root": {
                                  paddingLeft: 0,
                                  boxShadow: "none !important",
                                  "& .MuiAccordionSummary-content": {
                                    marginTop: 0,
                                    paddingTop: 0,
                                    alignSelf: "flex-start",
                                  },
                                },
                              }}
                              expanded={AccordionState["grwthDev"]["open"][0]}
                              onChange={handleChangeAccordian(
                                "open",
                                "grwthDev",
                                0
                              )}
                            >
                              <AccordionSummary
                                sx={{
                                  // pointerEvents: "none",
                                  marginBottom: "0px",
                                }}
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <div className="d-flex w-100 justify-content-between">
                                  <div className="asas ratingTitle">
                                    1.
                                    <div className="sdsd">
                                      <p className="ratingTitle">
                                        <span className="name">
                                          {" "}
                                          Data Engineering - More complex
                                          Projects
                                        </span>
                                      </p>
                                      <div className="d-flex w-100 align-items-center">
                                        <p className="created mb-0">
                                          Activities:{" "}
                                          <span className="dateTitle">1</span>
                                        </p>{" "}
                                        <img className="dotIcon" src={dot} />{" "}
                                        <p className="created mb-0">
                                          Created on :{" "}
                                          <span className="dateTitle">
                                            March 12, 2024
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex-end">
                                    <div className="d-flex w-100 gap-20 justify-content-between">
                                      <div class="form-check checkbox">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="check4"
                                          name="option1"
                                          value="something"
                                          checked={checkboxState.check4}
                                          onChange={handleCheckboxChange}
                                        />
                                        <label
                                          class="form-check-label"
                                          htmlFor="check4"
                                        >
                                          Mark as Complete
                                        </label>
                                      </div>
                                      <div className="contact1">
                                        <div className="mailOuter">
                                          <img src={notes1} />
                                          <p className="mail">Notes</p>
                                        </div>
                                      </div>
                                      <div
                                        className="contact"
                                        onClick={() =>
                                          handleEditClick(2, "edit")
                                        }
                                      >
                                        <div className="mailOuter">
                                          <img src={edit1} />
                                          <p className="mail">Edit</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="movementJourney b-bottom mb-0 pb-2 mt-0">
                                  <p className="created">Goal Name</p>
                                  <p className="created">
                                    <span className="dateTitle">
                                      Drive closure of IDP Project
                                    </span>
                                  </p>
                                </div>
                                {/* <div className="movementJourney">
                                                    <p className="created">Measure of Success</p>
                                                    <p className="created"><span className="dateTitle">Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.</span></p>
                                                </div> */}

                                {/*----- Activities -----*/}
                                <div className="rating b-bottom gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["open"][0] ===
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();

                                              togglePanel("open", "goals", 0);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("open", "goals", 0);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["open"][0]
                                      }
                                      onChange={handleChangeAccordian(
                                        "open",
                                        "goals",
                                        0
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span> Activities</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Live, 0 Completed
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {/* <div className="performanceCard">
                                          {promisedata?.performanceForms
                                            ?.length > 0 &&
                                            promisedata?.performanceForms?.map(
                                              (pf) => (
                                                <div className="card">
                                                  <div className="top">
                                                    <p className="year">
                                                      {pf?.year}
                                                    </p>
                                                    <a
                                                      href={pf.path}
                                                      target="_blank"
                                                    >
                                                      <img src={pdfDownload} />
                                                    </a>
                                                  </div>
                                                  <p className="fileName">
                                                    {pf?.fileName}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div> */}

                                        <div className="activities-outter">
                                          {/*----- Tasks -----*/}
                                          <Typography className="tasks-title-label">
                                            {"Tasks"}
                                          </Typography>
                                          <div className="activities-inner">
                                            <div>
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            <div className="act-b-bottom">
                                              <Typography className="field-label">
                                                {"Task Name"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {"Project Management Chapter 1"}
                                              </Typography>
                                            </div>
                                          </div>
                                          <div className="activities-inner">
                                            <div className="h-60per">
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            <div className="act-b-bottom">
                                              <Typography className="field-label">
                                                {"Task Name"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {"Project Management Chapter 2"}
                                              </Typography>
                                            </div>
                                          </div>

                                          {/*----- Courses -----*/}
                                          <Typography className="tasks-title-label act-mt-15">
                                            {"Courses"}
                                          </Typography>
                                          <div className="activities-inner">
                                            <div>
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            <div className="act-b-bottom">
                                              {/*----- Course Name -----*/}
                                              <Typography className="field-label">
                                                {"Course Name"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "Development of Python logarithms"
                                                }
                                              </Typography>

                                              {/*----- Course Link -----*/}
                                              <Typography className="field-label act-mt-15">
                                                {"Course Link"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "https://www.coursera.org/learn/python-for-application"
                                                }
                                              </Typography>

                                              {/*----- Course Details -----*/}
                                              <Typography className="field-label act-mt-15">
                                                {"Course Details"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project. "
                                                }
                                              </Typography>

                                              <Grid container>
                                                {/*----- Through Learning Assistance Program -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Through Learning Assistance Program"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"Yes"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Approved -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Approved"}
                                                  </Typography>
                                                  <div className="d-flex-gap">
                                                    <Typography className="field-value value-border">
                                                      {"Manager"}
                                                    </Typography>
                                                    <Typography className="field-value value-border">
                                                      {"HRBP"}
                                                    </Typography>
                                                  </div>
                                                </Grid>

                                                {/*----- Course Fee Currency -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Course Fee Currency"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"USD ($)"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Course Fee -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Course Fee"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"1,000"}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>

                                          <div className="activities-inner">
                                            <div className="h-90per">
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            {/*----- Course Name -----*/}
                                            <div className="act-b-bottom">
                                              <Typography className="field-label">
                                                {"Course Name"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "Development of Python logarithms 2"
                                                }
                                              </Typography>

                                              {/*----- Course Link -----*/}
                                              <Typography className="field-label act-mt-15">
                                                {"Course Link"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "https://www.coursera.org/learn/python-for-application2"
                                                }
                                              </Typography>

                                              {/*----- Course Details -----*/}
                                              <Typography className="field-label act-mt-15">
                                                {"Course Details"}
                                              </Typography>
                                              <Typography className="field-value">
                                                {
                                                  "Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project. "
                                                }
                                              </Typography>

                                              <Grid container>
                                                {/*----- Through Learning Assistance Program -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Through Learning Assistance Program"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"Yes"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Approved -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Approved"}
                                                  </Typography>
                                                  <div className="d-flex-gap">
                                                    <Typography className="field-value value-border">
                                                      {"Manager"}
                                                    </Typography>
                                                    <Typography className="field-value value-border">
                                                      {"HRBP"}
                                                    </Typography>
                                                  </div>
                                                </Grid>

                                                {/*----- Course Fee Currency -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Course Fee Currency"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"USD ($)"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Course Fee -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Course Fee"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"1,500"}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>

                                          {/*----- Mentorship -----*/}
                                          <Typography className="tasks-title-label act-mt-15">
                                            {"Mentorship"}
                                          </Typography>
                                          <div className="activities-inner">
                                            <div>
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            <div className="act-b-bottom">
                                              <Grid container>
                                                {/*----- Mentorship Title -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Mentorship Title"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {
                                                      "Development of Python logarithms"
                                                    }
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                ></Grid>

                                                {/*----- Mentor / Supervisor Employee ID -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Employee ID"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"EMP5545454"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Name -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Mentor / Supervisor Name"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"EMP5545454"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Designation -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Designation"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"Sr. Architect"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Department -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Department"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {
                                                      "Dept: Engineering Development"
                                                    }
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>

                                          <div className="activities-inner">
                                            <div className="h-85per">
                                              <img
                                                src={Goals}
                                                alt="Goals"
                                                height={10}
                                                width={10}
                                              />
                                              <div className="act-vertical-border" />
                                            </div>

                                            <div className="act-b-bottom">
                                              <Grid container>
                                                {/*----- Mentorship Title -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Mentorship Title"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {
                                                      "Development of Python logarithms 2"
                                                    }
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                ></Grid>

                                                {/*----- Mentor / Supervisor Employee ID -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Employee ID"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"EMP5545454"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Name -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {"Mentor / Supervisor Name"}
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"EMP5545454"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Designation -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Designation"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {"Sr. Architect"}
                                                  </Typography>
                                                </Grid>

                                                {/*----- Mentor / Supervisor Department -----*/}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                >
                                                  <Typography className="field-label act-mt-15">
                                                    {
                                                      "Mentor / Supervisor Department"
                                                    }
                                                  </Typography>
                                                  <Typography className="field-value">
                                                    {
                                                      "Dept: Engineering Development"
                                                    }
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>

                                {/*----- Link to Competency -----*/}
                                <div className="rating b-bottom gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["open"][1] ===
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("open", "goals", 1);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("open", "goals", 1);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["open"][1]
                                      }
                                      onChange={handleChangeAccordian(
                                        "open",
                                        "goals",
                                        1
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span>Link to Competency</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Competencies
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {/* <div className="performanceCard">
                                          {promisedata?.performanceForms
                                            ?.length > 0 &&
                                            promisedata?.performanceForms?.map(
                                              (pf) => (
                                                <div className="card">
                                                  <div className="top">
                                                    <p className="year">
                                                      {pf?.year}
                                                    </p>
                                                    <a
                                                      href={pf.path}
                                                      target="_blank"
                                                    >
                                                      <img src={pdfDownload} />
                                                    </a>
                                                  </div>
                                                  <p className="fileName">
                                                    {pf?.fileName}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div> */}

                                        <div className="activities-outter">
                                          <div className="activities-inner d-grid">
                                            <div className="d-flex-gap">
                                              <Typography className="field-value value-border">
                                                {"Coding"}
                                              </Typography>
                                              <Typography className="field-value value-border">
                                                {"Design"}
                                              </Typography>
                                              <Typography className="field-value value-border">
                                                {"Understanding of Systems"}
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>

                                {/*----- Link to Aspiration -----*/}
                                <div className="rating border-0 gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["open"][2] ===
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();

                                              togglePanel("open", "goals", 2);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("open", "goals", 2);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["open"][2]
                                      }
                                      onChange={handleChangeAccordian(
                                        "open",
                                        "goals",
                                        2
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span>Link to Aspiration</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Aspiration
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      {/* <AccordionDetails>
                                                                <div className="performanceCard">
                                                                    {
                                                                        promisedata?.performanceForms?.length > 0 &&
                                                                        promisedata?.performanceForms?.map((pf) =>
                                                                            <div className="card">
                                                                                <div className="top">
                                                                                    <p className="year">{pf?.year}</p>
                                                                                    <a href={pf.path} target="_blank">
                                                                                        <img src={pdfDownload} />
                                                                                    </a>
                                                                                </div>
                                                                                <p className="fileName">{pf?.fileName}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </AccordionDetails> */}

                                      <div className="activities-outter">
                                        <div className="activities-inner d-grid">
                                          <div className="d-flex-gap">
                                            <Typography className="field-value value-border">
                                              {
                                                "Work on lucy (data mart) tech stack"
                                              }
                                            </Typography>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}

                      {OutputGoalsActiveTab === "completed" && (
                        <div className="rating">
                          <div>
                            {(() => {
                              if (
                                AccordionState["grwthDev"]["completed"][0] ==
                                true
                              ) {
                                return (
                                  <LuMinusCircle
                                    style={{
                                      cursor: "pointer",
                                      width: "25px",
                                      color: "#7F56D9",
                                      height: "25px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      togglePanel("completed", "grwthDev", 0);
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <LuPlusCircle
                                    style={{
                                      cursor: "pointer",
                                      width: "25px",
                                      color: "#7F56D9",
                                      height: "25px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      togglePanel("completed", "grwthDev", 0);
                                    }}
                                  />
                                );
                              }
                            })()}
                          </div>
                          <div className="ratingInner">
                            <Accordion
                              sx={{
                                boxShadow: "none !important",
                                "& .MuiAccordionSummary-root": {
                                  paddingLeft: 0,
                                  boxShadow: "none !important",
                                  "& .MuiAccordionSummary-content": {
                                    marginTop: 0,
                                    paddingTop: 0,
                                    alignSelf: "flex-start",
                                  },
                                },
                              }}
                              expanded={
                                AccordionState["grwthDev"]["completed"][0]
                              }
                              onChange={handleChangeAccordian(
                                "completed",
                                "grwthDev",
                                0
                              )}
                            >
                              <AccordionSummary
                                sx={{
                                  marginBottom: "0px",
                                }}
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <div className="d-flex w-100 justify-content-between">
                                  <div className="asas ratingTitle">
                                    1.
                                    <div className="sdsd">
                                      <p className="ratingTitle">
                                        <span className="name">
                                          {" "}
                                          Data Engineering - More complex
                                          Projects
                                        </span>
                                      </p>
                                      <div className="d-flex w-100 align-items-center">
                                        <p className="created mb-0">
                                          Activities:{" "}
                                          <span className="dateTitle">1</span>
                                        </p>{" "}
                                        <img className="dotIcon" src={dot} />{" "}
                                        <p className="created mb-0">
                                          Created on :{" "}
                                          <span className="dateTitle">
                                            March 12, 2024
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex-end">
                                    <div className="d-flex w-100 gap-20 justify-content-between">
                                      <div class="form-check checkbox">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="check3"
                                          name="option1"
                                          value="something"
                                          checked={checkboxState.check3}
                                          onChange={handleCheckboxChange}
                                        />
                                        <label
                                          class="form-check-label"
                                          htmlFor="check3"
                                        >
                                          Mark as Complete
                                        </label>
                                      </div>
                                      <div className="contact1">
                                        <div className="mailOuter">
                                          <img src={notes1} />
                                          <p className="mail">Notes</p>
                                        </div>
                                      </div>
                                      {/* <div className="contact">
                                    <div className="mailOuter">
                                      <img src={edit1} />
                                      <p className="mail">Edit</p>
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="movementJourney b-bottom mb-0 pb-2 mt-0">
                                  <p className="created">Goal Name</p>
                                  <p className="created">
                                    <span className="dateTitle">
                                      Drive closure of IDP Project
                                    </span>
                                  </p>
                                </div>
                                {/* <div className="movementJourney">
                                  <p className="created">Measure of Success</p>
                                  <p className="created"><span className="dateTitle">Gain an immersive understanding of the practices and skills needed to succeed in an entry-level project management role. Learn how to create effective project documentation and artifacts throughout the various phases of a project.</span></p>
                              </div> */}

                                {/*----- Activities -----*/}
                                <div className="rating b-bottom gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["open"][0] ===
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();

                                              togglePanel("close", "goals", 0);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("close", "goals", 0);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["close"][0]
                                      }
                                      onChange={handleChangeAccordian(
                                        "close",
                                        "goals",
                                        0
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span> Activities</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Live, 0 Completed
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="performanceCard">
                                          {promisedata?.performanceForms
                                            ?.length > 0 &&
                                            promisedata?.performanceForms?.map(
                                              (pf) => (
                                                <div className="card">
                                                  <div className="top">
                                                    <p className="year">
                                                      {pf?.year}
                                                    </p>
                                                    <a
                                                      href={pf.path}
                                                      target="_blank"
                                                    >
                                                      <img src={pdfDownload} />
                                                    </a>
                                                  </div>
                                                  <p className="fileName">
                                                    {pf?.fileName}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>

                                {/*----- Link to Competency -----*/}
                                <div className="rating b-bottom gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["close"][1] ===
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("close", "goals", 1);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("close", "goals", 1);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["close"][1]
                                      }
                                      onChange={handleChangeAccordian(
                                        "close",
                                        "goals",
                                        1
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span>Link to Competency</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Competencies
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="performanceCard">
                                          {promisedata?.performanceForms
                                            ?.length > 0 &&
                                            promisedata?.performanceForms?.map(
                                              (pf) => (
                                                <div className="card">
                                                  <div className="top">
                                                    <p className="year">
                                                      {pf?.year}
                                                    </p>
                                                    <a
                                                      href={pf.path}
                                                      target="_blank"
                                                    >
                                                      <img src={pdfDownload} />
                                                    </a>
                                                  </div>
                                                  <p className="fileName">
                                                    {pf?.fileName}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>

                                {/*----- Link to Aspiration -----*/}
                                <div className="rating border-0 gap-8">
                                  <div>
                                    {(() => {
                                      if (
                                        AccordionState["goals"]["close"][2] ==
                                        true
                                      ) {
                                        return (
                                          <LuMinusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("close", "goals", 2);
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <LuPlusCircle
                                            style={{
                                              cursor: "pointer",
                                              width: "25px",
                                              color: "#7F56D9",
                                              height: "25px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              togglePanel("close", "goals", 2);
                                            }}
                                          />
                                        );
                                      }
                                    })()}
                                  </div>
                                  <div className="ratingInner b-bottom-none">
                                    <Accordion
                                      sx={{
                                        boxShadow: "none !important",
                                        "& .MuiAccordionSummary-root": {
                                          paddingLeft: 0,
                                          boxShadow: "none !important",

                                          "& .MuiAccordionSummary-content": {
                                            marginTop: 0,
                                            paddingTop: 0,
                                            alignSelf: "flex-start",
                                          },
                                        },
                                      }}
                                      expanded={
                                        AccordionState["goals"]["close"][2]
                                      }
                                      onChange={handleChangeAccordian(
                                        "close",
                                        "goals",
                                        2
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                        <div className="asas ratingTitle">
                                          <div className="sdsd">
                                            <p className="comment-title">
                                              <span>Link to Aspiration</span>
                                            </p>
                                            <div className="d-flex w-100 align-items-center">
                                              <p className="created mb-0">
                                                0 Aspiration
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      {/* <AccordionDetails>
                                              <div className="performanceCard">
                                                  {
                                                      promisedata?.performanceForms?.length > 0 &&
                                                      promisedata?.performanceForms?.map((pf) =>
                                                          <div className="card">
                                                              <div className="top">
                                                                  <p className="year">{pf?.year}</p>
                                                                  <a href={pf.path} target="_blank">
                                                                      <img src={pdfDownload} />
                                                                  </a>
                                                              </div>
                                                              <p className="fileName">{pf?.fileName}</p>
                                                          </div>
                                                      )
                                                  }
                                              </div>
                                          </AccordionDetails> */}
                                    </Accordion>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })()}
                {/**ppppppppppppppppppppppp */}
                {OutputGoalsActiveTab === "open" && (
                  <div className="rating  ">
                    <div>
                      {(() => {
                        if (AccordionState["grwthDev"]["open"][1] === true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("open", "grwthDev", 1);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("open", "grwthDev", 1);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner border-0">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",

                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["grwthDev"]["open"][1]}
                        onChange={handleChangeAccordian("open", "grwthDev", 1)}
                      >
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              1.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Data Engineering - More complex Projects
                                  </span>
                                </p>
                                <div className="d-flex w-100 align-items-center">
                                  <p className="created mb-0">
                                    Activities:{" "}
                                    <span className="dateTitle">1</span>
                                  </p>{" "}
                                  <img className="dotIcon" src={dot} />{" "}
                                  <p className="created mb-0">
                                    Created on :{" "}
                                    <span className="dateTitle">
                                      March 12, 2024
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div class="form-check checkbox">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="check4"
                                    name="option1"
                                    value="something"
                                    checked={checkboxState.check4}
                                    onChange={handleCheckboxChange}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="check4"
                                  >
                                    Mark as Complete
                                  </label>
                                </div>
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes</p>
                                  </div>
                                </div>
                                <div
                                  className="contact"
                                  onClick={() => handleEditClick(2, "edit")}
                                >
                                  <div className="mailOuter">
                                    <img src={edit1} />
                                    <p className="mail">Edit</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <p className="created">Goal Name</p>
                            <p className="created">
                              <span className="dateTitle">
                                Drive closure of IDP Project
                              </span>
                            </p>
                          </div>
                          <div className="movementJourney">
                            <p className="created">Measure of Success</p>
                            <p className="created">
                              <span className="dateTitle">
                                Gain an immersive understanding of the practices
                                and skills needed to succeed in an entry-level
                                project management role. Learn how to create
                                effective project documentation and artifacts
                                throughout the various phases of a project.
                              </span>
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                )}
                {/****sdasddddddddddddddddddddddddddddddddddddddddddddddddd */}
                {OutputGoalsActiveTab === "completed" && (
                  <div className="rating">
                    <div>
                      {(() => {
                        if (
                          AccordionState["grwthDev"]["completed"][1] == true
                        ) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "grwthDev", 1);
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("completed", "grwthDev", 1);
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner border-0">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",

                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                              alignSelf: "flex-start",
                            },
                          },
                        }}
                        expanded={AccordionState["grwthDev"]["completed"][1]}
                        onChange={handleChangeAccordian(
                          "completed",
                          "grwthDev",
                          1
                        )}
                      >
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="asas ratingTitle">
                              1.
                              <div className="sdsd">
                                <p className="ratingTitle">
                                  <span className="name">
                                    {" "}
                                    Data Engineering - More complex Projects
                                  </span>
                                </p>
                                <div className="d-flex w-100 align-items-center">
                                  <p className="created mb-0">
                                    Activities:{" "}
                                    <span className="dateTitle">1</span>
                                  </p>{" "}
                                  <img className="dotIcon" src={dot} />{" "}
                                  <p className="created mb-0">
                                    Created on :{" "}
                                    <span className="dateTitle">
                                      March 12, 2024
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex-end">
                              <div className="d-flex w-100 gap-20 justify-content-between">
                                <div class="form-check checkbox">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="check4"
                                    name="option1"
                                    value="something"
                                    checked={checkboxState.check4}
                                    onChange={handleCheckboxChange}
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="check4"
                                  >
                                    Mark as Complete
                                  </label>
                                </div>
                                <div className="contact1">
                                  <div className="mailOuter">
                                    <img src={notes1} />
                                    <p className="mail">Notes</p>
                                  </div>
                                </div>
                                <div
                                  className="contact"
                                  onClick={() => handleEditClick(2, "edit")}
                                >
                                  <div className="mailOuter">
                                    <img src={edit1} />
                                    <p className="mail">Edit</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <p className="created">Goal Name</p>
                            <p className="created">
                              <span className="dateTitle">
                                Drive closure of IDP Project
                              </span>
                            </p>
                          </div>
                          <div className="movementJourney">
                            <p className="created">Measure of Success</p>
                            <p className="created">
                              <span className="dateTitle">
                                Gain an immersive understanding of the practices
                                and skills needed to succeed in an entry-level
                                project management role. Learn how to create
                                effective project documentation and artifacts
                                throughout the various phases of a project.
                              </span>
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                )}
                {/* <hr className="dividerLine"></hr> */}
              </div>
            </div>
          </Grid>
        </>
      )}

      <div className="flex-end"></div>
    </Grid>
  );
};

export default React.memo(MyPlan);
