import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Avatar from 'react-avatar';
import hand from "../../../../assets/images/hand.svg"
import mail from "../../../../assets/images/mail.svg"
import phone from "../../../../assets/images/phone.svg"
import user from "../../../../assets/images/user.png"


const ProfileDetails = ({ }) => {

    return (
        <div className="my-plan-profile-details">
            {/* {profileinfodata?.profilePhotoPath ? (
                <img src={profileinfodata.profilePhotoPath} className="userImage" alt="User Profile" />
            ) : (
                <Avatar name={profileinfodata?.employeeName || 'Unknown User'} size="84" round={true} />
            )} */}
            <img src={user} className="userImage" alt="User Profile" />
            <div className="details">
                <div className="detailsOne">
                    <div className="detailsInner">
                        <Typography className="userName">{"Gowtham Vairavan"}</Typography>
                        <Typography className="dept">{"Architect"}</Typography>
                        <Typography className="empId">{"Employee ID: 410905"}</Typography>
                        <div className="cardOuter">
                            <Typography className="card">{"BU: Corporate"}</Typography>
                            <Typography className="card">{"Dept: HR Ops & Technology"}</Typography>
                        </div>
                    </div>
                    <div className="contact">
                        <div className="mailOuter">
                            <img src={mail} />
                            <Typography className="mail">{"gowthamvairav@phonepe.com"}</Typography>
                        </div>
                        <div className="mobNoOuter">
                            <img src={phone} />
                            <Typography className="mobNo">{"(+91) 1234567890"}</Typography>
                        </div>
                    </div>
                </div>
                <div className="detailsTwo">
                    <div className="detailsTwoInner">
                        <Typography className="title">{"Grade"}</Typography>
                        <Typography className="subTitle">{"A"}</Typography>
                    </div>
                    <div className="detailsTwoInner">
                        <Typography className="title">{"PhonePe Tenure"}</Typography>
                        <Typography className="subTitle">{"2 years"}</Typography>
                    </div>
                    <div className="detailsTwoInner">
                        <Typography className="title">{"Time Since Last Promotion"}</Typography>
                        <Typography className="subTitle">{"7 Months"}</Typography>
                    </div>
                    <div className="detailsTwoInner">
                        <Typography className="title">{"EEP Completion %"}</Typography>
                        <Typography className="subTitle">{"70%"}</Typography>
                    </div>
                </div>
                
                <div className="about">
                    <img src={hand} />
                    <div className="aboutInner">
                        <Typography className="title">{"About me"}</Typography>
                        <Typography className="desc">{"My name is [Your name], and I'm passionate about [Interest], [Interest] and [Interest]. I'm currently working as a [Your current job title] where I practise [Relevant skill] and [Relevant skill] every day."}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ProfileDetails);
