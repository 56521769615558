import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={46}
    height={46}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={3} y={3} width={40} height={40} rx={20} fill="#FEE4E2" />
    <rect
      x={3}
      y={3}
      width={40}
      height={40}
      rx={20}
      stroke="#FEF3F2"
      strokeWidth={6}
    />
    <path
      d="M23.8332 18.8333L22.9036 16.9741C22.636 16.439 22.5022 16.1714 22.3026 15.976C22.1262 15.8031 21.9134 15.6716 21.6799 15.5911C21.4158 15.5 21.1167 15.5 20.5184 15.5H17.3332C16.3997 15.5 15.933 15.5 15.5765 15.6817C15.2629 15.8414 15.0079 16.0964 14.8482 16.41C14.6665 16.7665 14.6665 17.2332 14.6665 18.1667V18.8333M14.6665 18.8333H27.3332C28.7333 18.8333 29.4334 18.8333 29.9681 19.1058C30.4386 19.3455 30.821 19.728 31.0607 20.1984C31.3332 20.7331 31.3332 21.4332 31.3332 22.8333V26.5C31.3332 27.9001 31.3332 28.6002 31.0607 29.135C30.821 29.6054 30.4386 29.9878 29.9681 30.2275C29.4334 30.5 28.7333 30.5 27.3332 30.5H18.6665C17.2664 30.5 16.5663 30.5 16.0315 30.2275C15.5611 29.9878 15.1787 29.6054 14.939 29.135C14.6665 28.6002 14.6665 27.9001 14.6665 26.5V18.8333Z"
      stroke="#D92D20"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
