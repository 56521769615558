import { Button, Skeleton, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Avatar from "react-avatar";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  isEmptyNullUndefined,
} from "../../utils/utils";
import { IoMdSearch } from "react-icons/io";
import PaginationMedi from "../../common/pagination";
import TableSkel from "../../common/tableSkeleton";
import FilterSelect from "../../common/filterSelect/index.js";
import SideDrawer from "../../common/sideDrawer/index.js";
import SendReq from "./sendRequest/index.js";
import { IoCloseSharp } from "react-icons/io5";
import filterIcon from "../../../assets/images/filter.svg";
import SideFilters from "../../common/sideFilters/index.js";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import moment from "moment";

const RequestFeedback = () => {
  const currentDate = moment().startOf("day");

  const history = useHistory();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const dropdownData = useSelector(
    (state) => state?.dropdownData?.dropdownData
  );
  const annualcycleData = useSelector(
    (state) => state?.annualcycleReducer?.annualcycle[0]
  );

  const [empList, setEmpList] = useState(null);
  const [sortEmpList, setSortEmpList] = useState(null);
  const [empListLoader, setEmpListLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [filterPayload, setFilterPayload] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [totalSearchPages, setTotalSearchPages] = useState(0);

  const [selectedPeer, setSelectedPeer] = useState(null);
  const [searchedList, setsearchedList] = useState(null);
  const [isSendRequest, setIsSendRequest] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [sentCount, setSentCount] = useState(0);
  const [showAllSearches, setShowAllSearches] = useState(false);
  const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
  const [isThereEmptyForm, setIsThereEmptyForm] = useState(false);
  const [emptyFormMessage, setEmptyFormMessage] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSearch(false);
          setSearch("");
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const handleSendRequest = (emp) => {
    setSelectedPeer(emp);
    setIsSendRequest(true);
    setIsSearch(false);
    setSearch("");
    setIsOpenDrawer(true);
  };

  useEffect(() => {
    if (
      !employeeDetails?.permissions?.includes("create-request-peer-feedback") &&
      employeeDetails?.permissions?.includes("view-request-peer-feedback")
    ) {
      history.push("/SentRequestTab");
    }
  }, []);

  useEffect(() => {
    if (annualcycleData?.pmsCycle?.peerAppraisalEndDate) {
      isActive();
    }
  }, [annualcycleData]);

  useEffect(() => {
    const getSearch = setTimeout(() => {
      setSearchLoader(true);

      if (!isEmptyNullUndefined(search)) {
        setIsSearch(true);
        if (!firstLoad) {
          APIList.getFilteredSearchPeersfeed({
            payload: {
              employeeEligibilityDTO: filterPayload,
              keyword: search,
              employeeId: employeeDetails?.id,
              managerId: employeeDetails?.manager?.id,
              companyId: employeeDetails?.company?.id,
              page: "peer-feedback",
            },
            page: currentPage,
            size: showAllSearches ? 10000 : 3,
          })
            .then((res) => {
              setsearchedList(res?.data?.data);
              setTotalSearchPages(res?.data?.totalPages);
              setSearchLoader(false);
            })
            .catch((err) => {
              setSearchLoader(false);
            });
        }
      }
    }, 500);

    return () => clearTimeout(getSearch);
  }, [search, showAllSearches]);

  useEffect(() => {
    getPageData();
  }, [currentPage]);

  useEffect(() => {
    getSentCount();
  }, [employeeDetails]);

  const getSentCount = () => {
    APIList.getSentRequestCount({ id: employeeDetails?.id })
      .then((res) => {
        setSentCount(res?.data?.submittedRequestCount);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const isActive = () => {
    if (currentDate.isBefore(annualcycleData.pmsCycle.peerAppraisalEndDate)) {
      console.log("Current date is before self appraisal end date");
      setStatus("ACTIVE");
      return false;
    } else if (
      currentDate.isAfter(annualcycleData.pmsCycle.peerAppraisalEndDate)
    ) {
      console.log("Current date is after self appraisal end date");

      setStatus("INACTIVE");
      return true;
    } else {
      console.log("Current date is equal to self appraisal end date");
      setStatus("ACTIVE");
      return false;
    }
  };

  const getPageData = () => {
    // console.log("getPageData");
    setEmpListLoader(true);
    APIList.getImmediateTeam({
      payload: {
        employeeEligibilityDTO: filterPayload,
        keyword: "",
        employeeId: employeeDetails?.id,
        companyId: employeeDetails?.company?.id,
        managerId: employeeDetails?.manager?.id,
      },
      page: currentPage,
      size: 10,
    })
      .then((res) => {
        setEmpList(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setEmpListLoader(false);
        setFirstLoad(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setEmpListLoader(false);
        setIsThereEmptyForm(true);
        setEmptyFormMessage(err?.message);
      });
    // setEmpList(dummy?.data?.filter(x => !x.sentStatus));
  };

  useEffect(() => {
    setSortEmpList(empList);
  }, [empList]);

  const handleSubmitFilter = () => {
    if (Object.keys(filterPayload).length === 0) {
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
          &nbsp;&nbsp;Please select some filters
        </div>
      );
    } else {
      // setSearchLoader(true);
      setIsFilterOn(true);
      setEmpListLoader(true);
      APIList.getImmediateTeam({
        payload: {
          employeeEligibilityDTO: filterPayload,
          keyword: "",
          employeeId: employeeDetails?.id,
          companyId: employeeDetails?.company?.id,
          managerId: employeeDetails?.manager?.id,
        },
        page: 0,
        size: 10,
      })
        .then((res) => {
          // console.log("res",res)
          setEmpList(res?.data?.data);
          setTotalPages(res?.data?.totalPages);
          // setSearchLoader(false);
          setEmpListLoader(false);
          setIsOpenFilter(false);
        })
        .catch((err) => {
          toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;{err?.message}
            </div>
          );
          // setSearchLoader(false);
          setEmpListLoader(false);
        });
    }
  };

  const handleResetFilters = () => {
    setFilterPayload({});

    setEmpListLoader(true);
    APIList.getImmediateTeam({
      payload: {
        employeeEligibilityDTO: {},
        keyword: "",
        employeeId: employeeDetails?.id,
        companyId: employeeDetails?.company?.id,
        managerId: employeeDetails?.manager?.id,
      },
      page: 0,
      size: 10,
    })
      .then((res) => {
        setEmpList(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setEmpListLoader(false);
        setFirstLoad(false);
        setIsFilterOn(false);
        setIsOpenFilter(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setEmpListLoader(false);
      });
  };

  const autoSearchText = (e) => {
    setCurrentPage(0);
    setShowAllSearches(false);
    setSearch(e.target.value);
  };

  return (
    <div className="feedback-request-one">
      <div
        style={{
          pointerEvents: isThereEmptyForm ? "none" : "auto",
          opacity: isThereEmptyForm ? "60%" : "100%",
        }}
        className="search-wrapper"
      >
        <div className="r-f-tabs">
          {employeeDetails?.permissions?.includes(
            "create-request-peer-feedback"
          ) && <div className={`tab  active`}>Send Request</div>}
          {employeeDetails?.permissions?.includes(
            "view-request-peer-feedback"
          ) && (
            <div
              className={`tab`}
              onClick={() => history.push("/SentRequestTab")}
            >
              Submitted Request{" "}
              <span>{sentCount < 10 ? `0${sentCount}` : sentCount}</span>
            </div>
          )}
        </div>

        <div className="second-div">
          <Search ref={wrapperRef}>
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by username or email"
              inputProps={{ "aria-label": "search" }}
              onChange={autoSearchText}
              value={search}
            />
            {!isEmptyNullUndefined(search) && (
              <IoCloseSharp
                onClick={() => {
                  setSearch("");
                  setShowAllSearches(false);
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "34%",
                  color: "gray",
                  cursor: "pointer",
                }}
              />
            )}

            {!isEmptyNullUndefined(search) && isSearch && (
              <div className="search-popup-req-feed">
                {searchLoader ? (
                  <>
                    <div className="search-emp-outer">
                      <div className="empDetaila">
                        <div className="empPic">
                          <Skeleton variant="circular" width={40} height={40} />
                        </div>
                        <div className="userInfo">
                          <p className="name">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "7rem" }}
                            />
                          </p>
                          <p className="designation">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "0.5rem", width: "3rem" }}
                            />
                          </p>
                        </div>
                      </div>
                      <p className="name">
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1.5rem", width: "2rem" }}
                        />
                      </p>
                    </div>
                    <div className="search-emp-outer">
                      <div className="empDetaila">
                        <div className="empPic">
                          <Skeleton variant="circular" width={40} height={40} />
                        </div>
                        <div className="userInfo">
                          <p className="name">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "7rem" }}
                            />
                          </p>
                          <p className="designation">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "0.5rem", width: "3rem" }}
                            />
                          </p>
                        </div>
                      </div>
                      <p className="name">
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1.5rem", width: "2rem" }}
                        />
                      </p>
                    </div>
                  </>
                ) : searchedList?.length > 0 ? (
                  <div>
                    {searchedList?.map((emp, index) => {
                      return (
                        <div key={index} className="search-emp-outer">
                          <div className="empDetaila">
                            <div className="empPic">
                              {emp?.profilePhotoPath ? (
                                <img
                                  src={emp?.profilePhotoPath}
                                  className="userImg"
                                  alt="User Image"
                                />
                              ) : (
                                <Avatar
                                  name={emp?.employeeName}
                                  size="40"
                                  className="userImg"
                                  color={"#00425A"}
                                />
                              )}
                            </div>
                            <div className="userInfo">
                              <p className="name">{emp?.employeeName}</p>
                              {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                              <p className="designation">
                                {emp?.employeeDesignation ?? ""}
                              </p>
                              <p
                                style={{
                                  width: "150px",
                                  overflow: "hidden",
                                }}
                                className="designation"
                              >
                                {emp?.emailId}
                              </p>
                            </div>
                          </div>
                          {status == "ACTIVE" && (
                            <button
                              className="search-sednd-btn"
                              onClick={() => handleSendRequest(emp)}
                            >
                              Send
                            </button>
                          )}
                        </div>
                      );
                    })}
                    {!showAllSearches && totalSearchPages > 1 && (
                      <div
                        className="view-all-search"
                        onClick={() => setShowAllSearches(true)}
                      >
                        View all
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="no-result">No result</div>
                )}
              </div>
            )}
          </Search>
          {/* <div className="filter-tab" onClick={() => setIsOpenFilter(true)}> 
            <img src={filterIcon} alt="" />
            Filter
            {
                isFilterOn && 
                <div className="red-dot"></div>
            }
          </div> */}
          <div
            className="page-i-btn"
            onClick={() => setIsOpenDrawerForIbtn(true)}
          >
            i
          </div>
        </div>
      </div>

      {
        // empListLoader ?
        // <EmplistSkel />
        // :
        <div className="row">
          <div className="col-12">
            <div className="feedback-one">
              {isThereEmptyForm ? (
                <div
                  className="feedbackAssessment"
                  style={{
                    justifyContent: "flex-start",
                    paddingTop: 0,

                    fontSize: "0.9rem",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "var(--tertiary)",
                    margin: 0,
                  }}
                >
                  {emptyFormMessage ??
                    "You are not eligible to submit Request Peer Feedback. However you will be able to provide feedback to peers !"}
                </div>
              ) : (
                <div className="tablee">
                  <table class="feedback-emp-list-table">
                    <tr className="table-head-emp-list-send">
                      {/* <th>My Immediate team member  */}
                      {/* change title 12/feb */}
                      <th>Employee Name</th>
                      <th className="mid-cols">Email</th>
                      <th>Status</th>
                    </tr>

                    {empListLoader ? (
                      <TableSkel />
                    ) : (
                      sortEmpList?.map((emp, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="empDetaila">
                                <div className="empPic">
                                  {emp?.profilePhotoPath ? (
                                    <img
                                      src={emp?.profilePhotoPath}
                                      className="userImg"
                                      alt="User Image"
                                    />
                                  ) : (
                                    <Avatar
                                      name={emp?.employeeName}
                                      size="45"
                                      className="userImg"
                                      color={"#00425A"}
                                    />
                                  )}
                                </div>
                                <div className="userInfo">
                                  <p className="name">{emp?.employeeName}</p>
                                  {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                                  <p className="designation">
                                    {emp?.employeeDesignation
                                      ? emp?.employeeDesignation
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="btns">{emp?.emailId}</div>
                            </td>
                            <td>
                              <div className="btns">
                                {status == "ACTIVE" && (
                                  <button
                                    className="send-btn"
                                    onClick={() => handleSendRequest(emp)}
                                  >
                                    Send
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </table>
                  {isEmptyNullUndefined(sortEmpList) && (
                    <div className="no-result">No Result Found</div>
                  )}

                  {!isEmptyNullUndefined(sortEmpList) && totalPages > 1 && (
                    <PaginationMedi
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      }

      {isOpenDrawerForIbtn && (
        <SideDrawer
          isOpen={isOpenDrawerForIbtn}
          setIsOpen={setIsOpenDrawerForIbtn}
        >
          
          <InfoContent pageName="request-feedback" />
        </SideDrawer>
      )}

      {isOpenDrawer && isSendRequest && !isEmptyNullUndefined(selectedPeer) && (
        <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
          <SendReq
            peer={selectedPeer}
            setIsOpen={setIsOpenDrawer}
            setSearch={setSearch}
            getPageData={getPageData}
            getSentCount={getSentCount}
          />
        </SideDrawer>
      )}
      {isOpenFilter && (
        <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
          <div className="filter-wrapper">
            <div className="filters-heading">Filters</div>

            <Stack>
              {!isEmptyNullUndefined(dropdownData) &&
                Object.keys(dropdownData).map((key) => {
                  return (
                    <FilterSelect
                      title={key}
                      filterPayload={filterPayload}
                      setFilterPayload={setFilterPayload}
                    />
                  );
                })}
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                disabled={Object.keys(filterPayload).length === 0}
                sx={{
                  color: "var(--primary)",
                  borderColor: "var(--primary)",
                  marginRight: "1rem",
                  width: "9rem",
                }}
                onClick={() => {
                  handleResetFilters();
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                sx={{
                  // color:"var(--secondary)",
                  backgroundColor: "var(--primary)",
                  borderColor: "var(--primary)",
                  "&:hover": {
                    backgroundColor: "#035c7c",
                    borderColor: "#035c7c",
                  },
                  width: "9rem",
                }}
                disabled={empListLoader}
                onClick={() => handleSubmitFilter()}
              >
                Submit
              </Button>
            </Stack>
          </div>
        </SideFilters>
      )}
    </div>
  );
};

export default RequestFeedback;
