import React, { useState } from 'react';
import { Grid, Button, TextField, Typography } from "@mui/material";
import YourTeamMember from './components/YourTeamMember';
import ProfileDetails from './components/ProfileDetails';
import MyPlan from './components/MyPlan';
import OutputGoals from './components/OutputGoals';
import Notes from './components/Notes';
import CheckIns from './components/CheckIns';

const SessionPlan = () => {
  const [myPlanTab, setMyPlanTab] = useState(true);
  const [checkInsTab, setCheckInsTab] = useState(false);

  const handleMyPlanTabClick = () => {
    setMyPlanTab(true);
    setCheckInsTab(false);
  };

  const handleCheckInsTabClick = () => {
    setMyPlanTab(false);
    setCheckInsTab(true);
  };

  return (
    <Grid container spacing={4} className="session-plan-main">
      {/*----- Heading -----*/}
      <Grid item xs={12} md={12} sm={12}>
        <Typography className="header-title">{"My Plan"}</Typography>
      </Grid>


      {/*----- Your Team Member -----*/}
      <Grid item xs={12} md={12} sm={12}>
        <YourTeamMember />
      </Grid>


      {/*----- Profile Details -----*/}
      <Grid item xs={12} md={12} sm={12}>
        <ProfileDetails />
      </Grid>


      {/*----- My Plan & Check-Ins Tab bar -----*/}
      <Grid item xs={12} md={12} sm={12}>
        <div className="tabs-block">
          <div className={myPlanTab === true ? "active" : "tab"} onClick={handleMyPlanTabClick}>My Plan</div>
          <div className={checkInsTab === true ? "active" : "tab"} onClick={handleCheckInsTabClick}>Check-Ins<span className="live">{"Live"}</span><span className="closure-days">{"(Closure in 15 Days)"}</span></div>
        </div>
      </Grid>


      {/*----- My Plan & Check-Ins Card -----*/}

      <Grid item xs={12} md={9} sm={9} className='ghgfh'>
        {myPlanTab === true &&
          <MyPlan />
          // <OutputGoals />
        }

        {checkInsTab === true &&
          <CheckIns />
        }
      </Grid>



      {/*----- Notes Card -----*/}
      <Grid item xs={12} md={3} sm={3} className='ghgfh'>
        <Notes />
      </Grid>

    </Grid>
  );
}

export default SessionPlan;
