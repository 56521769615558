import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import FileIcon from './FileIcon'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Alert(props) {
  const { onClose, selectedValue, open, action } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      
      {/* <List sx={{ pt: 0 }}>
        {emails.map((email) => (
          <ListItem disableGutters key={email}>
            <ListItemButton onClick={() => handleListItemClick(email)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => handleListItemClick('addAccount')}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItemButton>
        </ListItem>
      </List> */}
      <div className='cancel-aler-info-respondent'>

      

        <div class="notification">
            <div class="notification-icon">
                {/* <img src="https://via.placeholder.com/24/FF4D4F/FFFFFF?text=%F0%9F%93%81" alt="Warning Icon" /> */}
                <FileIcon />
            </div>
            <div class="notification-content">
                <h4>Changes has not been submitted</h4>
                <p>Are you sure you want to cancel the changes? You will lose them.</p>
                <div className=' d-flex justify-content-between'>
                  <button onClick={() => handleClose()} class="undo-action">Undo Action</button>
                  <button onClick={() => action()} class="confirm-action">Confirm</button>

                </div>
            </div>
        </div>
      </div>
    </Dialog>
  );
}

export default Alert;

Alert.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};