import {
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Autocomplete,
  Avatar,
  CircularProgress,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isEmptyNullUndefined, options } from "../../../utils/utils";
import "suneditor/dist/css/suneditor.min.css";
import { RiErrorWarningFill } from "react-icons/ri";
import SunEditor from "suneditor-react";
import APIList from "../../../../api";
// import searchIcon from "../../../assets/images/searchIcon.svg"
import searchIcon from "../../../../assets/images/searchIcon.svg";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

//////////// import for search/// start////////
import { Skeleton } from "@mui/material";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../utils/utils";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillCheckCircleFill } from "react-icons/bs";
import DeleteIcon from './DeleteIcon';
import Alert from './Alert'
//////////////////////////end/////////////////

const AddRespondentForm = ({ setIsOpen, updateStakeholdersList }) => {
  const history = useHistory();
  const employeeDetails = useSelector((state) => state?.empData?.empData);

  let defaultMsg = `<p>
  <p>Dear User,</p>

  <p> I hope this message finds you well.</p>

  <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

   Best regards,
   <p>${employeeDetails?.name}</p>
  </p>`;

  const initialDataRow = {
    selectedSearchEmp: null,
    message: defaultMsg,
    selectedSearchEmpError: {isError: false, errorMessage: ""},
    messageError: {isError: false, errorMessage: ""},

    search: ''
  }

  const initialDataRowList = [JSON.parse(JSON.stringify(initialDataRow))]



  const [respondent, setRespondent] = useState(null);
  const [criticality, setCriticality] = useState("Critical");
  const [submitLoader, setSubmitLoader] = useState(false);

  //////////////////// search state start /////////////
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [filterPayload, setFilterPayload] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);
  const [totalSearchPages, setTotalSearchPages] = useState(0);
  const [searchedList, setsearchedList] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [showAllSearches, setShowAllSearches] = useState(false);
  //////////////////search state end////////////////////

  ///////////////// list state ////////////////
  const [dataRowList, setDataRowList] = useState(initialDataRowList)

  

  
  const [message, setMessage] = useState(defaultMsg);

  const [formData, setFormData] = useState({
    respondent: null,
    criticality: null,
    message: defaultMsg,
  });
  const [formDataError, setFormDataError] = useState({
    respondent: { isError: false, errorMessage: "" },
    criticality: { isError: false, errorMessage: "" },
    message: { isError: false, errorMessage: "" },
  });

  //////////////old search start///////////////
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
    //////////////old search end///////////////

      /////////// for dilog ///////////
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  

  /////////////////////////////

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: `Provide your feedback here...`,
      // defaultActionOnPaste: "insert_as_html",
      defaultActionOnPaste: "insert_only_text",
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      // showCharsCounter: true, // Show character count in the footer
      showWordsCounter: true, // Show word count in the footer (optional)
      showLinesCounter: true, // Show line count (optional)
      statusbar: true, // Enable the status bar
      toolbarAdaptive: false,

      // height: '50',
      toolbar: false,

      showCharsCounter: false, // Hide character counter in the footer
      showWordsCounter: false, // Hide word counter in the footer
      showXPathInStatusbar: false, // Hide XPath in the footer
    }),
    []
  );

  const fetchEmployees = debounce((keyword) => {
    setSearchLoading(true);
    APIList.searchMyTeam({
      payload: {
        keyword,
        companyId: employeeDetails?.company?.id,
      },
    })
      .then((res) => {
        setDropdownOptions(res?.data?.data || []);
        setSearchLoading(false);
        if (res.data.data.length === 0) {
          toast.error(
            <div style={{ display: "flex", alignItems: "center" }}>
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;No record found...!
            </div>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", alignItems: "center" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title || "An error occurred"}
          </div>
        );
        setSearchLoading(false);
      });
  }, 3000);


  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // fetchEmployees(newInputValue);
  };

  const handleSelectionChange = (event, newValue) => {
    setSelectedEmployee(newValue);
  };

  ///////////////////////// search fun//////////
  function HandleOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSearch(false);
          setSearch("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);

  // console.log('wrapperRef', wrapperRef)
  // HandleOutsideClick(wrapperRef);
  useEffect(() => {
    const getSearch = setTimeout(() => {
      setSearchLoader(true);

      if (!isEmptyNullUndefined(search)) {
        setIsSearch(true);
        if (!firstLoad) {
          APIList.getFilteredSearchPeersfeed({
            payload: {
              employeeEligibilityDTO: filterPayload,
              keyword: search,
              employeeId: employeeDetails?.id,
              managerId: employeeDetails?.manager?.id,
              companyId: employeeDetails?.company?.id,
              page: "peer-feedback",
            },
            page: currentPage,
            size: showAllSearches ? 10000 : 3,
          })
            .then((res) => {
              setsearchedList(res?.data?.data);
              setTotalSearchPages(res?.data?.totalPages);
              setSearchLoader(false);
            })
            .catch((err) => {
              setSearchLoader(false);
            });
        }
      }
    }, 500);

    return () => clearTimeout(getSearch);
  }, [search, showAllSearches]);

  useEffect(() => {
    const getSearch = setTimeout(() => {
      setSearchLoader(true);
      // APIList.searchMyTeam({
      //   payload: {
      //     keyword: inputValue,
      //     companyId: employeeDetails?.company?.id,
      //   },
      // })

      APIList.getFilteredSearchPeersfeed({
        payload: {
          employeeEligibilityDTO: filterPayload,
          keyword: search,
          employeeId: employeeDetails?.id,
          managerId: employeeDetails?.manager?.id,
          companyId: employeeDetails?.company?.id,
          page: "peer-feedback",
        },
        page: currentPage,
        size: showAllSearches ? 10000 : 3,
      })
        .then((res) => {
          setDropdownOptions(res?.data?.data || []);
          setSearchLoading(false);
          if (res.data.data.length === 0) {
            toast.error(
              <div style={{ display: "flex", alignItems: "center" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;No record found...!
              </div>
            );
          }
        })
        .catch((err) => {
          toast.error(
            <div style={{ display: "flex", alignItems: "center" }}>
              <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
              &nbsp;&nbsp;{err?.title || "An error occurred"}
            </div>
          );
          setSearchLoading(false);
        });
    }, 500);

    return () => clearTimeout(getSearch);
  }, [inputValue]);

  useEffect(() => {
    setFirstLoad(false);
  }, [currentPage]);

  const autoSearchText = (e) => {
    setCurrentPage(0);
    setShowAllSearches(false);
    setSearch(e.target.value);
  };
  ///////////////////////search fun end///////////////////////

  const handleChange = (event, fieldType) => {
    const tempFormData = { ...formData };
    const tempFormDataError = { ...formDataError };

    if (fieldType == "TextField" || fieldType == "dropdown") {
      tempFormData[event.target.name] = event.target.value;
      tempFormDataError[event.target.name].isError = false;
      tempFormDataError[event.target.name].errorMessage = "";
    }
    if (fieldType == "autocomplete") {
      console.log("event", event);
      tempFormData.respondent = event;
      tempFormDataError.respondent.isError = false;
      tempFormDataError.respondent.errorMessage = "";

      

      /// set message ///
      tempFormData.message = `<p>
      <p>Dear ${tempFormData.respondent.employeeName},</p>

      <p> I hope this message finds you well.</p>

      <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
      Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

      Best regards,
      <p>${employeeDetails?.name}</p>
      </p>`;

    }
    if (fieldType == "editor") {
      tempFormData.message = event;
      tempFormDataError.message.isError = false;
      tempFormDataError.message.errorMessage = "";
    }

    setFormData(() => tempFormData);
    setFormDataError(() => tempFormDataError);

    
  };

  const handleChangeListRow = (event, fieldType, rowIndex, tempdataRowList) => {

    const initialDataRow = {
      selectedSearchEmp: null,
      message: "",
      selectedSearchEmpError: {isError: false, errorMessage: ""},
      messageError: {isError: false, errorMessage: ""},

      search: ''
    }

    if(fieldType === 'selectedSearchEmp') {

      tempdataRowList[rowIndex].selectedSearchEmp = event
      tempdataRowList[rowIndex].selectedSearchEmpError = {isError: false, errorMessage: ""}

      tempdataRowList[rowIndex].search = ''

      tempdataRowList[rowIndex].message = `<p>
      <p>Dear ${event.employeeName},</p>

      <p> I hope this message finds you well.</p>

      <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
      Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

      Best regards,
      <p>${employeeDetails?.name}</p>
      </p>`;
      
    } else if(fieldType === 'search') {
      console.log('event.target.value', event.target.value)
      tempdataRowList[rowIndex].search = event.target.value
    } else if(fieldType === 'message') {
      tempdataRowList.message = event;
      tempdataRowList[rowIndex].messageError = {isError: false, errorMessage: ""}
    }


    setDataRowList(() => tempdataRowList)

    console.log('tempdataRowList',tempdataRowList)


    // const tempFormData = { ...formData };
    // const tempFormDataError = { ...formDataError };

    // if (fieldType == "TextField" || fieldType == "dropdown") {
    //   tempFormData[event.target.name] = event.target.value;
    //   tempFormDataError[event.target.name].isError = false;
    //   tempFormDataError[event.target.name].errorMessage = "";
    // }
    // if (fieldType == "autocomplete") {
    //   console.log("event", event);
    //   tempFormData.respondent = event;
    //   tempFormDataError.respondent.isError = false;
    //   tempFormDataError.respondent.errorMessage = "";

      

    //   /// set message ///
    //   tempFormData.message = `<p>
    //   <p>Dear ${tempFormData.respondent.employeeName},</p>

    //   <p> I hope this message finds you well.</p>

    //   <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
    //   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

    //   Best regards,
    //   <p>${employeeDetails?.name}</p>
    //   </p>`;

    // }
    // if (fieldType == "editor") {
    //   tempFormData.message = event;
    //   tempFormDataError.message.isError = false;
    //   tempFormDataError.message.errorMessage = "";
    // }

    // setFormData(() => tempFormData);
    // setFormDataError(() => tempFormDataError);

    
  };

  const validateData = (tempdataRowList) => {
    const tempFormDataError = { ...formDataError };
    let isValid = true;
    // if (isEmptyNullUndefined(formData.respondent)) {
    //   tempFormDataError.respondent.isError = true;
    //   tempFormDataError.respondent.errorMessage = "Please select respondent.";
    //   isValid = false;
    // }
    // if (isEmptyNullUndefined(formData.criticality)) {
    //   tempFormDataError.criticality.isError = true;
    //   tempFormDataError.criticality.errorMessage = "Please select criticality.";
    //   isValid = false;
    // }

    tempdataRowList.map((e,i) => {
      if(isEmptyNullUndefined(e.selectedSearchEmp)){
        isValid = false;
        e.selectedSearchEmpError = {isError: true, errorMessage: "Please select respondent."}
      }
      return e
    })
    if (isValid) {
      console.log('true is validate')
      submitRequest();
    } else {
      console.log('false is validate')
      // setFormDataError(() => tempFormDataError);
      setDataRowList(() => tempdataRowList)
    }
  };

  const submitRequest = () => { 
    let respondentList = []
    dataRowList.forEach((e,i) => {
      let respondentListRow = {
        requestedBy:{ 
          id: employeeDetails?.id
        },
        requestedTo: {
          id: e.selectedSearchEmp.employeeId
        },
        message: e.message,

        relationship: "Peer",
        isResend: false,
        managerInitiatesPeerFeedback :false,
        initiateManager :null,
        isCritical: false,
        feedbackFormId: null,
      }

      respondentList.push(respondentListRow)
    })
    let payload = {
      relationship: "Peer",
      isResend: false,
      managerInitiatesPeerFeedback :false,
      initiateManager :null,
      isCritical: false,
      feedbackFormId: null,
      respondentList: respondentList,
    }
    setSubmitLoader(true);
  
    APIList.sendFeedbackRequestMultiple(respondentList)  
    .then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              {` Request sent successfully`}
            </div>
          );
          setSubmitLoader(false);
          updateStakeholdersList()
          setIsOpen(false);
          setSearch("");
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.message}
            </div>
        );
        setSubmitLoader(false);
        setSearch("");
    })               
  }

  const handleAddMoreStackholder = () => {
    const tempinitialDataRow = JSON.parse(JSON.stringify(initialDataRow))
    const tempdataRowList = JSON.parse(JSON.stringify(dataRowList))

    tempdataRowList.push(tempinitialDataRow)
    setDataRowList(() => tempdataRowList)
  }

  const handleDeleteCard = (rowIndex, tempdataRowList) => {

    tempdataRowList.splice(rowIndex, 1)

    setDataRowList(() => tempdataRowList)

  }

  return (
    <div className="add-respondent-form">
      <div className="header-title">Add A Respondent</div>
      <div className="header-desc">(Nomination Closure on 20/10/2024)</div>

      <div className="respondent-addmore-card-container">


        {!isEmptyNullUndefined(dataRowList) && dataRowList.map((rowE, rowI) => {
          return (
            <div key={`${rowI}-card-row`} className="row-card d-flex gap-3 ">
              <div className="form-group">
                <div className="fields-container">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{marginBottom: '17px'}} className="question">
                      Respondent {`${rowI + 1}`}<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className="right-container-search">              
                                            <div className="search-wrapper">
                                              <div className="second-div">
                                                <Search 
                                                  // style={{margin: '0 !important'}}  
                                                  ref={wrapperRef}
                                                  onFocus={console.log('hhhhh')}
                                                >
                                                  <SearchIconWrapper>
                                                    <IoMdSearch />
                                                  </SearchIconWrapper>
                                                  <StyledInputBase
                                                    // placeholder="Search by username or email"
                                                    placeholder={rowE?.selectedSearchEmp?.employeeName ? rowE?.selectedSearchEmp?.employeeName : "Search by username or email"}
                                                    inputProps={{ "aria-label": "search" }}
                                                    onChange={(e) => {autoSearchText(e); handleChangeListRow(e, "search", rowI, JSON.parse(JSON.stringify(dataRowList)))}}
                                                    // value={search}
                                                    value={rowE.search}
                                                    // style={{border: '1px solid #D0D5DD',borderRadius: '4px'}}
                                                  />
                                                  {!isEmptyNullUndefined(search) && (
                                                    <IoCloseSharp
                                                      onClick={() => {
                                                        setSearch("");
                                                        setShowAllSearches(false);

                                                        let tempdataRowList = JSON.parse(JSON.stringify(dataRowList))
                                                        tempdataRowList[rowI].search = ""
                                                        setDataRowList(() => tempdataRowList)
                                                      }}
                                                      style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "34%",
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  )}

                                                  {!isEmptyNullUndefined(search) && isSearch && (
                                                    <div style={{
                                                      display: isEmptyNullUndefined(rowE.search) ? 'none': 'all'
                                                    }} className="search-popup-req-feed">
                                                      {searchLoader ? (
                                                        <>
                                                          <div className="search-emp-outer">
                                                            <div className="empDetaila">
                                                              <div className="empPic">
                                                                <Skeleton variant="circular" width={40} height={40} />
                                                              </div>
                                                              <div className="userInfo">
                                                                <p className="name">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: "7rem" }}
                                                                  />
                                                                </p>
                                                                <p className="designation">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                                  />
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                          <div className="search-emp-outer">
                                                            <div className="empDetaila">
                                                              <div className="empPic">
                                                                <Skeleton variant="circular" width={40} height={40} />
                                                              </div>
                                                              <div className="userInfo">
                                                                <p className="name">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: "7rem" }}
                                                                  />
                                                                </p>
                                                                <p className="designation">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                                  />
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                        </>
                                                      ) : searchedList?.length > 0 ? (
                                                        
                                                        <div>
                                                          {searchedList?.map((emp, index) => {
                                                            // Determine if there is a match
                                                            const isMatch = dataRowList?.some(e => e.selectedSearchEmp?.employeeId === emp?.employeeId);

                                                            return (
                                                              <div key={index} className="search-emp-outer">
                                                                <div
                                                                  style={{
                                                                    opacity: isMatch ? '40%' : '100%',
                                                                    cursor: 'pointer'
                                                                  }}
                                                                  onClick={() => {
                                                                    if(!isMatch) {
                                                                      handleChange(emp, "autocomplete");
                                                                      setSearch("");
                                                                      handleChangeListRow(emp, "selectedSearchEmp", rowI, JSON.parse(JSON.stringify(dataRowList)));
                                                                    }
                                                                  }}
                                                                  className="empDetaila"
                                                                >
                                                                  <div className="empPic">
                                                                    {emp?.profilePhotoPath ? (
                                                                      <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                                    ) : (
                                                                      <Avatar
                                                                        name={emp?.employeeName}
                                                                        size="40"
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                      />
                                                                    )}
                                                                  </div>
                                                                  <div className="userInfo">
                                                                    <p className="name">{emp?.employeeName}</p>
                                                                    <p className="designation">
                                                                      {emp?.employeeDesignation ?? ""}
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        width: "150px",
                                                                        overflow: "hidden",
                                                                      }}
                                                                      className="designation"
                                                                    >
                                                                      {emp?.emailId}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            );
                                                          })}
                                                          
                                                          {!showAllSearches && totalSearchPages > 1 && (
                                                            <div
                                                              className="view-all-search"
                                                              onClick={() => setShowAllSearches(true)}
                                                            >
                                                              View all
                                                            </div>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div className="no-result">No result</div>
                                                      )}
                                                    </div>
                                                  )}
                                                  {/* {!isEmptyNullUndefined(search) && isSearch && (
                                                    <div className="search-popup-req-feed">
                                                      {searchLoader ? (
                                                        <>
                                                          <div className="search-emp-outer">
                                                            <div className="empDetaila">
                                                              <div className="empPic">
                                                                <Skeleton variant="circular" width={40} height={40} />
                                                              </div>
                                                              <div className="userInfo">
                                                                <p className="name">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: "7rem" }}
                                                                  />
                                                                </p>
                                                                <p className="designation">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                                  />
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                          <div className="search-emp-outer">
                                                            <div className="empDetaila">
                                                              <div className="empPic">
                                                                <Skeleton variant="circular" width={40} height={40} />
                                                              </div>
                                                              <div className="userInfo">
                                                                <p className="name">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: "7rem" }}
                                                                  />
                                                                </p>
                                                                <p className="designation">
                                                                  <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                                  />
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                        </>
                                                      ) : searchedList?.length > 0 ? (
                                                        <div>
                                                          {searchedList?.map((emp, index) => {
                                                            return (
                                                              <div key={index} className="search-emp-outer">
                                                                
                                                                <div onClick={() => {handleChange(emp, "autocomplete"); setSearch(""); handleChangeListRow(emp, "selectedSearchEmp", index, JSON.parse(JSON.stringify(dataRowList)))}}  className="empDetaila">
                                                                  <div className="empPic">
                                                                    {emp?.profilePhotoPath ? (
                                                                      <img
                                                                        src={emp?.profilePhotoPath}
                                                                        className="userImg"
                                                                        alt="User Image"
                                                                      />
                                                                    ) : (
                                                                      <Avatar
                                                                        name={emp?.employeeName}
                                                                        size="40"
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                      />
                                                                    )}
                                                                  </div>
                                                                  <div className="userInfo">
                                                                    <p className="name">{emp?.employeeName}</p>
                                                                    <p className="designation">
                                                                      {emp?.employeeDesignation ?? ""}
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        width: "150px",
                                                                        overflow: "hidden",
                                                                      }}
                                                                      className="designation"
                                                                    >
                                                                      {emp?.emailId}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            );
                                                          })}
                                                          {!showAllSearches && totalSearchPages > 1 && (
                                                            <div
                                                              className="view-all-search"
                                                              onClick={() => setShowAllSearches(true)}
                                                            >
                                                              View all
                                                            </div>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div className="no-result">No result</div>
                                                      )}
                                                    </div>
                                                  )} */}
                                                </Search>
                                              </div>
                                            </div>
                                          
                    </div>
                    {/* {formDataError.respondent.isError && (
                      <FormHelperText className="Mui-error">
                        {formDataError.respondent.errorMessage}
                      </FormHelperText>
                    )} */}

                    {rowE.selectedSearchEmpError.isError && (
                      <FormHelperText className="Mui-error">
                        {rowE.selectedSearchEmpError.errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <p className="question">Any message for them?</p>
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  <JoditEditor
                    ref={editor}
                    config={config}
                    name="message"
                    // value={formData.message}
                    value={rowE.message}
                    // onChange={(event) => handleChange(event, "editor")}
                    // onBlur={(event) => handleChange(event, "editor")}
                    onBlur={(event) => handleChangeListRow(event, "editor", rowI, JSON.parse(JSON.stringify(dataRowList)))}
                  />
                </div>
              </div>
              <div className="delete-card d-flex">
                <button className="delete-button" onClick={() => handleDeleteCard(rowI, JSON.parse(JSON.stringify(dataRowList)))}>
                  <div className="icon d-flex align-items-center">
                    <DeleteIcon />
                    
                  </div>
                  <div className="title">
                    Delete
                  </div>
                </button>
              </div>
            </div>

          )
        })}
        {/* <div className="row-card d-flex gap-3 ">
          <div className="form-group">
            <div className="fields-container">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{marginBottom: '17px'}} className="question">
                  Respondent<span style={{ color: "red" }}>*</span>
                </p>
                <div className="right-container-search">              
                                        <div className="search-wrapper">
                                          <div className="second-div">
                                            <Search 
                                              // style={{margin: '0 !important'}}  
                                              ref={wrapperRef}
                                              onFocus={console.log('hhhhh')}
                                            >
                                              <SearchIconWrapper>
                                                <IoMdSearch />
                                              </SearchIconWrapper>
                                              <StyledInputBase
                                                // placeholder="Search by username or email"
                                                placeholder={formData?.respondent?.employeeName ? formData.respondent.employeeName : "Search by username or email"}
                                                inputProps={{ "aria-label": "search" }}
                                                onChange={autoSearchText}
                                                value={search}
                                                // style={{border: '1px solid #D0D5DD',borderRadius: '4px'}}
                                              />
                                              {!isEmptyNullUndefined(search) && (
                                                <IoCloseSharp
                                                  onClick={() => {
                                                    setSearch("");
                                                    setShowAllSearches(false);
                                                  }}
                                                  style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "34%",
                                                    color: "gray",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              )}

                                              {!isEmptyNullUndefined(search) && isSearch && (
                                                <div className="search-popup-req-feed">
                                                  {searchLoader ? (
                                                    <>
                                                      <div className="search-emp-outer">
                                                        <div className="empDetaila">
                                                          <div className="empPic">
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                          </div>
                                                          <div className="userInfo">
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: "7rem" }}
                                                              />
                                                            </p>
                                                            <p className="designation">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <p className="name">
                                                          <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                          />
                                                        </p>
                                                      </div>
                                                      <div className="search-emp-outer">
                                                        <div className="empDetaila">
                                                          <div className="empPic">
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                          </div>
                                                          <div className="userInfo">
                                                            <p className="name">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "1rem", width: "7rem" }}
                                                              />
                                                            </p>
                                                            <p className="designation">
                                                              <Skeleton
                                                                variant="text"
                                                                sx={{ fontSize: "0.5rem", width: "3rem" }}
                                                              />
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <p className="name">
                                                          <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1.5rem", width: "2rem" }}
                                                          />
                                                        </p>
                                                      </div>
                                                    </>
                                                  ) : searchedList?.length > 0 ? (
                                                    <div>
                                                      {searchedList?.map((emp, index) => {
                                                        return (
                                                          <div key={index} className="search-emp-outer">
                                                            
                                                            <div onClick={() => {setSearch(() => handleChange(emp, "autocomplete")); setSearch("");}}  className="empDetaila">
                                                              <div className="empPic">
                                                                {emp?.profilePhotoPath ? (
                                                                  <img
                                                                    src={emp?.profilePhotoPath}
                                                                    className="userImg"
                                                                    alt="User Image"
                                                                  />
                                                                ) : (
                                                                  <Avatar
                                                                    name={emp?.employeeName}
                                                                    size="40"
                                                                    className="userImg"
                                                                    color={"#00425A"}
                                                                  />
                                                                )}
                                                              </div>
                                                              <div className="userInfo">
                                                                <p className="name">{emp?.employeeName}</p>
                                                                <p className="designation">
                                                                  {emp?.employeeDesignation ?? ""}
                                                                </p>
                                                                <p
                                                                  style={{
                                                                    width: "150px",
                                                                    overflow: "hidden",
                                                                  }}
                                                                  className="designation"
                                                                >
                                                                  {emp?.emailId}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                      {!showAllSearches && totalSearchPages > 1 && (
                                                        <div
                                                          className="view-all-search"
                                                          onClick={() => setShowAllSearches(true)}
                                                        >
                                                          View all
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div className="no-result">No result</div>
                                                  )}
                                                </div>
                                              )}
                                            </Search>
                                          </div>
                                        </div>
                                      
                </div>
                {formDataError.respondent.isError && (
                  <FormHelperText className="Mui-error">
                    {formDataError.respondent.errorMessage}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <p className="question">Any message for them?</p>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <JoditEditor
                ref={editor}
                config={config}
                name="message"
                value={formData.message}
                // onChange={(event) => handleChange(event, "editor")}
                onBlur={(event) => handleChange(event, "editor")}
              />
            </div>
          </div>
          <div className="delete-card d-flex">
            <div className="icon d-flex align-items-center">
              <DeleteIcon />
              
            </div>
            <div className="title">
              Delete
            </div>
          </div>
        </div> */}
      </div>
      <div className="add-more-card">
        <button
          type="button"
          className="add-more-card-button"
          onClick={() => {handleAddMoreStackholder()}}
        >
          
       + Add Stakeholders
        </button>
      </div>
      <div className="form-actions">
        <button
          type="button"
          className="cancel-button"
          // onClick={() => {
          //   // setDisappear(true);
          //   setTimeout(() => {
          //     setIsOpen(false);
          //   }, 400);
          // }}
          onClick={() => handleClickOpen()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="button send-button"
          onClick={() => validateData(JSON.parse(JSON.stringify(dataRowList)))}
        >
          Send
        </button>
      </div>
      <Alert
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        action={() => {
            // setDisappear(true);
            handleClose(() => {
              setTimeout(() => {
                setIsOpen(false);
              }, 400);
            })
            
          }}
      />
    </div>
  );
};

export default AddRespondentForm;
