import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Box,
} from "@mui/material";
import { RiErrorWarningFill } from "react-icons/ri";
import {
  IoArrowDownOutline,
  IoFilter,
  IoArrowUp,
  IoClose,
} from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import RightArrow from "../../../assets/images/viewNominations/rightArrow.svg";
import UserProfile from "../../../assets/images/viewNominations/userProfile.svg";
import PlusCircle from "../../../assets/images/viewNominations/plusCircle.svg";
import MinusCircle from "../../../assets/images/viewNominations/minusCircle.svg";
import InfoCircle from "../../../assets/images/viewNominations/infoCircle.svg";
import TableFilterIcon from "../../../assets/images/viewNominations/tableFilterIcon.svg";
import DotsVerticalMenu from "../../../assets/images/viewNominations/dotsVerticalMenu.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import APIList from "../../../api";
import {
  getObjById,
  isEmptyNullUndefined,
  removeEmptyArrays,
  returnNameByKey,
} from "../../utils/utils";
import SideDrawer from "../../common/sideDrawer";
import AddStakeholders from "./AddStakeholders";
import EditStakeholders from "./EditStakeholders";
import viewProfile from "./viewProfile";

const ViewNominations = () => {
  //const [myTeamData, setMyTeamData] = useState();
  const { empId, id } = useParams();
  const [myTeamLoader, setMyTeamLoader] = useState(false);
  const [myTeamData, setMyTeamData] = useState();
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isEditOpenDrawer, setEditIsOpenDrawer] = useState(false);
  const [editData, setEditData] = useState();
  const [isProfileOpenDrawer, setProfileIsOpenDrawer] = useState(false);

  // const employeeDetails = useSelector((state) => state?.empData?.empData);
  useEffect(() => {
    if (id) {
      getMyTeamData();
    }
  }, [id, empId]);
  const getMyTeamData = (filteredData, isFiltered) => {
    setMyTeamLoader(true);
    APIList.getViewNomination({
      payload: {
        employeeEligibilityDTO: isFiltered == true ? filteredData : {}, //{},
        filterData: isFiltered == true ? true : false, //false,
        keyword: "",
        approverId: id,
        employeeId: empId,
        // companyId: employeeDetails?.company?.id,
        // "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
        page: "My Team",
        hrbp: activeTab === "Hrbp" ? true : false,
      },
      page: page - 1,
      size: 10,
    })
      .then((res) => {
        setMyTeamData(res?.data);

        setMyTeamLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setMyTeamLoader(false);
      });
  };

  console.log(empId, "data");
  console.log(id, "data");
  console.log(myTeamData, "myTeamData");

  const listOfStakeholders = [
    {
      userName: "Venkateshan V",
      userRole: "Software Engineer",
      email: "venkateshan@exmaple.com",
      formTemplate: "Form Template 1",
      criticality: "Not Critical",
      nominationStatus: "Approved",
      respondentStatus: "Responded",
    },
    {
      userName: "Hitesh Sharma",
      userRole: "Architect",
      email: "hiteshsharma@exmaple.com",
      formTemplate: "Form Template 2",
      criticality: "Critical",
      nominationStatus: "Approval Pending",
      respondentStatus: "",
    },
    {
      userName: "Kavita Singh",
      userRole: "Software Engineer",
      email: "Kavitasingh@exmaple.com",
      formTemplate: "-",
      criticality: "Critical",
      nominationStatus: "Denied",
      respondentStatus: "-",
    },
    {
      userName: "Lalita Thakur",
      userRole: "Architect",
      email: "lalita@exmaple.com",
      formTemplate: "Form Template 3",
      criticality: "Not Critical",
      nominationStatus: "Approval Pending",
      respondentStatus: "-",
    },
    {
      userName: "Nandan Raikwar",
      userRole: "Software Engineer",
      email: "nandan@exmaple.com",
      formTemplate: "Form Template 1",
      criticality: "Critical",
      nominationStatus: "Approval Pending",
      respondentStatus: "-",
    },
    {
      userName: "Manu Lodhi",
      userRole: "Architect",
      email: "nandan@exmaple.com",
      formTemplate: "Form Template 2",
      criticality: "Critical",
      nominationStatus: "Approved",
      respondentStatus: "Yet to Respond",
    },
  ];

  const listDirectReports = [
    {
      userName: "Venkateshan V",
      userRole: "Software Engineer",
      email: "venkateshan@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
    {
      userName: "Hitesh Sharma",
      userRole: "Architect",
      email: "hiteshsharma@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
    {
      userName: "Kavita Singh",
      userRole: "Software Engineer",
      email: "Kavitasingh@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
    {
      userName: "Lalita Thakur",
      userRole: "Architect",
      email: "lalita@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
    {
      userName: "Nandan Raikwar",
      userRole: "Software Engineer",
      email: "nandan@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
    {
      userName: "Manu Lodhi",
      userRole: "Architect",
      email: "nandan@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approved",
    },
  ];

  return (
    <Grid container className="view-nomination">
      {/*----- Header -----*/}
      <Grid item xs={12} md={12} sm={12} className="header-view">
        <Typography className="nomination-header-title">
          360 Feedback
        </Typography>
      </Grid>

      {/*----- Team & View Nominations -----*/}
      <Grid container item xs={12} md={12} sm={12} className="viewMt-10">
        <Typography className="team-nominations">
          {"Team Nominations"}
        </Typography>
        <img src={RightArrow} />
        <Typography className="view-nominations">
          {"View Nominations"}
        </Typography>
      </Grid>

      {/*----- User Profile Name & View Profile Button -----*/}
      <Grid
        container
        item
        xs={12}
        md={12}
        sm={12}
        className="viewMt-10 user-card"
      >
        <Grid className="d-flex">
          <img className="user-profile" src={UserProfile} />
          <Typography className="user-name">{myTeamData?.data&&myTeamData?.data.length>0&&myTeamData?.data[0]?.requestedBy?.name}</Typography>
        </Grid>
        <Button className="view-profile" onClick={() => setProfileIsOpenDrawer(true)}>{"View Profile"}</Button>
      </Grid>

      {/*----- Nominations Summary -----*/}
      <Grid item xs={12} md={12} sm={12} className="viewMt-15">
        <Grid className="d-flex aI-center">
          <img className="iconHW24" src={PlusCircle} />
          <Typography className="sub-heading">
            {"Nominations Summary"}
          </Typography>
          <img className="iconHW16" src={InfoCircle} />
        </Grid>
        <Typography className="nomination-date">
          {"Nomination Closure on 20/10/2024"}
        </Typography>
      </Grid>

      {/*----- List of Stakeholders -----*/}
      <Grid item xs={12} md={12} sm={12} className="viewMt-30">
        <Grid className="d-flex aI-center">
          <img className="iconHW24" src={MinusCircle} />
          <Typography className="sub-heading">
            {"List of Stakeholders"}
          </Typography>
          <img className="iconHW16" src={InfoCircle} />
        </Grid>
      </Grid>

      {/*----- Approve Selected & Add Stakeholders Button -----*/}
      <Grid item xs={12} md={12} sm={12} className="right-btn-grid">
        <Button className="approve-selected-btn">{"Approve Selected"}</Button>
        <Button className="add-stakeholders-btn" onClick={() => setIsOpenDrawer(true)}>{"Add Stakeholders"}</Button>
      </Grid>

      {/*----- Table card -----*/}
      <Grid item xs={12} md={12} sm={12} className="table-card">
        <Grid className="filter-grid">
          <Button className="filter-btn">
            <IoFilter />
            {"Filters"}
          </Button>
        </Grid>
        {!isEmptyNullUndefined(myTeamData) && (
          <Table>
            <TableHead style={{ background: "#F9FAFB" }}>
              <TableRow>
                <TableCell
                  className="table-stakeholders-header-style"
                  style={{ textAlign: "left", marginLeft: "15px" }}
                >
                  <Checkbox />
                  Name
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Email Id
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Form Template
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Criticality
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Nomination Status
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Respondent Status
                  <img className="table-filter-icon" src={TableFilterIcon} />
                </TableCell>
                <TableCell className="table-stakeholders-header-style">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {myTeamData?.data?.map((item, index) => (
                <TableRow key={index}>
                  {console.log(item.status, "data")}
                  <TableCell
                    className="table-stakeholders-header-style"
                    style={{ textAlign: "left" }}
                  >
                    <Grid className="table-user-grid">
                      <Checkbox />
                      <img className="iconHW40" src={UserProfile} />
                      <Grid className="table-ml-10">
                        <Typography className="table-user-name">
                          {item?.requestedTo?.name}
                        </Typography>
                        <Typography className="table-user-post">
                          {item?.requestedTo?.employeeDesignation}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    <Typography className="table-value-txt">
                      {item?.requestedTo?.emailId}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    <Typography className="table-value-txt">
                      {item?.feedbackFormName}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    <Typography className="table-value-txt">
                      {item?.isCritical ? "Not Critical" : "Critical"}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    <Typography
                      className={
                        item.status === "Approved" || item.status === "Pending"
                          ? "approved-responded-status"
                          : "approved-pending-denied-status"
                      }
                    >
                      {item?.status?.toLowerCase() === "pending"
                        ? "Approved"
                        : item.status}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    {["pending", "decline", "completed"].includes(
                      item?.status?.toLowerCase()
                    ) && (
                      <Typography
                        className={
                          item.respondentStatus === "Responded"
                            ? "approved-responded-status"
                            : "approved-pending-denied-status"
                        }
                      >
                        {item?.status?.toLowerCase() === "completed"
                          ? "Responded"
                          : item?.status?.toLowerCase() === "pending"
                          ? "Yet to Respond"
                          : item.status}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell className="table-stakeholders-header-style">
                    <img className="iconHW16" src={DotsVerticalMenu} onClick={() => {setEditIsOpenDrawer(true); setEditData(item)}}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>

      {/*----- Direct Reports -----*/}
      <Grid item xs={12} md={12} sm={12} className="viewMt-30">
        <Grid className="d-flex aI-center">
          <img className="iconHW24" src={MinusCircle} />
          <Typography className="sub-heading">{"Direct Reports"}</Typography>
          <img className="iconHW16" src={InfoCircle} />
        </Grid>
      </Grid>

      {/*----- Table card -----*/}
      <Grid item xs={12} md={12} sm={12} className="table-card">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="table-reports-header-style head-bg top-left-radius-12">
                Name
                <img className="table-filter-icon" src={TableFilterIcon} />
              </TableCell>
              <TableCell className="table-reports-header-style head-bg">
                Email Id
                <img className="table-filter-icon" src={TableFilterIcon} />
              </TableCell>
              <TableCell className="table-reports-header-style head-bg">
                Criticality
                <img className="table-filter-icon" src={TableFilterIcon} />
              </TableCell>
              <TableCell className="table-reports-header-style head-bg top-right-radius-12">
                Status
                <img className="table-filter-icon" src={TableFilterIcon} />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {listDirectReports.map((item, index) => (
              <TableRow>
                <TableCell
                  className="table-reports-header-style"
                  style={{ textAlign: "left" }}
                >
                  <Grid className="table-user-grid">
                    <img className="iconHW40" src={UserProfile} />
                    <Grid className="table-ml-10">
                      <Typography className="table-user-name">
                        {item.userName}
                      </Typography>
                      <Typography className="table-user-post">
                        {item.userRole}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className="table-reports-header-style">
                  <Typography className="table-value-txt">
                    {item.email}
                  </Typography>
                </TableCell>
                <TableCell className="table-reports-header-style">
                  <Typography className="table-value-txt">
                    {item.criticality}
                  </Typography>
                </TableCell>
                <TableCell className="table-reports-header-style">
                  <Typography
                    className={
                      item.nominationStatus === "Approved"
                        ? "approved-responded-status"
                        : "approved-pending-denied-status"
                    }
                  >
                    {item.nominationStatus}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <div>
        {isOpenDrawer && (
          <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
            <AddStakeholders
              isOpen={isOpenDrawer}
              setIsOpen={setIsOpenDrawer}
            />
          </SideDrawer>
        )}
      </div>
      <div>
        {isEditOpenDrawer && (
          <SideDrawer isOpen={isEditOpenDrawer} setIsOpen={setEditIsOpenDrawer}>
            <EditStakeholders
             editData={editData}
             setEditIsOpenDrawer={setEditIsOpenDrawer}
            />
          </SideDrawer>
        )}
      </div>
      <div>
        {isProfileOpenDrawer && (
          <SideDrawer isOpen={isProfileOpenDrawer} setIsOpen={setProfileIsOpenDrawer}>
            <viewProfile
              isProfileOpen={isProfileOpenDrawer}
              setProfileIsOpen={setProfileIsOpenDrawer}
              isEditOpen={isEditOpenDrawer}
              setEditIsOpen={setEditIsOpenDrawer}
            />
          </SideDrawer>
        )}
      </div>
    </Grid>
  );
};

export default ViewNominations;
