import React from "react";

function EmpProfile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#667085"
        fillRule="evenodd"
        d="M12 3a9 9 0 00-6.862 14.824A4.99 4.99 0 019 16h6a4.99 4.99 0 013.862 1.824A9 9 0 0012 3zm7.37 17.166A10.972 10.972 0 0023 12c0-6.075-4.925-11-11-11S1 5.925 1 12a10.972 10.972 0 003.665 8.198A10.96 10.96 0 0012 23a10.96 10.96 0 007.37-2.834zM17.4 19.2A2.996 2.996 0 0015 18H9c-.98 0-1.853.47-2.4 1.2A8.96 8.96 0 0012 21a8.96 8.96 0 005.4-1.8zM12 6.5a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default EmpProfile;