import React, { useState, useEffect } from "react";
import { LuDelete } from "react-icons/lu";
import UserProfile from "../../../assets/images/viewNominations/userProfile.svg";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";


function EditStakeholders(params) {
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  let editData = params.editData
  const [formData, setFormData] = useState({
    employeeId: employeeDetails?.id,
    isApproved: false,  // true if approve | false to denie
    workItemId: editData?.id,// feedback request id
    isCritical: editData?.isCritical,
    feedbackFormId: editData?.feedbackFormId,
    message: ''
  });
  const [totalPeerFormList, setTotalPeerFormList] = useState([])
  useEffect(() => {
    getThreeSixtyPeerFeedbackApi();
  }, []);

  const getThreeSixtyPeerFeedbackApi = async () => {
    try {
      const response = await APIList.getThreeSixtyPeerFeedback(employeeDetails?.company?.id);

      if (response.status == 200) {
        setTotalPeerFormList(response.data)
      }
    } catch (error) {

    }
  }
  const submitRequest = (approved) => {
    let payload = { ...formData, isApproved: approved }

    APIList.editStakeholder(payload)
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <BsFillCheckCircleFill
              style={{ width: "2rem", height: "2rem" }}
            />
            {` Request sent successfully`}
          </div>
        );
        params.setEditIsOpenDrawer(false)
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );

      })
  }
  return (
    <div style={{ width: '100%' }}>
      <div>
        <h1 style={{
          fontFamily: 'Inter',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '28px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          color: 'black'
        }}>Edit Stakeholders</h1>
        <p className="Add-Stakeholder-text">(Nomination Closure on 20/10/2024)</p>
      </div>
      <div>
        <div style={{ display: 'table-cell', textAlign: 'left' }} className="table-reports-header-style">
          <div className="table-user-grid" style={{ display: 'flex', alignItems: 'center' }}>
            <img className="iconHW40" src={UserProfile} alt="User Profile" />
            <div className="table-ml-10" style={{ marginLeft: '10px' }}>
              <div className="table-user-name" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {editData?.requestedTo?.name}
              </div>
              <div className="table-user-post" style={{ fontSize: '14px', color: '#555' }}>
                {editData?.requestedTo?.employeeDesignation}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{
        display: "grid",
        gridTemplateColumns: "1fr  1fr",
        gap: "16px",
        alignItems: "start",
        padding: "16px",
        borderRadius: "8px",
        margin: "auto"
      }}>
        <div style={{ gridColumn: "span 1" }}>
          <label htmlFor="feedback-type">Feedback critical to you?*</label>
          <select
            id="feedback-type"
            name="feedbackType"
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc"
            }}
            onChange={(e) => {
              setFormData({ ...formData, isCritical: e.target.value === "true" ? true : false })
            }}
            value={String(formData.isCritical)}
          >
            <option value="true">Critical</option>
            <option value="false">Not Critical</option>
          </select>
        </div>

        <div style={{ gridColumn: "span 1" }}>
          <label htmlFor="form-template">Form Template</label>
          <select
            id="form-template"
            name="formTemplate"
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc"
            }}
            onChange={(e) => {
              setFormData({ ...formData, feedbackFormId: e.target.value })
            }}
            value={formData.feedbackFormId}
          ><option disabled value=''>select form template</option>
            {
              totalPeerFormList && totalPeerFormList.length > 0 && totalPeerFormList.map((val) => {
                return <option value={val.id}>{val.displayName}</option>

              })
            }
          </select>
        </div>
        <div style={{ gridColumn: "span 1", color: 'red', display: 'flex', gap: '10px', cursor: "pointer" }} onClick={() => submitRequest(false)}>
          <LuDelete /> <p>Deny</p>
        </div>

        <div style={{ gridColumn: "span 3" }}>
          <label htmlFor="message">Any message for them? (Optional)</label>
          <textarea
            id="message"
            placeholder="Type your message here"
            onChange={(e) => {
              setFormData({ ...formData, message: e.target.value })
            }}
            value={formData.message}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              resize: "vertical"
            }}
          />
        </div>
      </div>
      <hr />
      <div style={{ float: 'right' }}>
        <div style={{ display: 'flex', gap: '8px', padding: '10px' }}>
          <button onClick={() => params.setEditIsOpenDrawer(false)} style={{
            padding: '8px 16px',
            backgroundColor: '#f0f0f0',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>Cancel</button>
          <button onClick={() => submitRequest(true)} style={{
            padding: '8px 16px',
            backgroundColor: '#7F56D9',
            color: '#fff',
            border: '1px solid #4CAF50',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>Approve</button>
        </div>
      </div>

    </div>
  );
}

export default EditStakeholders;
