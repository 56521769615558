import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import info from "../../../../assets/images/info.svg";
import DownHideShowArrow from "../../../../assets/images/downHideShowArrow.svg";
import RightHideShowArrow from "../../../../assets/images/rightHideShowArrow.svg";
import member1 from "../../../../assets/images/member1.svg"
import member2 from "../../../../assets/images/aj.svg"
import dot from "../../../../assets/images/graydot.svg"


const CheckIns = ({ }) => {
    const [visibleItem, setVisibleItem] = useState(null);

    const handleToggle = (key) => {
        setVisibleItem((prevVisibleItem) => (prevVisibleItem === key ? null : key));
    };

    const items = [
        { key: 'check1', label: 'Q2 2024' },
        { key: 'check2', label: 'Q1 2024 (No Data Captured)' },
        { key: 'check3', label: 'Q4 2023' },
        { key: 'check4', label: 'Q3 2023' },
    ];

    return (
        <Grid container className="check-ins-plan-card">
            <Grid item xs={12} md={12} sm={12}>
                <Typography className="check-ins-title">{"1.Reflection"}</Typography>

                <Grid container className="check-ins-ml-12">
                    <img src={info} />
                    <Typography className="title-notes">
                        {"Check-In is NOT LIVE, You can view earlier notes."}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} sm={12} className="check-in-border">
                {items.map((item) => (
                    <div key={item.key}>
                        <Grid container className="hide-show-grid" onClick={() => handleToggle(item.key)} >
                            <img className="right-down-arrow" src={visibleItem === item.key ? DownHideShowArrow : RightHideShowArrow} />
                            <Typography className="hide-show-title">{item.label}</Typography>
                        </Grid>


                        {visibleItem === item.key && (
                            <Grid container className="toggle-content">
                                <Grid item xs={12} md={4} sm={4}>
                                    <Typography className="toggle-first-qu">{"What is going well / went well ?"}</Typography>
                                    <Typography className="toggle-qu">{"What can be improved ?"}</Typography>
                                    <Typography className="toggle-qu">{"Share your aspirations/new skills (you want to work on structurally)"}</Typography>
                                </Grid>
                                <Grid item xs={12} md={1} sm={1}>
                                    <div className="toggle-vertical-border" />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <div className="checkin-membership-list">
                                        <img src={member1} />
                                        <div className="membership-inner">
                                            <Typography className="member-name">{"Gowtham Vairavan"}</Typography>
                                            <div className="emp-date-div">
                                                <Typography className="created mb-0">{"Employee"}</Typography> <img className="dotIcon" src={dot} /> <Typography className="created mb-0"><span className="dateTitle">{"July 06, 2024"}</span></Typography>
                                            </div>
                                            <Typography className="emp-desc">{"Earn career credentials from industry leaders that demonstrate your expertise."}</Typography>
                                        </div>
                                    </div>

                                    <div className="checkin-membership-list">
                                        <img src={member2} />
                                        <div className="membership-inner">
                                            <Typography className="member-name">{"Sayed Adil Hussain"}</Typography>
                                            <div className="emp-date-div">
                                                <Typography className="created mb-0">{"Manager (you)"}</Typography> <img className="dotIcon" src={dot} /> <Typography className="created mb-0"><span className="dateTitle">{"July 06, 2024"}</span></Typography>
                                            </div>
                                            <Typography className="emp-desc">{"Earn career credentials from industry leaders that demonstrate your expertise. "}</Typography>
                                            <textarea type="text" class="form-control" id="" aria-describedby="" placeholder="Earn career credentials from industry leaders that demonstrate your expertise."></textarea>
                                        </div>
                                    </div>
                                </Grid>

                                {/*----- Save & Submit Button -----*/}
                                <Grid item xs={12} md={12} sm={12} className="save-submit-btn-grid">
                                    <Button className="save-btn">{"Save"}</Button>
                                    <Button className="submit-btn">{"Submit"}</Button>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(CheckIns);
