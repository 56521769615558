import {Chip} from '@mui/material';
import { blue, orange, red, grey } from '@mui/material/colors';

export const getStatusChip = (status) => {
    switch (status) {
      case 'Pending':
        return <Chip label="Approved" style={{ backgroundColor: '#EFF8FF', color: '#1570EF' }} />;
      case 'Submitted':
        return <Chip label="Approved" style={{ backgroundColor: '#EFF8FF', color: '#1570EF' }} />;
      case 'Declined':
        return <Chip label="Approved" style={{ backgroundColor: '#EFF8FF', color: '#1570EF' }} />;
      case 'Pending For Approval':
          return <Chip label="Approval Pending" style={{ backgroundColor: '#FFFAEB', color: '#B54708' }} />;
      case 'Denied':
          return <Chip label="Denied" style={{ backgroundColor: '#FEF3F2', color: '#B42318' }} />;
      // case 'Approved':
      //   return <Chip label="Approved" style={{ backgroundColor: blue[50], color: blue[500] }} />;
      // case 'Approval Pending':
      //   return <Chip label="Approval Pending" style={{ backgroundColor: orange[50], color: orange[500] }} />;
      // case 'Denied':
      //   return <Chip label="Denied" style={{ backgroundColor: red[50], color: red[500] }} />;
      // case 'Recalled':
      //   return <Chip label="Recalled" style={{ backgroundColor: grey[50], color: grey[500] }} />;
    	// case 'Pending':
      //   return <Chip label="Pending" style={{ backgroundColor: orange[50], color: orange[500] }} />;
			// case 'Pending For Approval':
			// 	return <Chip label="Pending For Approval" style={{ backgroundColor: orange[50], color: orange[500] }} />;
      default:
        return <Chip label={status} />;
    }
};