import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers";
import sagas from "./sagas";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"], // List of reducers to persist
};

export default function globalStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();

  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware],
    preloadedState,
  });

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store);

  return { store, persistor };
}
