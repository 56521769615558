import rightArrow from "../../../assets/images/rightArrow.svg"
import searchIcon from "../../../assets/images/searchIcon.svg"
import userImage from "../../../assets/images/user.png"
import hand from "../../../assets/images/hand.svg"
import mail from "../../../assets/images/mail.svg"
import phone from "../../../assets/images/phone.svg"
import plus from "../../../assets/images/plus.svg"
import filter from "../../../assets/images/filter.svg"
import minusClose from "../../../assets/images/minusClose.svg"
import chartImg from "../../../assets/images/canvas_image.png"
import plusOpen from "../../../assets/images/plusOpen.svg"
import pdfDownload from "../../../assets/images/pdfDownload.svg"
import selectDropDown from "../../../assets/images/selectDropdown.svg"
import movementJourney from "../../../assets/images/movementJourney.png"
import roundIcon from "../../../assets/images/roundIcon.svg"
import linkIcon from "../../../assets/images/link.svg"
import plusWhite from "../../../assets/images/plusWhite.svg"
import notes from "../../../assets/images/notes.svg"
import circleArrow from "../../../assets/images/circleArrow.svg"
import edit from "../../../assets/images/edit.svg"
import info from "../../../assets/images/info.svg"
import illustration from "../../../assets/images/Illustration.svg"
import send from "../../../assets/images/send.svg"
import deleteImg from "../../../assets/images/delete.svg"
import editDisabled from "../../../assets/images/editDisabled.svg"
import infoCircular from "../../../assets/images/infoCircular.svg"
import crossImg from "../../../assets/images/cross.svg"

import { useEffect, useState } from "react"
import APIList from "../../../api"
import { RiErrorWarningFill } from "react-icons/ri"
import { useSelector,useDispatch} from "react-redux"
import { toast } from "react-toastify"
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";
import ThreeSixtyHeader from "../../../assets/images/dynamicSidebar/threeSixtyHeader"
import ThreeSixtyHeaderIcon from "../../../assets/images/dynamicSidebar/threeSixtyHeaderIcon"

const EmployeePlan = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("MyPlan");
    const [activeTab2, setActiveTab2] = useState("Open");
    const [activeTab3, setActiveTab3] = useState("Task1");

    const [myTeamData, setMyTeamData] = useState([]);
    const [myTeamLoader, setMyTeamLoader] = useState(false);

    useEffect(() => {
        setMyTeamLoader(true)
        APIList.getMyTeam({
            payload: {
                employeeEligibilityDTO: {},
                filterData: false,
                keyword: "",
                // managerId: employeeDetails?.id,
                // companyId: employeeDetails?.company?.id,
                "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
                "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
                page: "My Team"
            },
            page: 0,
            size: 10
        }).then((res) => {
            setMyTeamData(res?.data);
            setMyTeamLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setMyTeamLoader(false);
        })
    }, [])

    useEffect(() => {
        console.log("is here")
        //if (!isEmptyNullUndefined(userToken)) {
          dispatch(EmployeeDataActions.PerformanceHistoryRequest());
        // } else {
        //   history.push("/login");
        // }
      }, []);

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/posts/1')
            .then(response => response.json())
            .then(data => {
                setData({
                    title: data.title, 
                    subTitle: data.body
                });
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const [data, setData] = useState({
        title: '',
        subTitle: ''
    });
    
    return(
        <div className="emp-plan-main">
            <p className="header-title">360 Feedback</p>

            <div style={{
                // position: 'relative'
            }}>
                
                {/* <ThreeSixtyHeader /> */}

                <div
                    style={{
                        // position: 'absolute',
                        // top: '50%',
                        // left: '50%',
                        // transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '2rem',

                        background: '#53389E',
                        borderRadius: '12px'
                    }}
                >
                    <div 
                        className="text"
                        style={{
                            alignContent: 'center',
                        }}
                    >
                        <div 
                            className="header"
                            style={{
                                fontWeight: '600',
                                fontSize: '24px',
                                color: 'white',
                                marginBottom: '8px'
                            }}
                        >
                            Welcome to Mid Year 2023-24: 360 Feedback Process
                        </div>
                        <div className="paragraph">
                            <div 
                                style={{
                                    fontWeight: '400',
                                    fontSize: '14',
                                    color: '#D6BBFB',
                                }}
                            >
                                1. Nomination - Click on Ask for feedback to nominate...
                            </div>
                            <div
                                style={{
                                    fontWeight: '400',
                                    fontSize: '14',
                                    color: '#D6BBFB',
                                }}
                            >
                                2. Evaluation - Under Give feedback to others..
                            </div>
                        </div>
                    </div>
                    <div className="icon">
                        <ThreeSixtyHeaderIcon />
                    </div>
                </div>
            </div>

            <div className="profile">
                <div 
                    className="tabs3"
                    
                >
                        <div 
                            className="tabs-block"
                            style={{
                                width: 'max-content',
                                marginTop: '23px',

                            }}
                        >
                            <div className={`tab ${activeTab === "Ask-For-Feedback" ? "active" : ""}`} onClick={() => setActiveTab("Ask-For-Feedback")}>Ask For Feedback</div>
                            <div className={`tab ${activeTab === "Give-Feedback" ? "active" : ""}`} onClick={() => setActiveTab("Give-Feedback")}>Give Feedback</div>
                        </div>
                </div>

            </div>

            <div className=" d-flex justify-content-between">
                <div className="">
                    <div 
                        style={{
                            // fontFamily: 'intern',
                            fontWeight: '600',
                            fontSize: '20px',
                            color: '#000000'
                        }}
                    >Ask For Feedback</div>
                    <div
                        style={{
                            // fontFamily: 'intern',
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#667085'
                        }}
                    >( OPEN till 20/10/2024 )</div>
                </div>
                <div className="">
                    <button 
                        className=""
                        style={{
                            border: '1px solid #D0D5DD',
                            background: 'white',
                            alignContent: 'center',
                            borderRadius: '7px',
                            margin: '0px 6px',
                            padding: '7px 19px'
                        }}
                    >
                        Nudge Manager for Approval
                       
                    </button>
                    <button 
                        className=""
                        style={{
                            border: '1px solid #7F56D9',
                            background: '#7F56D9',
                            color: 'white',
                            borderRadius: '7px',
                            margin: '0px 6px',
                            padding: '7px 19px'
                        }}      
                    >
                        Select Respondents
                    </button>
                </div>
            </div>












            
            <div 
                className="profile"
                style={{
                    marginTop: '30px',
                }}
            >
               

               
                <div className="profile-details">
                    <div className="workflows">
                        {/* <p className="title">Workflows</p> */}
                        <div className="rating">
                            <div className="ratingInner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="right"></th>
                                            <th>Approval Pending</th>
                                            <th>Approved</th>
                                            <th>Rejected by Manager</th>
                                            <th>Nominations by Manager</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="bg right">Direct Repartees</td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg right">Skip Repartees</td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="bg right">Stakeholders</td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="bg right">Total</td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                            <td>                                     
                                                <span className="tableArrowCorcle">3</span>
                                            </td>
                                            <td>
                                                <span className="tableArrowCorcle">1</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EmployeePlan;