import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Avatar,
  Stack,
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from "@mui/material";
import { isEmptyNullUndefined, removeEmptyArrays } from "../../utils/utils";
import { IoArrowDownOutline, IoArrowUp, IoFilter } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import Filters from "./Filters";
import Pagination from "@mui/material/Pagination";
import APIList from "../../../api";
import { getStatusChip } from "./utils";
//////////////////// tab import//////
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Message from './Message'
import InfoCard from "./InfoCard";
import HeaderWellcomeCard from "./HeaderWellcomeCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import searchIcon from "../../../assets/images/searchIcon.svg";
import HeadDocumentIcon from "../../../assets/images/AskForFeedback/headDocument.svg";
import HeadBellIcon from "../../../assets/images/AskForFeedback/headBell.svg";

import CloseIcon from "../../../assets/images/close.svg";
import UserIcon from "../../../assets/images/user.png";
import MinusCircleIcon from "../../../assets/images/minus-circle.svg";
///////////////////// tab import end /////////

const AskForFeedback = () => {
  const history = useHistory();
  const dropdownRef = useRef(null);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const dispatch = useDispatch();

  const initialFilters = {
    // employeeGrade: [],
    // employeeLevel: [],
    // employeeSubfunction: [],
    // gender: [],
    // pod: [],
    // phonepeTenure: [],
    // timeSinceLastPromotion: [],
  };
  const data = [
    {
      name: "Venkateshan V",
      role: "Software Engineer",
      email: "venkateshan@exmaple.com",
      criticality: "Not Critical",
      nominationStatus: "Approved",
      avatar: "path_to_avatar1.jpg",
    },
    {
      name: "Hitesh Sharma",
      role: "Architect",
      email: "hiteshsharma@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approval Pending",
      avatar: "path_to_avatar2.jpg",
    },
    // Additional rows
  ];
  const [myTeamDataSearch, setMyTeamDataSearch] = useState([]);
  const [infoCardData, setInfoCardData] = useState(null)
  const [value, setValue] = React.useState('To do'); // for tab
  const [open, setOpen] = React.useState(false);
  const [filterData, setFilterData] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );
  const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );
  const [page, setPage] = useState(1);
  const [myTeamLoader, setMyTeamLoader] = useState(false);
  const [myTeamData, setMyTeamData] = useState([]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });
  const [sortType, setSortType] = useState({
    type: "descending",
    sortBy: "",
  });
  const [requestCounts, setRequestCounts] = useState({
    completedRequestCount: 0,
    declinedRequestCount: 0,
    expiredRequestCount: 0,
    pendingRequestCount: 0,
  });
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchvalue, setSearchValue] = useState("");

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      getSentCount()
      getMyTeamData(); // selected tab
    }
  }, [employeeDetails.id]);

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      let isFiltered = checkIsThereAnyFilter(
        JSON.parse(JSON.stringify(selectedOptions))
      );
      getMyTeamData(selectedOptions, isFiltered);
    }
  }, [page]);

  useEffect(() => {
    if (searchvalue) {
      const countdownInterval = setTimeout(() => { getMyTeamData(filterData, false, null, searchvalue) }, 2000)
      return () => clearTimeout(countdownInterval);
    }
  }, [searchvalue])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  ////// search start
  const handleChangeSearch = (event, newInputValue) => {
    setSearchValue(newInputValue);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getMyTeamData(filterData, false, null, searchvalue)
    }
  };

  ///// search end

  const getSentCount = () => {
    APIList.getReceivedRequestCount({ id: employeeDetails?.id })
      .then((res) => {
        setRequestCounts(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleChangeTab = (event, newValue) => { //for tab
    setValue(newValue);
    getMyTeamData(filterData, false, newValue)

    setSearchValue("");
    setPage(1)
    setSortType(() => ({
      type: "descending",
      sortBy: "",
    }))

  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingDropDownOpen(false);
    }
  };

  const handleSetInitialSelectedOptions = (initialSelectedOption) => {
    setSelectedOptions(() => initialSelectedOption);
  };

  const handleUpdatefilterDataList = (filterDataList) => {
    setFilterData(() => filterDataList);
  };

  const settingHandler = () => {
    if (!isSettingDropDownOpen) {
      setIsSettingDropDownOpen(true);
    }
  };

  const handleCloseFilter = () => {
    setIsSettingDropDownOpen(() => false);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSortDomCreateion = (domSortBy) => {
    if (sortType.sortBy == domSortBy) {
      if (sortType.type == "descending") {
        return (
          <IoArrowUp
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      } else {
        return (
          <IoArrowDownOutline
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      }
    } else {
      return (
        <TbArrowsSort
          style={{
            cursor: "pointer",
            pointerEvents: myTeamLoader ? "none" : "auto",
            opacity: myTeamLoader ? "30%" : "100%",
          }}
          onClick={() => {
            handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
          }}
        />
      );
    }
  };

  const handleFilteredData = (selectedOptions) => {
    checkIsThereAnyFilter(selectedOptions)
      ? getMyTeamData(selectedOptions, true)
      : getMyTeamData(); // true for isFiltered
    handleCloseFilter();

    setSelectedOptions(() => selectedOptions);
    console.log("selectedOptions", selectedOptions);

    //
    let tempfilterData = JSON.parse(JSON.stringify(filterData));
    let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    // setting initialOpen
    let tempOpen = {};
    selectedOptionsArray?.forEach((e, i) => {
      tempOpen[e] = null;
    });
    // setOpen(() => tempOpen);
  };

  const checkIsThereAnyFilter = (selectedOptions) => {
    let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    ///// check is filter empty
    let onlyDataSelectedOptions = removeEmptyArrays(
      JSON.parse(JSON.stringify(tempselectedOptions))
    );
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    ////

    if (selectedOptionsArray.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSort = (tempmyTeamData, soryBy) => {
    //myTeamData, 'employeeName'
    const getSortedList = (sortBy, sortDir, filteredData, isFiltered) => {
      setMyTeamLoader(true);
      // api/new-employees/myTeam/filter?page=0&size=10&sortBy=employeeGrade&sortDir=desc
      let data = null;

      let statusByTab = ''
      if (value === 'To do') {
        statusByTab = "Pending"
      } else if (value === 'Completed') {
        statusByTab = "Completed"
      } else if (value === 'Declined') {
        statusByTab = "Declined"
      }
      APIList.getFeedbackRequestSort({
        payload: {
          status: statusByTab,
          keyword: "",
          employeeId: employeeDetails?.id,
          employeeEligibilityDTO:
            isFiltered == true
              ? filteredData
              : {
                employeeLevel: [],
                employeeType: [],
                employeeStatus: [],
                employementStatus: [],
                employeeFunction: [],
                employeeSubFunction: [],
              },
        },
        page: page - 1,
        size: 10,
        sortBy,
        sortOrder: sortDir,
      })
        .then((res) => {
          setMyTeamData(() => res.data);
          setMyTeamLoader(false);
        })
        .catch((err) => {
          setMyTeamLoader(false);
          console.log(err);
        });
    };
    let tempsortType = { ...sortType };

    if (tempsortType.sortBy == soryBy) {
      if (tempsortType.type == "descending") {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getSortedList(soryBy, "desc", selectedOptions, true)
          : getSortedList(soryBy, "desc"); //sortBy, sortDir  asc and desc
        tempsortType.type = "ascending";

        tempsortType.sortBy = soryBy;
        setSortType(() => tempsortType);
      } else {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getMyTeamData(selectedOptions, true)
          : getMyTeamData();
        tempsortType.type = "descending";

        tempsortType.sortBy = "";
        setSortType(() => tempsortType);
      }
    } else {
      checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
        ? getSortedList(soryBy, "asc", selectedOptions, true)
        : getSortedList(soryBy, "asc"); //sortBy, sortDir  asc and desc

      tempsortType.type = "descending";

      tempsortType.sortBy = soryBy;
      setSortType(() => tempsortType);
    }
  };

  const getMyTeamData = (filteredData, isFiltered, selectedTab, keyword) => {
    let activeTab = null
    let statusByTab = ''
    if (selectedTab) {
      activeTab = selectedTab
    } else {
      activeTab = value
    }
    if (activeTab === 'To do') {
      statusByTab = "Pending"
    } else if (activeTab === 'Completed') {
      statusByTab = "Completed"
    } else if (activeTab === 'Declined') {
      statusByTab = "Declined"
    }
    setMyTeamLoader(true);
    APIList.getFeedbackRequests({
      payload: {
        employeeEligibilityDTO:
          isFiltered == true
            ? filteredData
            : {
              employeeLevel: [],
              employeeType: [],
              employeeStatus: [],
              employementStatus: [],
              employeeFunction: [],
              employeeSubFunction: [],
            },
        keyword: keyword || "",
        employeeId: employeeDetails?.id,
        status: statusByTab
      },
      page: page - 1,
      size: 10,
      // payload : {
      //   employeeEligibilityDTO:
      //     isFiltered == true
      //       ? filteredData
      //       : {
      //           employeeLevel: [],
      //           employeeType: [],
      //           employeeStatus: [],
      //           employementStatus: [],
      //           employeeFunction: [],
      //           employeeSubFunction: [],
      //         },
      //   keyword : "",
      //   employeeId : employeeDetails?.id,
      //   status: statusByTab
      // },
      // page: page - 1,
      // size: 10,
      // sortBy: "requestDate",
      // sortOrder: "asc"
    })
      .then((res) => {
        setMyTeamData(res?.data);
        setMyTeamLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setMyTeamLoader(false);
      });
  };

  const handleDecline = (id) => {
    setMyTeamLoader(true)
    handleClose();
    APIList.declineFeedbackRequests({ requestId: id })
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <BsFillCheckCircleFill
              style={{ width: "2rem", height: "2rem" }}
            />
            &nbsp;&nbsp;Declined.
          </div>
        );
        getMyTeamData()
        getSentCount();
        setMyTeamLoader(false)
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setMyTeamLoader(false)
      })
  }

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  return (
    <Grid container className="give-feedback-page">

      {/*----- Header -----*/}
      <Grid item xs={12} md={12} sm={12} className="header-grid">
        <Typography className="header-title">{"360 Feedback"}</Typography>

        <Grid className="d-flex gap-15">
          <img src={HeadDocumentIcon} className="headIcon" alt="profilePic"></img>
          <img src={HeadBellIcon} className="headIcon" alt="profilePic"></img>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sm={12}>
        {/*----- WelCome msg card -----*/}
        <HeaderWellcomeCard />

        {/*----- Give feedback & Feedback Window closure on -----*/}
        <Grid container className='give-feedback-grid'>
          <Grid item xs={12} md={6} sm={6}>
            <Typography className="give-feedback">{"Give feedback"}</Typography>
          </Grid>

          <Grid item xs={12} md={6} sm={6} className="ac-center">
            <Typography className="feedback-window-closure-on">{"Feedback Window closure on 30/11/2024"}</Typography>
          </Grid>

          <Grid item xs={12} md={12} sm={12} className="sub-card">
            <Typography className="description">{'Click on "Give Feedback" to share your feedback. If you choose not to give feedback, press "Decline" to notify their manager'}</Typography>
          </Grid>
        </Grid>


        <div className="w-100 d-flex justify-content-between table-header-filter-container">
          <div className="table-tab-container">
            <Box sx={{ width: '100%', typography: 'body1', border: 'none' }}>
              <TabContext sx={{ border: 'none' }} value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                    <Tab label={
                      <div className="tab-label-container d-flex">
                        <Typography className={value === 'To do' ? "select-tab-title" : "tab-title"}>
                          {"To do"}
                        </Typography>
                        <Typography className={value === 'To do' ? "select-tab-count" : "tab-count"}>
                          {requestCounts?.pendingRequestCount}
                        </Typography>
                      </div>
                    } value="To do" />
                    <Tab label={
                      <div className="tab-label-container d-flex">
                        <Typography className={value === 'Completed' ? "select-tab-title" : "tab-title"}>
                          {"Completed"}
                        </Typography>
                        <Typography className={value === 'Completed' ? "select-tab-count" : "tab-count"}>
                          {requestCounts?.completedRequestCount}
                        </Typography>
                      </div>
                    } value="Completed" />
                    <Tab label={
                      <div className="tab-label-container d-flex">
                        <Typography className={value === 'Declined' ? "select-tab-title" : "tab-title"}>
                          {"Declined"}
                        </Typography>
                        <Typography className={value === 'Declined' ? "select-tab-count" : "tab-count"}>
                          {requestCounts?.declinedRequestCount}
                        </Typography>
                      </div>
                    } value="Declined" />
                  </TabList>
                </Box>
                {/* <TabPanel value="To do">To do</TabPanel>
                        <TabPanel value="Completed">Completed</TabPanel>
                        <TabPanel value="Declined">Declined</TabPanel> */}
              </TabContext>
            </Box>
          </div>
          <div>
            <Autocomplete

              style={{
                width: '19rem'
              }}
              id="searchemp"
              disableClearable
              options={myTeamDataSearch?.data || []}
              fullWidth
              freeSolo
              disableCloseOnSelect
              onInputChange={handleChangeSearch}
              onKeyDown={handleKeyDown}
              getOptionLabel={(option) => option?.employeeName || ""}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    padding: 10,
                    borderBottom: "0.6px solid #F3F3F3",
                    // width: "100%",
                    width: '320px !important'
                  }}
                // onClick={() => clickEmp(option?.employeeId, option?.company)}
                >
                  <div className={"searchOptions"} style={{ display: "flex" }}>
                    <div><Avatar name={option?.employeeName || 'Unknown User'} size="40" round={true} /></div>
                    <div style={{ margin: "0rem 1.2rem" }}>{option?.employeeName}
                      <br />{option?.employeeLevel}</div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search using Name"
                  fullWidth
                  variant="outlined"
                  // style={{ width: "50%", margin: "0", padding: "0", height: "2.2rem", float: "right" }}
                  style={{ width: "100%", margin: "0", padding: "0", height: "2.2rem", float: "right" }}
                  InputProps={{
                    ...params.InputProps,
                    style: { height: "2.2rem", padding: "0 8px", lineHeight: "25px" }, // Adjust internal padding and height
                    endAdornment: (
                      <InputAdornment position="end">
                        {myTeamLoader ? <div className="d-flex"><CircularProgress style={{ width: '20px', height: '20px' }} /></div> : <img src={searchIcon} className="searchIcon" alt="Search Icon" />}
                        {/* <img src={searchIcon} className="searchIcon" alt="Search Icon" /> */}
                      </InputAdornment>

                    ),
                  }}
                />
              )}
            />
            {/* <div
                      className="search-filters d-flex align-items-baseline"
                      onClick={() => settingHandler()}
                      style={{
                        pointerEvents: isSettingDropDownOpen ? "none" : "auto",
                      }}
                    >
                      <div className="filters-icon">
                        <IoFilter />
                      </div>
                      <div className="filters-title">Filters</div>
                      <div className="filters-count">
                        {(() => {
                          let count = 0;
                          for (let key in selectedOptions) {
                            // if (obj.hasOwnProperty(key)) {
                            //     console.log(key, obj[key]);
                            // }
                            console.log("key", key);
                            count = count + selectedOptions[key].length;
                          }
                          if (!isEmptyNullUndefined(count) && !(count == 0)) {
                            return `+${count}`;
                          }
                        })()}
                      </div>
                    </div> */}
            {isSettingDropDownOpen ? (
              <div ref={dropdownRef}>
                <div>
                  <Filters
                    handleCloseFilter={() => handleCloseFilter()}
                    handleFilteredData={(filteredData) =>
                      handleFilteredData(filteredData)
                    }
                    parentSelectedOptions={selectedOptions}
                    handleUpdateParentfilterDataList={(filterDataList) =>
                      handleUpdatefilterDataList(filterDataList)
                    }
                    handleSetInitialSelectedOptions={(
                      initialSelectedOption
                    ) =>
                      handleSetInitialSelectedOptions(
                        initialSelectedOption
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {/* <EmployeeTable data={data} /> */}
        {!isEmptyNullUndefined(myTeamData) && (
          <TableContainer
            style={{ borderRadius: "0px 0px 12px 12px" }}
            component={Paper}
          >
            <Table>
              <TableHead style={{ background: "#F9FAFB" }}>
                <TableRow>
                  <TableCell
                    className="table-header-style table-name-sticky-coll-header"
                    style={{ textAlign: "center" }}
                  >
                    Name
                    {handleSortDomCreateion("employeeName")}
                  </TableCell>
                  <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Email Id
                    {handleSortDomCreateion("employeeEmail")}
                  </TableCell>
                  <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Category
                    {handleSortDomCreateion("category")}
                  </TableCell>
                  <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Form Type
                    {handleSortDomCreateion("formType")}
                  </TableCell>
                  {value === 'Declined' && <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Status
                    {handleSortDomCreateion("status")}
                  </TableCell>}
                  {((value === 'To do') || (value === 'Completed')) && <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Action
                    {/* {handleSortDomCreateion("status")} */}
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {myTeamLoader ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      {value === 'Declined' && <TableCell>
                        <Skeleton />
                      </TableCell>}
                      {((value === 'To do') || (value === 'Completed')) && <TableCell>
                        <Skeleton />
                      </TableCell>}

                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      {value === 'Declined' && <TableCell>
                        <Skeleton />
                      </TableCell>}
                      {((value === 'To do') || (value === 'Completed')) && <TableCell>
                        <Skeleton />
                      </TableCell>}

                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      {value === 'Declined' && <TableCell>
                        <Skeleton />
                      </TableCell>}
                      {((value === 'To do') || (value === 'Completed')) && <TableCell>
                        <Skeleton />
                      </TableCell>}

                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      {value === 'Declined' && <TableCell>
                        <Skeleton />
                      </TableCell>}
                      {((value === 'To do') || (value === 'Completed')) && <TableCell>
                        <Skeleton />
                      </TableCell>}

                    </TableRow>
                  </>
                ) : (
                  myTeamData.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-name-sticky-coll-body">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {row?.requestedBy?.profilePhoto ? (
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={row?.requestedBy?.profilePhoto}
                              className="userImage"
                              alt="User Profile"
                            />
                          ) : (
                            <Avatar
                              src={row?.requestedBy?.name}
                              alt={row?.requestedBy?.name}
                            />
                          )}
                          <div style={{ marginLeft: '10px' }}>
                            <div className=" d-flex align-items-center gap-2">
                              <div className='table-employee-name'>{row.requestedBy.name}</div>

                              <div className="message-namager-icon d-flex align-items-center gap-2">
                                <div className="message-icon d-flex align-items-center">
                                  <Message onClick={() => { setInfoCardData(row); handleClickOpen() }} />
                                </div>
                              </div>

                            </div>
                            {row?.requestedBy?.employeeDesignation ? <div className='table-employee-designation'>{row?.requestedBy?.employeeDesignation}</div> : <div className=" opacity-0">designation</div>}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.requestedBy.emailId}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row?.caregory} {/* currently category not present */}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row?.formType} {/* currently form type not present */}
                      </TableCell>
                      {value === 'Declined' && <TableCell style={{ textAlign: "center" }}>
                        {getStatusChip(row.status)}
                      </TableCell>}
                      {value === 'Completed' && <TableCell style={{ textAlign: "center" }}>
                        <button onClick={() => history.push(`/peer-feedBack/${row.id}`)} className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>View Form</button>
                      </TableCell>}
                      {value === 'To do' && <TableCell style={{ textAlign: "center" }}>
                        <button onClick={() => history.push(`/peer-feedBack/${row.id}`)} className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Give Feedback</button> |
                        <button onClick={() => handleDecline(row.id)} className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Decline</button>
                      </TableCell>}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isEmptyNullUndefined(myTeamData?.data) && (
          <Stack
            width={"fullWidth"}
            justifyContent={"center"}
            display={"flex"}
            textAlign={"center"}
          >
            No data found...!
          </Stack>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          {!myTeamLoader && (
            <Pagination
              count={myTeamData?.totalPages}
              page={page}
              onChange={handleChange}
            />
          )}
        </div>
      </Grid>
      <InfoCard
        open={open}
        onClose={handleClose}
        data={infoCardData}
      />

    </Grid>
  );
};

export default AskForFeedback;
