import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={174}
    height={130}
    viewBox="0 0 174 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3224_24107)">
      <path
        d="M51.7814 69.939C52.4186 72.5237 54.8164 74.2895 57.4736 74.1229C57.6934 74.1092 57.9119 74.0956 58.13 74.0807C59.7497 73.9756 61.1754 75.1424 61.39 76.7518L62.201 82.8352C62.4738 84.8816 64.971 85.7307 66.4335 84.2741C69.1022 81.6152 72.6055 77.7229 74.5649 73.9098C75.0285 73.0069 75.912 72.399 76.9193 72.2703C84.3896 71.3127 91.8231 70.0773 99.2184 68.5572C101.28 68.1345 102.873 66.5909 103.415 64.6461C103.563 64.1241 103.631 63.583 103.617 63.0407C103.376 52.7939 102.03 42.693 99.5775 32.7396C98.9407 30.1577 96.5427 28.4019 93.8898 28.5679C82.683 29.2681 71.5243 30.5965 60.4663 32.5467C57.6845 33.0355 54.91 33.5646 52.1435 34.1337C49.5383 34.6688 47.6828 36.9919 47.7446 39.6503C47.9852 49.8943 49.3311 59.9896 51.7814 69.939Z"
        fill="#DD638F"
      />
      <path
        d="M136.605 102.108C135.867 104.833 133.282 106.648 130.468 106.407C130.235 106.387 130.004 106.367 129.773 106.346C128.058 106.196 126.518 107.398 126.252 109.1L125.244 115.531C124.906 117.694 122.237 118.534 120.722 116.954C117.956 114.07 114.336 109.858 112.35 105.768C111.881 104.799 110.958 104.133 109.893 103.972C101.988 102.776 94.1308 101.283 86.3379 99.4953C84.1618 98.9972 82.5101 97.3219 81.9825 95.2467C81.8389 94.6897 81.7796 94.1142 81.8068 93.5396C82.3101 82.68 83.9824 72.0021 86.8236 61.5071C87.5613 58.7849 90.1465 56.9808 92.9556 57.2212C104.822 58.2347 116.622 59.9132 128.301 62.2487C131.236 62.8338 134.166 63.4621 137.087 64.1328C139.837 64.7629 141.748 67.2712 141.618 70.0887C141.116 80.9454 139.444 91.6176 136.605 102.108Z"
        fill="#FFC250"
      />
      <path
        d="M105.078 77.1795C105.438 74.8776 104.604 72.8283 103.218 72.6113C101.832 72.3942 100.411 74.0904 100.05 76.3921C99.6898 78.6939 100.524 80.7432 101.91 80.9603C103.296 81.1774 104.717 79.4814 105.078 77.1795ZM122.613 75.6489C121.227 75.4318 119.806 77.128 119.446 79.4297C119.085 81.7315 119.919 83.7808 121.306 83.9979C122.692 84.215 124.113 82.5188 124.473 80.2171C124.834 77.9153 123.999 75.8659 122.613 75.6489ZM119.888 86.7118C119.582 86.5223 119.213 86.4621 118.862 86.5446C118.511 86.6271 118.208 86.8454 118.018 87.1517C117.227 88.4342 116.074 89.4542 114.705 90.0835C113.336 90.7127 111.811 90.9231 110.322 90.6882C108.833 90.4567 107.446 89.7902 106.334 88.7725C105.223 87.7547 104.437 86.4312 104.076 84.9681C103.984 84.6236 103.761 84.3289 103.454 84.1476C103.147 83.9662 102.781 83.9128 102.435 83.9988C102.09 84.0848 101.791 84.3034 101.605 84.6073C101.419 84.9112 101.359 85.2762 101.44 85.6235C102.449 89.6835 105.771 92.7249 109.902 93.3719C114.033 94.0189 118.125 92.1386 120.327 88.5816C120.421 88.43 120.485 88.2613 120.513 88.0853C120.542 87.9093 120.536 87.7293 120.495 87.5557C120.454 87.3821 120.38 87.2182 120.275 87.0734C120.171 86.9286 120.04 86.8057 119.888 86.7118Z"
        fill="#454045"
      />
      <path
        d="M86.9476 40.2299L56.5118 44.2876C56.1547 44.3352 55.7934 44.2391 55.5073 44.0203C55.2212 43.8015 55.0337 43.478 54.9861 43.1209C54.9385 42.7639 55.0347 42.4026 55.2535 42.1165C55.4723 41.8304 55.7958 41.6429 56.1528 41.5953L86.5886 37.5375C86.9457 37.4899 87.307 37.5861 87.5931 37.8049C87.8792 38.0237 88.0667 38.3472 88.1143 38.7042C88.1619 39.0612 88.0657 39.4225 87.8469 39.7087C87.6281 39.9948 87.3046 40.1823 86.9476 40.2299ZM87.9986 48.1131L57.5628 52.1709C57.386 52.1945 57.2063 52.183 57.034 52.1371C56.8616 52.0913 56.7 52.0119 56.5583 51.9036C56.4167 51.7952 56.2977 51.66 56.2083 51.5057C56.1189 51.3514 56.0607 51.181 56.0371 51.0042C56.0136 50.8274 56.025 50.6478 56.0709 50.4754C56.1168 50.3031 56.1962 50.1414 56.3045 49.9998C56.4129 49.8581 56.548 49.7392 56.7023 49.6497C56.8566 49.5603 57.0271 49.5021 57.2038 49.4786L87.6396 45.4208C87.8164 45.3972 87.9961 45.4087 88.1685 45.4546C88.3408 45.5005 88.5024 45.5798 88.6441 45.6882C88.7858 45.7965 88.9047 45.9317 88.9941 46.086C89.0836 46.2403 89.1417 46.4107 89.1653 46.5875C89.1889 46.7643 89.1774 46.944 89.1315 47.1163C89.0856 47.2887 89.0063 47.4503 88.8979 47.592C88.7896 47.7336 88.6544 47.8526 88.5001 47.942C88.3458 48.0314 88.1754 48.0896 87.9986 48.1131ZM69.4736 58.6062L58.6138 60.054C58.2568 60.1016 57.8954 60.0055 57.6093 59.7867C57.3232 59.5679 57.1357 59.2444 57.0881 58.8873C57.0405 58.5303 57.1367 58.169 57.3555 57.8829C57.5743 57.5968 57.8978 57.4093 58.2548 57.3617L69.1147 55.9138C69.4717 55.8662 69.833 55.9624 70.1191 56.1812C70.4052 56.4 70.5927 56.7235 70.6403 57.0805C70.6879 57.4376 70.5917 57.7989 70.3729 58.085C70.1541 58.3711 69.8306 58.5586 69.4736 58.6062Z"
        fill="#F9F6F9"
      />
    </g>
    <path
      d="M133.96 40.9601L148.136 49"
      stroke="#E478FA"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M84.4268 1L76.4268 7"
      stroke="#67E3F9"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M52.8643 114.78L43.4268 127"
      stroke="#FEC84B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M4.71289 13.4788L0.823773 24.371"
      stroke="#FEC84B"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M83.4951 118.943L89.3585 126.057"
      stroke="#C3B5FD"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M26.4268 58L30.8698 72.1755"
      stroke="#C3B5FD"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M139.867 128.552L147.484 125.59"
      stroke="#32D583"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M165.427 12.9621L173.043 10"
      stroke="#6CE9A6"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <defs>
      <clipPath id="clip0_3224_24107">
        <rect
          width={97}
          height={90}
          fill="white"
          transform="translate(46.4268 28)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
