import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Avatar,
  Stack,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from "@mui/material";
import { isEmptyNullUndefined, removeEmptyArrays } from "../../../utils/utils";
import { getStatusChip } from "../utils";
import { IoArrowDownOutline, IoArrowUp, IoFilter } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import Filters from "../Filters";
import Pagination from "@mui/material/Pagination";
import APIList from "../../../../api";

const AskForFeedback = () => {
  const dropdownRef = useRef(null);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const dispatch = useDispatch();

  const initialFilters = {
    // employeeGrade: [],
    // employeeLevel: [],
    // employeeSubfunction: [],
    // gender: [],
    // pod: [],
    // phonepeTenure: [],
    // timeSinceLastPromotion: [],
  };
  const data = [
    {
      name: "Venkateshan V",
      role: "Software Engineer",
      email: "venkateshan@exmaple.com",
      criticality: "Not Critical",
      nominationStatus: "Approved",
      avatar: "path_to_avatar1.jpg",
    },
    {
      name: "Hitesh Sharma",
      role: "Architect",
      email: "hiteshsharma@exmaple.com",
      criticality: "Critical",
      nominationStatus: "Approval Pending",
      avatar: "path_to_avatar2.jpg",
    },
    // Additional rows
  ];

  const [open, setOpen] = React.useState({});
  const [filterData, setFilterData] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );
  const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );
  const [page, setPage] = useState(1);
  const [myTeamLoader, setMyTeamLoader] = useState(false);
  const [myTeamData, setMyTeamData] = useState([]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });
  const [sortType, setSortType] = useState({
    type: "descending",
    sortBy: "",
  });

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      getMyTeamData();
    }
  }, [employeeDetails.id]);

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      let isFiltered = checkIsThereAnyFilter(
        JSON.parse(JSON.stringify(selectedOptions))
      );
      getMyTeamData(selectedOptions, isFiltered);
    }
  }, [page]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingDropDownOpen(false);
    }
  };

  const handleSetInitialSelectedOptions = (initialSelectedOption) => {
    setSelectedOptions(() => initialSelectedOption);
  };

  const handleUpdatefilterDataList = (filterDataList) => {
    setFilterData(() => filterDataList);
  };

  const settingHandler = () => {
    if (!isSettingDropDownOpen) {
      setIsSettingDropDownOpen(true);
    }
  };

  const handleCloseFilter = () => {
    setIsSettingDropDownOpen(() => false);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSortDomCreateion = (domSortBy) => {
    if (sortType.sortBy == domSortBy) {
      if (sortType.type == "descending") {
        return (
          <IoArrowUp
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      } else {
        return (
          <IoArrowDownOutline
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      }
    } else {
      return (
        <TbArrowsSort
          style={{
            cursor: "pointer",
            pointerEvents: myTeamLoader ? "none" : "auto",
            opacity: myTeamLoader ? "30%" : "100%",
          }}
          onClick={() => {
            handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
          }}
        />
      );
    }
  };

  const handleFilteredData = (selectedOptions) => {
    checkIsThereAnyFilter(selectedOptions)
      ? getMyTeamData(selectedOptions, true)
      : getMyTeamData(); // true for isFiltered
    handleCloseFilter();

    setSelectedOptions(() => selectedOptions);
    console.log("selectedOptions", selectedOptions);

    //
    let tempfilterData = JSON.parse(JSON.stringify(filterData));
    let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    // setting initialOpen
    let tempOpen = {};
    selectedOptionsArray?.forEach((e, i) => {
      tempOpen[e] = null;
    });
    setOpen(() => tempOpen);
  };

  const checkIsThereAnyFilter = (selectedOptions) => {
    let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    ///// check is filter empty
    let onlyDataSelectedOptions = removeEmptyArrays(
      JSON.parse(JSON.stringify(tempselectedOptions))
    );
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    ////

    if (selectedOptionsArray.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSort = (tempmyTeamData, soryBy) => {
    //myTeamData, 'employeeName'
    const getSortedList = (sortBy, sortDir, filteredData, isFiltered) => {
      setMyTeamLoader(true);
      // api/new-employees/myTeam/filter?page=0&size=10&sortBy=employeeGrade&sortDir=desc
      let data = null;

      APIList.getSortedAssessmentResponseFeedbackRequestSentRequest({
        payload: {
          keyword: "",
          employeeId: employeeDetails?.id,
          employeeEligibilityDTO:
            isFiltered == true
              ? filteredData
              : {
                employeeLevel: [],
                employeeType: [],
                employeeStatus: [],
                employementStatus: [],
                employeeFunction: [],
                employeeSubFunction: [],
              }, //{},
          // filterData: (isFiltered == true) ? true : false, //false,
          // companyId: employeeDetails?.company?.id,
          //   "employeeEligibilityDTO": {
          //     "employeeLevel": [],
          //     "employeeType": [],
          //     "employeeStatus": [],
          //     "employementStatus": [],
          //     "employeeFunction": [],
          //     "employeeSubFunction": []
          // },
        },
        page: page - 1,
        size: 10,
        sortBy,
        sortDir,
      })
        .then((res) => {
          setMyTeamData(() => res.data);
          setMyTeamLoader(false);
        })
        .catch((err) => {
          setMyTeamLoader(false);
          console.log(err);
        });
    };
    let tempsortType = { ...sortType };

    if (tempsortType.sortBy == soryBy) {
      if (tempsortType.type == "descending") {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getSortedList(soryBy, "desc", selectedOptions, true)
          : getSortedList(soryBy, "desc"); //sortBy, sortDir  asc and desc
        tempsortType.type = "ascending";

        tempsortType.sortBy = soryBy;
        setSortType(() => tempsortType);
      } else {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getMyTeamData(selectedOptions, true)
          : getMyTeamData();
        tempsortType.type = "descending";

        tempsortType.sortBy = "";
        setSortType(() => tempsortType);
      }
    } else {
      checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
        ? getSortedList(soryBy, "asc", selectedOptions, true)
        : getSortedList(soryBy, "asc"); //sortBy, sortDir  asc and desc

      tempsortType.type = "descending";

      tempsortType.sortBy = soryBy;
      setSortType(() => tempsortType);
    }
  };

  const getMyTeamData = (filteredData, isFiltered) => {
    setMyTeamLoader(true);
    APIList.getAssessmentResponseFeedbackRequestDirectReportsRequest({
      payload: {
        employeeEligibilityDTO:
          isFiltered == true
            ? filteredData
            : {
              employeeLevel: [],
              employeeType: [],
              employeeStatus: [],
              employementStatus: [],
              employeeFunction: [],
              employeeSubFunction: [],
            }, //{},
        // filterData: (isFiltered == true) ? true : false, //false,
        keyword: "",
        // managerId: employeeDetails?.id,
        // companyId: employeeDetails?.company?.id,
        // page: "My Team",
        // hrbp: activeTab === 'Hrbp' ? true : false

        keyword: "",
        employeeId: employeeDetails?.id,

        //   "employeeEligibilityDTO": {
        //     "employeeLevel": [],
        //     "employeeType": [],
        //     "employeeStatus": [],
        //     "employementStatus": [],
        //     "employeeFunction": [],
        //     "employeeSubFunction": []
        // },
      },
      page: page - 1,
      size: 10,
    })
      .then((res) => {
        setMyTeamData(res?.data);
        setMyTeamLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setMyTeamLoader(false);
      });
  };

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  return (
    // <div className="ask-for-feedback-page">
      <div>
        <div className="w-100 d-flex justify-content-end table-header-filter-container">
          <div
            className="search-filters d-flex align-items-baseline"
            onClick={() => settingHandler()}
            style={{
              pointerEvents: isSettingDropDownOpen ? "none" : "auto",
            }}
          >
            <div className="filters-icon">
              <IoFilter />
            </div>
            <Typography className="filters-title">{"Filters"}</Typography>
            <Typography className="filters-count">
              {(() => {
                let count = 0;
                for (let key in selectedOptions) {
                  count = count + selectedOptions[key].length;
                }
                if (!isEmptyNullUndefined(count) && !(count == 0)) {
                  return `+${count}`;
                }
              })()}
            </Typography>
          </div>
          {isSettingDropDownOpen ? (
            <div ref={dropdownRef}>
              <div>
                <Filters
                  handleCloseFilter={() => handleCloseFilter()}
                  handleFilteredData={(filteredData) =>
                    handleFilteredData(filteredData)
                  }
                  parentSelectedOptions={selectedOptions}
                  handleUpdateParentfilterDataList={(filterDataList) =>
                    handleUpdatefilterDataList(filterDataList)
                  }
                  handleSetInitialSelectedOptions={(
                    initialSelectedOption
                  ) =>
                    handleSetInitialSelectedOptions(
                      initialSelectedOption
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/* <EmployeeTable data={data} /> */}
        {!isEmptyNullUndefined(myTeamData) && (
          <TableContainer
            style={{ borderRadius: "0px 0px 12px 12px" }}
            component={Paper}
          >
            <Table>
              <TableHead style={{ background: "#F9FAFB" }}>
                <TableRow>
                  <TableCell
                    className="table-header-style table-name-sticky-coll-header"
                    style={{ textAlign: "center" }}
                  >
                    Name
                    {handleSortDomCreateion("employeeName")}
                  </TableCell>
                  <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Email Id
                    {handleSortDomCreateion("employeeEmail")}
                  </TableCell>
                  {/* <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Criticality
                    {handleSortDomCreateion("criticality")}
                  </TableCell> */}
                  <TableCell
                    className="table-header-style"
                    style={{ textAlign: "center" }}
                  >
                    Status
                    {handleSortDomCreateion("status")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myTeamLoader ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  myTeamData.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-name-sticky-coll-body">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {row?.requestedTo?.profilePhoto ? (
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={row?.requestedTo?.profilePhoto}
                              className="userImage"
                              alt="User Profile"
                            />
                          ) : (
                            <Avatar
                              src={row?.requestedTo?.name}
                              alt={row?.requestedTo?.name}
                            />
                          )}
                          <div style={{ marginLeft: "10px" }}>
                            <div className="table-employee-name">
                              {row.requestedTo.name}
                            </div>
                            <div className="table-employee-designation">
                              {row.requestedTo.employeeDesignation}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.requestedTo.emailId}
                      </TableCell>
                      {/* <TableCell style={{ textAlign: "center" }}>
                                {row.isCritical ? "Critical" : "Not Critical"}
                              </TableCell> */}
                      <TableCell style={{ textAlign: "center" }}>
                        {getStatusChip(row.status)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isEmptyNullUndefined(myTeamData?.data) && (
          <Stack
            width={"fullWidth"}
            justifyContent={"center"}
            display={"flex"}
            textAlign={"center"}
          >
            No data found...!
          </Stack>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          {!myTeamLoader && (
            <Pagination
              count={myTeamData?.totalPages}
              page={page}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    // </div>
  );
};

export default AskForFeedback;
