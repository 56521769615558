import { IoClose } from "react-icons/io5";
import { Avatar, Menu, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import APIList from "../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoArrowDownOutline, IoFilter } from "react-icons/io5";
import {
  getObjById,
  isEmptyNullUndefined,
  removeEmptyArrays,
  returnNameByKey,
} from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ThreeSixtyHeaderIcon from "../../../assets/images/dynamicSidebar/threeSixtyHeaderIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Filters from "../teamview/Filters";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { IoArrowUp } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import { getStatusChip } from "../askForFeedback/utils";
import { saveAs } from 'file-saver';

const initialFilters = {
  // employeeGrade: [],
};

const ApproveNominations = () => {
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const dispatch = useDispatch();
  const [myTeamData, setMyTeamData] = useState();
console.log(myTeamData,"data")
console.log(myTeamData?.data, "data1")
  useEffect(() => {
    if (employeeDetails?.id) {
      getMyTeamData();
    }
  }, [employeeDetails]);

  const dropdownRef = useRef(null);
  // const { promisedata, profiledata } = useEmployee();
  const history = useHistory();
  const clickEmp = (empId, compId) => {
    history?.push(`/employee-profile/${empId}`);
  };

  const viewNomination = (empId) => {
    history?.push(`/view-nominations/${empId}/${employeeDetails?.id}`);
  };

  const [page, setPage] = useState(1);
  console.log("employeeDetails", employeeDetails);

  const [myTeamDataSearch, setMyTeamDataSearch] = useState([]);
  const [myTeamLoader, setMyTeamLoader] = useState(false);
  const [searchvalue, setSearchValue] = useState("");

  const [activeTab, setActiveTab] = useState(null);

  const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );

  const [filterData, setFilterData] = useState(
    JSON.parse(JSON.stringify(initialFilters))
  );

  const [searchLoading, setSearchLoading] = useState(false);

  const [isSubEmployeeLoading, setIsSubEmployeeLoading] = useState(false);
  const [subEmployeeLoadId, setSubEmployeeLoad] = useState(null);

  const handleCloseFilter = () => {
    setIsSettingDropDownOpen(() => false);
  };

  const getMyTeamData = (filteredData, isFiltered) => {
    setMyTeamLoader(true);
    APIList.getApproveNomination({
      payload: {
        employeeEligibilityDTO: isFiltered == true ? filteredData : {}, //{},
        filterData: isFiltered == true ? true : false, //false,
        keyword: "",
        managerId: employeeDetails?.id,
        companyId: employeeDetails?.company?.id,
        // "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
        // "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
        page: "My Team",
        hrbp: activeTab === "Hrbp" ? true : false,
      },
      page: page - 1,
      size: 10,
    })
      .then((res) => {
        setMyTeamData(res?.data);

        setMyTeamLoader(false);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
        setMyTeamLoader(false);
      });
  };

  
  const getDownloadExcelReports = (filteredData, isFiltered) => {
    setMyTeamLoader(true);
    const payload = {
        employeeEligibilityDTO: isFiltered ? filteredData : {},
        filterData: isFiltered,
        managerId: employeeDetails?.id,
        companyId: employeeDetails?.company?.id,
        reportId: "504f34bd-7bdd-41e1-9af9-06e4ace10832",
        email: null,
        queryId: null,
        cycleId: null,
        range: "My Team",
    };
    APIList.getDownloadApproveNomination({
        payload,
        page: page - 1,
        size: 10,
        responseType: 'arraybuffer', // Binary data response type
    })
    .then((res) => {
        if (res.status === 200) {
            // Extract filename from 'Content-Disposition' header, if available
            let filename = 'report.xlsx';
            const contentDisposition = res.headers['content-disposition'];
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }
            // Ensure response data is in the correct binary format for Excel
            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            // Trigger file download
            saveAs(blob, filename);
            toast.success('Report downloaded successfully!');
        } else {
            throw new Error('Failed to download: Server returned an error');
        }
    })
    .catch((err) => {
        toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{err?.message || 'An error occurred while downloading the file'}
            </div>
        );
    })
    .finally(() => {
        // Reset loader
        setMyTeamLoader(false);
    });
};
  

  const handleResetFilter = () => {
    setSelectedOptions(() => initialFilters);
    if (page == 1) {
      getMyTeamData();
    } else {
      setPage(() => 1); // this will auto call getMyTeamData throw useEffect
    }
  };

  const settingHandler = () => {
    if (!isSettingDropDownOpen) {
      setIsSettingDropDownOpen(true);
    }
  };

  const [open, setOpen] = React.useState({});
  const checkIsThereAnyFilter = (selectedOptions) => {
    let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    ///// check is filter empty
    let onlyDataSelectedOptions = removeEmptyArrays(
      JSON.parse(JSON.stringify(tempselectedOptions))
    );
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    ////

    if (selectedOptionsArray.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleFilteredData = (selectedOptions) => {
    checkIsThereAnyFilter(selectedOptions)
      ? getMyTeamData(selectedOptions, true)
      : getMyTeamData(); // true for isFiltered
    handleCloseFilter();

    setSelectedOptions(() => selectedOptions);
    console.log("selectedOptions", selectedOptions);

    //
    let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    // setting initialOpen
    let tempOpen = {};
    selectedOptionsArray?.forEach((e, i) => {
      tempOpen[e] = null;
    });
    setOpen(() => tempOpen);
  };

  const handleDeleteFilterCart = (
    selectedOption,
    index,
    tempselectedOptions
  ) => {
    tempselectedOptions[selectedOption].length = 0;
    ///// check is filter empty
    let onlyDataSelectedOptions = removeEmptyArrays(
      JSON.parse(JSON.stringify(tempselectedOptions))
    );
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    ////

    setSelectedOptions(() => tempselectedOptions);

    if (selectedOptionsArray.length == 0) {
      if (page == 1) {
        getMyTeamData();
      } else {
        setPage(() => 1);
      }
    } else {
      if (page == 1) {
        getMyTeamData(tempselectedOptions, true);
      } else {
        setPage(() => 1);
      }
    }
  };

  const handleUpdatefilterDataList = (filterDataList) => {
    setFilterData(() => filterDataList);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, name) => {
    console.log("handleClick name", name);
    let tempopen = JSON.parse(JSON.stringify(open));
    console.log("handleClick tempopen", tempopen);
    tempopen[name] = true;
    // console.log('event.currentTarget', event.currentTarget)
    setAnchorEl(event.currentTarget);
    setOpen(() => tempopen);
  };
  const handleClose = (name) => {
    setAnchorEl(null);
    console.log("handle close name", name);

    let tempopen = JSON.parse(JSON.stringify(open));
    // console.log('handleClick tempopen',tempopen)
    tempopen[name] = false;

    console.log("tempopen----", tempopen);
    setOpen(() => tempopen);
  };

  const [sortType, setSortType] = useState({
    type: "descending",
    sortBy: "",
  });

  const handleSort = (tempmyTeamData, soryBy) => {
    //myTeamData, 'employeeName'
    const getSortedList = (sortBy, sortDir, filteredData, isFiltered) => {
      setMyTeamLoader(true);
      // api/new-employees/myTeam/filter?page=0&size=10&sortBy=employeeGrade&sortDir=desc
      let data = null;

      APIList.getSortedAssessmentResponseFeedbackRequestDirectReportsRequest({
        payload: {
          keyword: "",
          employeeId: employeeDetails?.id,
          employeeEligibilityDTO:
            isFiltered == true
              ? filteredData
              : {
                  employeeLevel: [],
                  employeeType: [],
                  employeeStatus: [],
                  employementStatus: [],
                  employeeFunction: [],
                  employeeSubFunction: [],
                },
        },
        page: page - 1,
        size: 10,
        sortBy,
        sortDir,
      })
        .then((res) => {
          setMyTeamData(() => res?.data);
          setMyTeamLoader(false);
        })
        .catch((err) => {
          setMyTeamLoader(false);
          console.log(err);
        });
    };
    let tempsortType = { ...sortType };

    if (tempsortType.sortBy == soryBy) {
      if (tempsortType.type == "descending") {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getSortedList(soryBy, "desc", selectedOptions, true)
          : getSortedList(soryBy, "desc"); //sortBy, sortDir  asc and desc
        tempsortType.type = "ascending";

        tempsortType.sortBy = soryBy;
        setSortType(() => tempsortType);
      } else {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
          ? getMyTeamData(selectedOptions, true)
          : getMyTeamData();
        tempsortType.type = "descending";

        tempsortType.sortBy = "";
        setSortType(() => tempsortType);
      }
    } else {
      checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
        ? getSortedList(soryBy, "asc", selectedOptions, true)
        : getSortedList(soryBy, "asc"); //sortBy, sortDir  asc and desc

      tempsortType.type = "descending";

      tempsortType.sortBy = soryBy;
      setSortType(() => tempsortType);
    }
  };

  const handleSortDomCreateion = (domSortBy) => {
    if (sortType.sortBy == domSortBy) {
      if (sortType.type == "descending") {
        return (
          <IoArrowUp
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      } else {
        return (
          <IoArrowDownOutline
            style={{
              cursor: "pointer",
              pointerEvents: myTeamLoader ? "none" : "auto",
              opacity: myTeamLoader ? "30%" : "100%",
            }}
            onClick={() => {
              handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
            }}
          />
        );
      }
    } else {
      return (
        <TbArrowsSort
          style={{
            cursor: "pointer",
            pointerEvents: myTeamLoader ? "none" : "auto",
            opacity: myTeamLoader ? "30%" : "100%",
          }}
          onClick={() => {
            handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy);
          }}
        />
      );
    }
  };

  const renderFilterCards = () => {
    let tempfilterData = JSON.parse(JSON.stringify(filterData));
    let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);

    return selectedOptionsArray.map((selectedOption, index) => {
      console.log("tempfilterData--", tempfilterData);
      console.log("selectedOption--", selectedOption);
      return (
        <>
          <div
            key={index}
            className="d-flex filter-card"
            id="basic-button"
            aria-controls={open[selectedOption] ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open[selectedOption] ? "true" : undefined}
            onClick={(event) => handleClick(event, selectedOption)}
          >
            <div className="title">
              {returnNameByKey(selectedOption)}:
              {console.log(tempSelectedOptions[selectedOption][0])}
              {
                getObjById(
                  tempSelectedOptions[selectedOption][0],
                  tempfilterData[selectedOption]
                ).name
              }
            </div>
            {tempSelectedOptions[selectedOption].length > 1 ? (
              <div className="filter-count">
                {`+${tempSelectedOptions[selectedOption].length - 1}`}
              </div>
            ) : null}
            <div
              onClick={() =>
                handleDeleteFilterCart(
                  selectedOption,
                  index,
                  JSON.parse(JSON.stringify(selectedOptions))
                )
              }
              className="delete"
            >
              <IoClose />
            </div>
          </div>
          <Menu
            sx={{
              "& .MuiPopover-paper": {
                borderRadius: "8px",
              },
              "& .MuiMenuItem-root": {
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                textAlign: "left",
                pointerEvents: "none",
              },
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open[selectedOption]}
            onClose={() => handleClose(selectedOption)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {tempSelectedOptions[selectedOption].map((item, index) => (
              <MenuItem key={index} onClick={() => handleClose(selectedOption)}>
                {getObjById(item, tempfilterData[selectedOption]).name}
              </MenuItem>
            ))}
          </Menu>
        </>
      );
    });
  };

  const handleSetInitialSelectedOptions = (initialSelectedOption) => {
    setSelectedOptions(() => initialSelectedOption);
  };

  return (
    <div className="ask-for-feedback-page">
      <p className="header-title">360 Feedback</p>

      <div className="main-card">
        <div
          className="text"
          style={{
            alignContent: "center",
          }}
        >
          <div
            className="header"
            style={{
              fontWeight: "600",
              fontSize: "24px",
              color: "white",
              marginBottom: "8px",
            }}
          >
            Welcome to Mid Year 2023-24: 360 Feedback Process
          </div>
          <div className="paragraph">
            <div
              style={{
                fontWeight: "400",
                fontSize: "14",
                color: "#D6BBFB",
              }}
            >
              1. Nomination - Click on Ask for feedback to nominate stakeholders
              for 360 feedback. Once you’re done nominating stakeholders, click
              on send approval request.
            </div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "14",
                color: "#D6BBFB",
              }}
            >
              2. Evaluation - Under Give feedback to others, click on the
              relevant employee name to fill their 360 feedback form.
            </div>
          </div>
        </div>
        <div className="icon">
          <ThreeSixtyHeaderIcon />
        </div>
      </div>
      <div className="my-3">
        <h4>Finalize</h4>
        <p style={{ color: "grey" }}>
          Add approve feedback request for your team
        </p>
      </div>
      <div className="  d-flex gap-2 align-items-center justify-content-between my-3">
        <div className="d-flex gap-2">
          <p className="p-0" style={{ color: "grey" }}>
            Approval pending:{" "}
            <b style={{ color: "#000" }}>
              {myTeamData?.pendingForApprovalCount ?? 0} /{" "}
              {myTeamData?.totalEmployees ?? 0}
            </b>
          </p>
          <p className="p-0" style={{ color: "grey" }}>
            Direct report with ZERO nominations:{" "}
            <b style={{ color: "#000" }}>
              {myTeamData?.zeroNominationEmployees ?? 0} /{" "}
              {myTeamData?.totalEmployees ?? 0}
            </b>
          </p>
        </div>

        <div className="add-respondent m-0 flex    gap-2">
          <button className="button">
            <IoArrowDownOutline /> Nomination Status
          </button>

          <button className="button" onClick={getDownloadExcelReports}>
            <IoArrowDownOutline  /> Feedback Raw data
          </button>
        </div>
      </div>
      <div className="emp-profile-main p-0">
        <div className="breadcumb">
        </div>
        <div className="profile">
          <div className="searchBar">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="d-flex g-3 align-items-center">
                <p className="search-title">
                  {/* Search for Team Members */}
                  List of direct report
                </p>

                <div className="nav-tabs-outer"></div>
              </div>

              <div className="right-container-search">
                <div
                  className="search-filters d-flex align-items-baseline"
                  onClick={() => settingHandler()}
                  style={{
                    pointerEvents: isSettingDropDownOpen ? "none" : "auto",
                  }}
                >
                  <div className="filters-icon">
                    <IoFilter />
                  </div>
                  <div className="filters-title">Filters</div>
                  <div className="filters-count">
                    {(() => {
                      let count = 0;
                      for (let key in selectedOptions) {
                        console.log("key", key);
                        count = count + selectedOptions[key].length;
                      }
                      if (!isEmptyNullUndefined(count) && !(count == 0)) {
                        return `+${count}`;
                      }
                    })()}
                  </div>
                </div>
                {isSettingDropDownOpen ? (
                  <div ref={dropdownRef}>
                    <div>
                      <Filters
                        handleCloseFilter={() => handleCloseFilter()}
                        handleFilteredData={(filteredData) =>
                          handleFilteredData(filteredData)
                        }
                        parentSelectedOptions={selectedOptions}
                        handleUpdateParentfilterDataList={(filterDataList) =>
                          handleUpdatefilterDataList(filterDataList)
                        }
                        handleSetInitialSelectedOptions={(
                          initialSelectedOption
                        ) =>
                          handleSetInitialSelectedOptions(initialSelectedOption)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            {
              (() => {
                let tempSelectedOptions = JSON.parse(
                  JSON.stringify(selectedOptions)
                );
                let onlyDataSelectedOptions =
                  removeEmptyArrays(tempSelectedOptions);
                let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);

                if (selectedOptionsArray.length > 0) {
                  return (
                    <div className="d-flex justify-content-between my-3">
                      <div className="d-flex gap-4 overflow-auto w-75 pb-1">
                        {renderFilterCards()}
                      </div>
                      <div
                        onClick={() => handleResetFilter()}
                        className="reset-filters"
                      >
                        Reset Filters
                      </div>
                    </div>
                  );
                }
              })()
            }
          </div>
          {!isEmptyNullUndefined(myTeamData) && (
  <TableContainer
    style={{ borderRadius: "0px 0px 12px 12px" }}
    component={Paper}
  >
    <Table>
      <TableHead>
        <TableRow style={{ background: "#F9FAFB", position: "sticky", top: 0, zIndex: 1 }}>
          <TableCell
            className="table-header-style table-name-sticky-coll-header"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Name
            {handleSortDomCreateion("employeeName")}
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Email Id
            {handleSortDomCreateion("employeeEmail")}
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Approved
            {handleSortDomCreateion("criticality")}
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Pending approval
            {handleSortDomCreateion("status")}
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Feedback received
            {handleSortDomCreateion("status")}
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
            Actions
          </TableCell>
          <TableCell
            className="table-header-style"
            style={{
              textAlign: "center",
              padding: "10px 16px",
              fontWeight: "bold",
              backgroundColor: "#F9FAFB",
            }}
          >
         
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {myTeamLoader ? (
          Array.from({ length: 4 }).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from({ length: 6 }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          myTeamData?.data?.map((row, index) => (
            <TableRow key={index}>
              <TableCell className="table-name-sticky-coll-body">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {row?.requestedTo?.profilePhoto ? (
                    <img
                      src={row.requestedTo.profilePhoto}
                      width="40px"
                      height="40px"
                      className="userImage"
                      alt="User Profile"
                    />
                  ) : (
                    <Avatar src={row?.name} alt={row?.name} />
                  )}
                  <div style={{ marginLeft: "10px" }}>
                    <div className="table-employee-name">{row.name}</div>
                    <div className="table-employee-designation">{row.employeeDesignation}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>{row.email}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row?.approvedRequestCount}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row?.pendingForApprovalRequestCount}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row?.feedbackRecievedRequestCount}</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={()=>viewNomination(row?.id)}>
                  View nominations
                </button>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <button style={{ background: "none", border: "none", cursor: "pointer" }}>
                  Download
                </button>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </TableContainer>
)}


          {isEmptyNullUndefined(myTeamData?.data) && (
            <Stack
              width={"fullWidth"}
              justifyContent={"center"}
              display={"flex"}
              textAlign={"center"}
            >
              No data found...!
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApproveNominations;
