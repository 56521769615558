import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Goals from "../../../../assets/images/goals.png";
import pdfDownload from "../../../../assets/images/pdfDownload.svg";
import deleteActivity from "../../../../assets/images/deleteActivity.svg";
import closeIcon from "../../../../assets/images/cross.svg";
import { LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import { GoChevronDown } from "react-icons/go";

const GrowthDevelopmentGoals = ({
  AddInputGoals,
  setGoals,
  editedKey,
  prevValue,
}) => {
  const [promisedata, setPromisedata] = useState([]);

  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });

  const [competencyValues, setCompetencyValues] = useState([
    { id: 1, name: "Coding" },
    { id: 2, name: "Design" },
    { id: 3, name: "Understanding of Systems" },
  ]);

  const initialData = {
    selectedCompetencies: [1, 2], // IDs of competencies selected initially
  };

  useEffect(() => {
    // Set the default competency based on initial data (this could come from props or API)
    if (initialData.selectedCompetencies.length > 0) {
      setCompetency(initialData.selectedCompetencies[0]); // Set the first competency as default
    }
  }, []);

  const [dummyData, setDummyData] = useState([
    { id: 10, label: "Aspiration 1" },
    { id: 20, label: "Aspiration 2" },
    { id: 30, label: "Aspiration 3" },
  ]);

  const [goalInput, setGoalInput] = useState(prevValue?.goalName); // To hold the input of the goal name
  const [taskName, setTaskName] = useState(prevValue.taskName);
  const [courseName, setCourseName] = useState(prevValue.courseName);
  const [courseLink, setCourseLink] = useState(prevValue.courseLink);
  const [courseDetails, setCourseDetails] = useState(prevValue.courseDetails);
  const [courseFeeCurrency, setCourseFeeCurrency] = useState(
    prevValue.courseFeeCurrency
  );
  const [courseFee, setCourseFee] = useState(prevValue.courseFee);
  const [mentorshipTitle, setMentorshipTitle] = useState(
    prevValue.mentorshipTitle
  );
  const [employeeID, setEmployeeID] = useState(
    prevValue.mentorDetails.employeeID
  );
  const [name, setName] = useState(prevValue.mentorDetails.name);
  const [designation, setDesignation] = useState(
    prevValue.mentorDetails.designation
  );
  const [department, setDepartment] = useState(
    prevValue.mentorDetails.department
  );
  const [markAsComplete, setMarkAsComplete] = useState(
    prevValue.markAsComplete
  );
  const [taskType, setTaskType] = useState(prevValue.taskType);

  // useEffect(() => {
  //   if (editedKey === "edit" && AddInputGoals?.length > 0) {
  //     const goalToEdit = prevValue[0]; // Assuming you are editing the first item for now
  //     if (goalToEdit) {
  //       setGoalInput(goalToEdit.goalName);
  //       setMeasureOfSuccess(goalToEdit.measureOfSuccess);
  //     }
  //   } else {
  //     setGoalInput("");
  //     setMeasureOfSuccess("");
  //     setIsEditing(false);
  //   }
  // }, [editedKey, prevValue]);

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  const [showTaskTypeDiv, setShowTaskTypeDiv] = useState({
    task: true,
    course: false,
    mentorship: false,
  });

  const handleTaskTypeRadioChange = (event) => {
    const { value } = event.target;
    setShowTaskTypeDiv({
      task: value === "task",
      course: value === "course",
      mentorship: value === "mentorship",
    });
  };

  const [showYesNoDiv, setShowYesNoDiv] = useState({
    yes: true,
    no: false,
  });

  const handleYesNoRadioChange = (event) => {
    const { value } = event.target;
    setShowYesNoDiv({
      yes: value === "yes",
      no: value === "no",
    });
  };

  const [competency, setCompetency] = useState("select");

  const handleCompetencyChange = (event) => {
    setCompetency(event.target.value);
  };

  const [aspiration, setAspiration] = useState("select");

  const handleAspirationChange = (event) => {
    setAspiration(event.target.value);
  };

  const handleCancelClick = () => {
    setGoals([]); // Reset the goal input to an empty array
  };
  return (
    <Grid container className="output-goals-card">
      {/*----- 2. Growth / Development Goals -----*/}
      {AddInputGoals?.[0] === 2 && (
        <>
          <Grid item xs={12} md={12} sm={12} className="mt-15">
            <Typography className="output-goals">
              {"2. Growth / Development Goals"}
            </Typography>
          </Grid>

          <Grid item xs={1} md={1} sm={1}>
            <Grid className="mt-15">
              <img src={Goals} alt="Goals" height={10} width={10} />
              <Grid className="vertical-border" />
            </Grid>
          </Grid>

          {/*----- Add a Growth / Development Goals -----*/}
          <Grid item xs={11} md={11} sm={11}>
            <Typography className="add-output-goals mt-15">
              {editedKey == "edit"
                ? "Edit a Growth / Development Goals"
                : "Add a Growth / Development Goals"}
            </Typography>

            {/*----- Goal Name -----*/}
            <Typography className="field-label mt-15">{"Goal Name"}</Typography>
            <TextField
              name="goalName"
              variant="outlined"
              size="small"
              fullWidth
              value={goalInput}
              onChange={(e) => setGoalInput(e.target.value)}
            />

            {/*----- Mark as Complete Checkbox -----*/}
            <FormGroup className="ptb-15">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={markAsComplete}
                    onChange={(e) => setMarkAsComplete(e.target.checked)}
                  />
                }
                label="Mark as Complete"
              />
            </FormGroup>

            {/*----- Activities  -----*/}
            <div className="rating b-top b-bottom gap-8">
              <div>
                {(() => {
                  if (expanded.panel2 == true) {
                    return (
                      <LuMinusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel2");
                        }}
                      />
                    );
                  } else {
                    return (
                      <LuPlusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel2");
                        }}
                      />
                    );
                  }
                })()}
              </div>
              <div className="ratingInner b-bottom-none">
                <Accordion
                  sx={{
                    boxShadow: "none !important",
                    "& .MuiAccordionSummary-root": {
                      paddingLeft: 0,
                      boxShadow: "none !important",

                      "& .MuiAccordionSummary-content": {
                        marginTop: 0,
                        paddingTop: 0,
                        alignSelf: "flex-start",
                      },
                    },
                  }}
                  expanded={expanded.panel2}
                  onChange={handleChangeAccordian("panel2")}
                >
                  <AccordionSummary
                    // sx={{ pointerEvents: "none" }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="asas ratingTitle">
                      <div className="sdsd">
                        <p className="comment-title">
                          <span> Activities</span>
                        </p>
                        <div className="d-flex w-100 align-items-center">
                          <p className="created mb-0">0 Live, 0 Completed</p>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {/*----- Activity Button -----*/}
                      <Grid item xs={12} md={12} sm={12}>
                        <div className="activity-btn-div">
                          <div className="btnInline">
                            <Button className="openBtn">Activity 1</Button>
                            <Button className="completedBtn">
                              + Add more activities{" "}
                            </Button>
                          </div>
                        </div>
                      </Grid>

                      {/*----- Task Type -----*/}
                      <Grid item xs={12} md={12} sm={12}>
                        <Typography className="field-label mt-15">
                          {"Task Type"}
                        </Typography>

                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="task"
                            onChange={handleTaskTypeRadioChange}
                          >
                            <FormControlLabel
                              value="task"
                              control={<Radio />}
                              label="Task"
                            />
                            <FormControlLabel
                              value="course"
                              control={<Radio />}
                              label="Course"
                            />
                            <FormControlLabel
                              value="mentorship"
                              control={<Radio />}
                              label="Mentorship"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {showTaskTypeDiv.task && (
                        <div className="w-100">
                          {/*----- Task Name -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Task Name"}
                            </Typography>
                            <TextField
                              name="taskName"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={taskName}
                              onChange={(e) => setTaskName(e.target.value)}
                            />
                          </Grid>
                        </div>
                      )}

                      {showTaskTypeDiv.course && (
                        <Grid container spacing={2} className="w-100">
                          {/*----- Course Name -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Course Name"}
                            </Typography>
                            <TextField
                              name="courseName"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={courseName}
                              onChange={(e) => setCourseName(e.target.value)}
                            />
                          </Grid>

                          {/*----- Course Link -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Course Link"}
                            </Typography>
                            <TextField
                              name="courseLink"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={courseLink}
                              onChange={(e) => setCourseLink(e.target.value)}
                            />
                          </Grid>

                          {/*----- Course Details -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Course Details"}
                            </Typography>
                            <TextField
                              name="courseDetails"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={3}
                              value={courseDetails}
                              onChange={(e) => setCourseDetails(e.target.value)}
                            />
                            <Typography className="field-label">
                              {"(256 characters Left)"}
                            </Typography>
                          </Grid>

                          {/*----- Learning Through Assistance Program -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Learning Through Assistance Program"}
                            </Typography>

                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="yes"
                                onChange={handleYesNoRadioChange}
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {showYesNoDiv.yes && (
                            <>
                              {/*----- Course Fee Currency -----*/}
                              <Grid item xs={12} md={6} sm={6}>
                                <Typography className="field-label mt-2">
                                  {"Course Fee Currency"}
                                </Typography>
                                <TextField
                                  name="courseFeeCurrency"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={courseFeeCurrency}
                                  onChange={(e) =>
                                    setCourseFeeCurrency(e.target.value)
                                  }
                                />
                              </Grid>

                              {/*----- Course Fee -----*/}
                              <Grid item xs={12} md={6} sm={6}>
                                <Typography className="field-label mt-2">
                                  {"Course Fee"}
                                </Typography>
                                <TextField
                                  name="courseFee"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={courseFee}
                                  onChange={(e) => setCourseFee(e.target.value)}
                                />
                              </Grid>
                            </>
                          )}

                          {showYesNoDiv.no && <div></div>}
                        </Grid>
                      )}

                      {showTaskTypeDiv.mentorship && (
                        <Grid container spacing={2} className="w-100">
                          {/*----- Mentorship Title -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Mentorship Title"}
                            </Typography>
                            <TextField
                              name="mentorshipTitle"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={mentorshipTitle}
                              onChange={(e) =>
                                setMentorshipTitle(e.target.value)
                              }
                            />
                          </Grid>

                          {/*----- Mentor / Supervisor Details -----*/}
                          <Grid item xs={12} md={12} sm={12}>
                            <Typography className="field-label mt-15">
                              {"Mentor / Supervisor Details"}
                            </Typography>
                          </Grid>

                          {/*----- Employee ID -----*/}
                          <Grid item xs={12} md={3} sm={3}>
                            <Typography className="field-label mt-2">
                              {"Employee ID"}
                            </Typography>
                            <TextField
                              name="employeeID"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={employeeID}
                              onChange={(e) => setEmployeeID(e.target.value)}
                            />
                          </Grid>

                          {/*----- Name -----*/}
                          <Grid item xs={12} md={3} sm={3}>
                            <Typography className="field-label mt-2">
                              {"Name"}
                            </Typography>
                            <TextField
                              name="name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Grid>

                          {/*----- Designation -----*/}
                          <Grid item xs={12} md={3} sm={3}>
                            <Typography className="field-label mt-2">
                              {"Designation"}
                            </Typography>
                            <TextField
                              name="designation"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={designation}
                              onChange={(e) => setDesignation(e.target.value)}
                            />
                          </Grid>

                          {/*----- Department -----*/}
                          <Grid item xs={12} md={3} sm={3}>
                            <Typography className="field-label mt-2">
                              {"Department"}
                            </Typography>
                            <TextField
                              name="department"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={department}
                              onChange={(e) => setDepartment(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/*----- Mark as Complete Checkbox -----*/}
                      <Grid item xs={12} md={12} sm={12}>
                        <FormGroup className="mt-15">
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Mark as Complete"
                          />
                        </FormGroup>
                      </Grid>

                      {/*----- Delete this activity -----*/}
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        sm={6}
                        className="mt-15"
                      >
                        <img src={deleteActivity} />
                        <Typography className="delete-activity">
                          {"Delete this activity"}
                        </Typography>
                      </Grid>

                      {/*----- Cancel & Save Task Btn -----*/}
                      <Grid item xs={12} md={6} sm={6} className="mt-15 ta-end">
                        <Button className="cancel-btn">{"Cancel"}</Button>
                        <Button className="save-goal-btn">{"Save Task"}</Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            {/*----- Link to Competency  -----*/}
            <div className="rating b-bottom gap-8">
              <div>
                {(() => {
                  if (expanded.panel1 == true) {
                    return (
                      <LuMinusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel1");
                        }}
                      />
                    );
                  } else {
                    return (
                      <LuPlusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel1");
                        }}
                      />
                    );
                  }
                })()}
              </div>
              <div className="ratingInner b-bottom-none">
                <Accordion
                  sx={{
                    boxShadow: "none !important",
                    "& .MuiAccordionSummary-root": {
                      paddingLeft: 0,
                      boxShadow: "none !important",

                      "& .MuiAccordionSummary-content": {
                        marginTop: 0,
                        paddingTop: 0,
                        alignSelf: "flex-start",
                      },
                    },
                  }}
                  expanded={expanded.panel1}
                  onChange={handleChangeAccordian("panel1")}
                >
                  <AccordionSummary
                    sx={{ pointerEvents: "none" }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="asas ratingTitle">
                      <div className="sdsd">
                        <p className="comment-title">
                          <span>Link to Competency</span>
                        </p>
                        <div className="d-flex w-100 align-items-center">
                          <p className="created mb-0">0 Live, 0 Completed</p>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/*----- Competency -----*/}
                    <Grid container>
                      <Grid item xs={12} md={8} sm={8}>
                        <Typography className="field-label">
                          {"Competency"}
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={competency}
                            size="small"
                            defaultValue="Select"
                            onChange={handleCompetencyChange}
                            IconComponent={GoChevronDown}
                          >
                            <MenuItem value="Select">Select</MenuItem>
                            {competencyValues.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Display selected value below */}
                        <div className="select-competency-value">
                          {competencyValues
                            .filter((item) =>
                              initialData.selectedCompetencies.includes(item.id)
                            )
                            .map((item) => (
                              <div
                                className="select-competency-border"
                                key={item.id}
                              >
                                <Typography className="select-value">
                                  {item.name}
                                </Typography>
                                <img
                                  src={closeIcon}
                                  alt="remove"
                                  className="c-pointer"
                                  onClick={() => {
                                    // Logic to remove competency from selected competencies
                                    setCompetencyValues((prev) =>
                                      prev.filter((comp) => comp.id !== item.id)
                                    );
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            {/*----- Link to Aspiration  -----*/}
            <div className="rating b-bottom gap-8">
              <div>
                {(() => {
                  if (expanded.panel3 == true) {
                    return (
                      <LuMinusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel3");
                        }}
                      />
                    );
                  } else {
                    return (
                      <LuPlusCircle
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          color: "#7F56D9",
                          height: "25px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePanel("panel3");
                        }}
                      />
                    );
                  }
                })()}
              </div>
              <div className="ratingInner b-bottom-none">
                <Accordion
                  sx={{
                    boxShadow: "none !important",
                    "& .MuiAccordionSummary-root": {
                      paddingLeft: 0,
                      boxShadow: "none !important",

                      "& .MuiAccordionSummary-content": {
                        marginTop: 0,
                        paddingTop: 0,
                        alignSelf: "flex-start",
                      },
                    },
                  }}
                  expanded={expanded.panel3}
                  onChange={handleChangeAccordian("panel3")}
                >
                  <AccordionSummary
                    sx={{ pointerEvents: "none" }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="asas ratingTitle">
                      <div className="sdsd">
                        <p className="comment-title">
                          <span> Link to Aspiration</span>
                        </p>
                        <div className="d-flex w-100 align-items-center">
                          <p className="created mb-0">0 Live, 0 Completed</p>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/*----- Aspiration -----*/}
                    <Grid container>
                      <Grid item xs={12} md={8} sm={8}>
                        <Typography className="field-label">
                          {"Aspiration"}
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={aspiration}
                            size="small"
                            defaultValue="Select"
                            onChange={handleAspirationChange}
                            IconComponent={GoChevronDown}
                          >
                            {dummyData.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Display selected value below */}
                        <div className="select-competency-value">
                          {aspiration && (
                            <div className="select-competency-border">
                              <Typography className="select-value">
                                {dummyData.find(
                                  (item) => item.id === aspiration
                                )?.label || "Selected Aspiration"}
                              </Typography>
                              <img
                                src={closeIcon}
                                className="c-pointer"
                                onClick={() => setAspiration("")} // Clear selection on click
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <Grid item xs={12} md={6} sm={6} className="mt-15 ta-end">
              <Button className="cancel-btn" onClick={handleCancelClick}>
                {"Cancel"}
              </Button>
              <Button className="save-goal-btn">{"Save Task"}</Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(GrowthDevelopmentGoals);
