import { type } from "@testing-library/user-event/dist/type";
import api, { axiosInstanceWorkFlow } from "./apiServices";
import { axiosInstanceNotification } from "./apiServices";
const workFlowApi = "http://dev-portal.benevolve.com/services/workflow/api"


const authenticateUser = (payload) => api.post(`/authenticate`, payload);
const logOutUser = (payload) => api.post(`/logout?token=${payload.jwtToken}`);

const getEmployeeData = () => api.get(`/employees/email`);
const getPerformanceData = ({ payload }) => {
  return api.get(`/new-employees/performance-history/${payload.employedId}`)
};
const getProfileinfoData = ({ payload }) => {
  return api.get(`/new-employees/employee-info/${payload.employedId}/${payload.companyId}`)
};
const getProfileData = ({ payload }) => {
  return api.get(`/new-employees/myTeam/${payload.employedId}/${payload.companyId}`)
};

const postProfileData = ({ payload }) => {
  return api.post(`/new-employees/myTeam/filter?page=0&size=10}`, payload)
};
const searchMyTeam = ({ payload }) => {
  return api.post(`/new-employees/employee-search`, payload)
};
// Getprofileinfromatin data
const getMyTeam = (payload) => api.post(`/new-employees/myTeam/filter?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getAssessmentResponseFeedbackRequestSentRequest = (payload) => api.post(`/assessment-response/feedback-request/sent-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
// assessment-response/feedback-request/sent-request?page=0&size=10&sortBy=requestDate&sortOption=asc
const getSortedAssessmentResponseFeedbackRequestSentRequest = (payload) => api.post(`/assessment-response/feedback-request/sent-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOption=${payload?.sortDir}`, payload?.payload);

const getcompetencyFeedback = ({employeeId, roleId}) => api.get(`/new-employees/performance-history/competency-report?employeeId=${employeeId}&roleId=${roleId}`)

const get_new_employees_filters = (payload) => api.get(`new-employees/filters/${payload}`)

const getTalentNotes = ({ payload }) => {
  return api.get(`/talent-note?managerId=${payload?.managerId}&annualYear=${payload?.annualYear}`)
};

const uploadTalentData = (payload, companyId) => {
  return api.post(`/newcsventity/upload/TalentNote/${companyId}/create?employeeId=EMP_ID&movingTheFunctionForward=Moving The Function Forward&comment=Comment&tribalKnowledge=Tribal Knowledge&potential=Potential&criticality=Crticality&annualYear=Annual Year`,
    payload,
  )
};

const downloadTalentData = ({ payload }) => {
  return api.post(`/talent-note/download?managerId=${payload?.managerId}&annualYear=${payload?.annualYear}`, payload)
};

const getSortedEmpList = (payload) => api.post(`/new-employees/myTeam/filter?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortDir=${payload?.sortDir}`, payload?.payload);

const getDashboardData = (payload) => axiosInstanceNotification.post(`/dashboard`, payload); 

// migration
const getImmediateTeam = (payload) => api.post(`/employees/immediate-team?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
const getSentRequestCount = (payload) => api.get(`/assessment-response/feedback-requests/counts/submitted-by/${payload?.id}`);
const getInfoDataByPageNameAndCompanyId = (companyId,pageName) => api.get(`/infoContents/${companyId}/${pageName}`);
const getAllSentRequest = (payload) => api.post(`/assessment-response/feedback-request/sent-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOrder=${payload?.sortOrder}`, payload?.payload);
const getFeedbackRequestSort = (payload) => api.post(`/assessment-response/feedback-request/recieved-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOrder=${payload?.sortOrder}`, payload?.payload);
const getFeedbackRequests = (payload) => api.post(`/assessment-response/feedback-request/recieved-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
const getReceivedRequestCount = (payload) => api.get(`/assessment-response/feedback-requests/counts/recieved-by/${payload?.id}`);
const getFilteredSearchPeersfeed = (payload) => api.post(`/employees/peers-search-feedback-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
const sendFeedbackRequest = (payload) => api.post(`/assessment-response/feedback-request`, payload);
const getAllActiveannualReviewCycle = (companyId) => api.get(`/annualReviewCycle/${companyId}/ACTIVE`);
const isCloseLoopOnForManFeedback = (payload) => api.get(`/pms-closedloop/check-manager-review-active/company/${payload?.companyId}`);

// {{host}}/api/three-sixty-cycle/assessment-forms-for-360-feedback/by-companyId/{companyId}
const getThreeSixtyPeerFeedback = (companyId) => api.get(`/three-sixty-cycle/assessment-forms-for-360-feedback/by-companyId/${companyId}`)
const getFeedbackRequstById = (payload) => api.get(`/assessment-response/feedback-request/ID/${payload}`);
const getEmployeeById = (payload) => api.get(`/employees/${payload?.id}`);
const getPeerfeedbackByRequest = (payload) => api.get(`/assessment-response-new/get-feedback-by-request/${payload}`);
const getAssessment = (payload) => api.get(`/assessment-new/by-formfor-and-company/${payload.selectedFormFor}/${payload.selectedCompanyId}?status=ACTIVE`);
const putAssessmentResponse = (payload) => api.put(`/assessment-response-new/${payload.responseFormId}`, payload.data);
const postAssessmentResponse = (payload) => api.post(`/assessment-response-new`, payload);
const workFlowDefinitionEmployeeBy = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/employee`, payload);
const addAStakeholder = (payload)=> axiosInstanceWorkFlow.post(`/work/flow/definition/approve-nomination`,payload)
const editStakeholder = (payload)=> axiosInstanceWorkFlow.post(`/work/flow/definition/verified/FEEDBACK`,payload)

const getCompentencyEntityDropDown = ({employeeId, companyId}) => api.get(`/competency-entity/dropDown/${employeeId}/${companyId}`);

///
const getAssessmentResponseFeedbackRequestDirectReportsRequest = (payload) => api.post(`/assessment-response/feedback-request/direct-reports-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getSortedAssessmentResponseFeedbackRequestDirectReportsRequest = (payload) => api.post(`/assessment-response/feedback-request/direct-reports-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOption=${payload?.sortDir}`, payload?.payload);

const getApproveNomination = (payload) => api.post(`/assessment-response/direct-reports-request-data?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const sendFeedbackRequestMultiple = (payload) => api.post(`/assessment-response/feedback-request/multiple`, payload);

const AssessmentResponseDeleteFeedbackRequest = (id) => api.delete(`/assessment-response/delete-feedback-request/${id}`);

const getViewNomination = (payload) => api.post(`/assessment-response/feedback-request/sent-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getDownloadApproveNomination = (payload) => api.post(`/feedback-report/export`,payload?.payload);
const declineFeedbackRequests = (payload) => api.put(`/assessment-response/feedback-request/decline/${payload?.requestId}`);

const APIList = {
  authenticateUser,
  logOutUser,
  getEmployeeData,
  getMyTeam,
  getPerformanceData,
  getProfileinfoData,
  getProfileData,
  postProfileData,
  searchMyTeam,
  get_new_employees_filters,
  getTalentNotes,
  uploadTalentData,
  downloadTalentData,
  getSortedEmpList,
  getcompetencyFeedback,
  getDashboardData,
  getImmediateTeam,
  getSentRequestCount,
  getInfoDataByPageNameAndCompanyId,
  getAllSentRequest,
  getFeedbackRequests,
  getReceivedRequestCount,
  getFilteredSearchPeersfeed,
  sendFeedbackRequest,
  getAllActiveannualReviewCycle,
  isCloseLoopOnForManFeedback,
  getThreeSixtyPeerFeedback,
  getFeedbackRequstById,
  getEmployeeById,
  getPeerfeedbackByRequest,
  getAssessment,
  putAssessmentResponse,
  postAssessmentResponse,
  workFlowDefinitionEmployeeBy,
  getCompentencyEntityDropDown,
  getAssessmentResponseFeedbackRequestSentRequest,
  getSortedAssessmentResponseFeedbackRequestSentRequest,
  getAssessmentResponseFeedbackRequestDirectReportsRequest,
  getSortedAssessmentResponseFeedbackRequestDirectReportsRequest,
  getApproveNomination,
  sendFeedbackRequestMultiple,
  AssessmentResponseDeleteFeedbackRequest,
  getViewNomination,
  getDownloadApproveNomination,
  getFeedbackRequestSort,
  declineFeedbackRequests,
  addAStakeholder,
  editStakeholder
};

export default APIList;
