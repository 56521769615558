import rightArrow from "../../../assets/images/rightArrow.svg"
import searchIcon from "../../../assets/images/searchIcon.svg"
import userImage from "../../../assets/images/user.png"
import hand from "../../../assets/images/hand.svg"
import mail from "../../../assets/images/mail.svg"
import phone from "../../../assets/images/phone.svg"
import plus from "../../../assets/images/plus.svg"
import filter from "../../../assets/images/filter.svg"
import minusClose from "../../../assets/images/minusClose.svg"
import plusOpen from "../../../assets/images/plusOpen.svg"
import pdfDownload from "../../../assets/images/pdfDownload.svg"
import selectDropDown from "../../../assets/images/selectDropdown.svg"
import movementJourney from "../../../assets/images/movementJourney.png"
import roundIcon from "../../../assets/images/roundIcon.svg"
import linkIcon from "../../../assets/images/link.svg"
import React, { useEffect, useRef, useState } from "react"
import APIList from "../../../api"
import { RiErrorWarningFill } from "react-icons/ri"
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import useEmployee from "./useEmployee"
import Pagination from '@mui/material/Pagination';
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Avatar from 'react-avatar';
import { Autocomplete, Button, CircularProgress, Menu, MenuItem, Skeleton, Stack, TextField } from "@mui/material"
import { CleanPlugin } from "webpack"
import { getObjById, isEmptyNullUndefined, removeEmptyArrays, returnNameByKey } from "../../utils/utils"
import { IoArrowDownOutline, IoFilter } from "react-icons/io5";
import Filters from "./Filters"
import { IoClose } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import { IoArrowUp } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";


const initialFilters = {
    // employeeGrade: [],
    // employeeLevel: [],
    // employeeSubfunction: [],
    // gender: [],
    // pod: [],
    // phonepeTenure: [],
    // timeSinceLastPromotion: [],
}

const TeamView = () => {
    const dropdownRef = useRef(null);
    // const { promisedata, profiledata } = useEmployee();
    const history = useHistory();
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    console.log("employeeDetails", employeeDetails);
    const dispatch = useDispatch();
    // const [activeTab, setActiveTab] = useState("Experience");
    const [activeTab2, setActiveTab2] = useState("Course");

    const [myTeamData, setMyTeamData] = useState([]);
    const [myTeamDataSearch, setMyTeamDataSearch] = useState([]);
    const [myTeamLoader, setMyTeamLoader] = useState(false);
    const [profileinfodata, setProfileinfodata] = useState("");
    const [searchvalue, setSearchValue] = useState("");

    const [activeTab, setActiveTab] = useState(null);


    const [currentPage, setCurrentPage] = useState(0);

    const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState(JSON.parse(JSON.stringify(initialFilters)));

    const [filterData, setFilterData] = useState(JSON.parse(JSON.stringify(initialFilters)))

    const [dataLoading, setDataLoading] = useState(false)

    const [searchLoading, setSearchLoading] = useState(false)

    const [isSubEmployeeLoading, setIsSubEmployeeLoading] = useState(false)
    const [subEmployeeLoadId, setSubEmployeeLoad] = useState(null)

    // const [sortType, setSortType] = useState("ascending") //ascending, descending

    const [sortType, setSortType] = useState({
        type: 'descending',
        sortBy: ''
    })

    console.log(profileinfodata, "profileinfodata");
    
    // useEffect(() => {
    //     getMyTeamData()
    // }, [page]);

    
    React.useEffect(() => {
        let path = localStorage.getItem("gotToPath");
        if(!isEmptyNullUndefined(path)){
          history.push(path);
          localStorage.removeItem("gotToPath");
        }
      }, [])

    useEffect(() => {
        if (searchvalue) {
            const countdownInterval = setTimeout(() => { searchMyTeamData(searchvalue) }, 2000)
            return () => clearTimeout(countdownInterval);
        }
    }, [searchvalue])

    useEffect(() => {
        // isHrbp: null
        // isManager:true
        if(!isEmptyNullUndefined(employeeDetails)) {

            if(employeeDetails.isManager == true) {
                setActiveTab("Member"); // there is no permission thats by set direct
    
            } else if(employeeDetails.isHrbp) {
                setActiveTab("Hrbp");
            } else {
                console.log('in use effect ismanage and hrbp both false')
            }
        }



        // if (employeeDetails?.permissions?.includes("team-view-Member-toggle")) {
        //   setActiveTab("Member");
        // } else if (
        //   !employeeDetails?.permissions?.includes("team-view-Member-toggle") &&
        //   employeeDetails?.permissions?.includes("team-view-hrbp-toggle")
        // ) {
        //   setActiveTab("Hrbp");
        // } else if (
        //   !employeeDetails?.permissions?.includes("team-view-Member-toggle") &&
        //   !employeeDetails?.permissions?.includes("team-view-hrbp-toggle")
        // ) {
        //   setActiveTab(null);
        // }
    }, [employeeDetails]);

    useEffect(() => {
        // setSearch(""); -not defined
        if (!isEmptyNullUndefined(activeTab)) {
    
          if (currentPage !== 0) {
            setCurrentPage(0);
          } else {
            // getPageData();
            getMyTeamData()
            setSelectedOptions(() => initialFilters)
          }
        }
    }, [activeTab]);

    useEffect(() => {
        let isFiltered = checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
        getMyTeamData(selectedOptions, isFiltered)
    }, [page]);

      const settingHandler = () => {
        if (!isSettingDropDownOpen) {
          setIsSettingDropDownOpen(true);
        }
      };
    
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsSettingDropDownOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    // const clickEmp = (empId, compId) => {

    //     APIList.getProfileinfoData({
    //         payload: {
    //             employeeEligibilityDTO: {},
    //             filterData: false,
    //             keyword: "",
    //             companyId: compId,
    //             employedId: empId,
    //         }
    //     }).then((res) =>
    //         // this is not success because after refresh state will reset
    //         history?.push({ pathname: `/employee-profile`, state: { profile: res?.data, empid: empId } })
    //     ).catch(err => console.log(err));
    // }
    const clickEmp = (empId, compId) => {
        history?.push(`/employee-profile/${empId}`)
    }

    const checkIsSubEmpOpen = (tempmyTeamData) => { // for sorting
        if(isEmptyNullUndefined(tempmyTeamData)) {
            return true
        }
        let isOpen = false
        tempmyTeamData?.data?.forEach((e,i) => {
            if(e?.isOpened == true) {
                isOpen = true
            } else if(e?.level) {
                isOpen = true
            }
        })
        return isOpen
    }

    const checkIsParentSubEmpOpen = (parentEmpId, parentIndex, tempmyTeamData) => {
        let isOpen = false
        tempmyTeamData?.data?.forEach((e,i) => {
            if(parentIndex != i) {
                if(e?.parentId == parentEmpId) {
                    if(e.isOpened == true) {
                        isOpen = true 
                    }
                    
                }
                
            }
        })

        return isOpen

    }

    const searchMyTeamData = (keyword) => {
        setSearchLoading(() => true)
        APIList.searchMyTeam({
            payload: {
                "keyword": keyword,
                "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
            }
        }).then((res) => {
            setMyTeamDataSearch(res?.data);
            setSearchLoading(() => false)
            if(res.data.data.length > 0) {
                
            } else {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{'no record found...!'}
                    </div>
                ); 
            }
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSearchLoading(() => false)
        })
    }
    const getMyTeamData = (filteredData, isFiltered) => {
        setMyTeamLoader(true);
        APIList.getMyTeam({
            payload: {
                employeeEligibilityDTO: (isFiltered == true) ? filteredData : {}, //{},
                filterData: (isFiltered == true) ? true : false, //false,
                keyword: "",
                managerId: employeeDetails?.id,
                companyId: employeeDetails?.company?.id,
                // "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
                // "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
                page: "My Team",
                hrbp: activeTab === 'Hrbp' ? true : false
            },
            page: page - 1,
            size: 10
        }).then((res) => {
            setMyTeamData(res?.data);
            setMyTeamLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            );
            setMyTeamLoader(false);
        })
    }

    const handleFilteredData = (selectedOptions) => {
        checkIsThereAnyFilter(selectedOptions) ? getMyTeamData(selectedOptions, true) : getMyTeamData() // true for isFiltered
        handleCloseFilter()

        setSelectedOptions(() => selectedOptions)
        console.log('selectedOptions', selectedOptions)

        //
        let tempfilterData = JSON.parse(JSON.stringify(filterData))
        let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
        let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
        let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
        // setting initialOpen 
        let tempOpen = {}
        selectedOptionsArray?.forEach((e, i) => {
            tempOpen[e] = null
        })
        setOpen(() => tempOpen)
    }

    const handleUpdatefilterDataList = (filterDataList) => {
        setFilterData(() => filterDataList)
    }


    const handleChangeSearch = (event, newInputValue) => {
        setSearchValue(newInputValue);
        // if (newInputValue === '') {
        //     getMyTeamData();
        // } else {
        //     searchMyTeamData(newInputValue);
        // }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchMyTeamData(searchvalue);
        }
    };
    const getEmployeesPlus = async (empId, compId, level, index) => {
        setSubEmployeeLoad(() =>empId)
        setIsSubEmployeeLoading(() => true)
        await APIList.getProfileData({
            payload: {
                "companyId": compId,
                "employedId": empId,
            },
        }).then((res) => {

            let arraySrc = [];
            if (res?.data?.length > 0) {
                res.data.map((val) => { arraySrc.push({ ...val, level: level + 1, parentId: empId }) })
            }
            let arrayDest = [...myTeamData.data];
            arrayDest[index - 1].isOpened = true;
            let finalArr = [
                ...arrayDest.slice(0, index),
                ...arraySrc,
                ...arrayDest.slice(index)
            ];
            console.log(arraySrc, arrayDest, finalArr, "kd")
            setMyTeamData({ data: finalArr, totalPages: myTeamData?.totalPages })

            setIsSubEmployeeLoading(() => false)

        }
        ).catch(err => {
            setIsSubEmployeeLoading(() => false)  
            console.log(err)
        } )
    }
    const getEmployeeMinus = async (empId,index) => {
        let arrayDest = [...myTeamData.data];
        arrayDest[index - 1].isOpened = false;
        if(empId&&arrayDest.length>0){
            arrayDest= arrayDest.filter((val)=>val.parentId!==empId)
        }
       

        setMyTeamData({ data: arrayDest, totalPages: myTeamData?.totalPages })


    }

    const handleCloseFilter = () => {
        setIsSettingDropDownOpen(() => false)
    }

    const handleResetFilter = () => {
        setSelectedOptions(() => initialFilters)
        if(page == 1) {
            getMyTeamData()
        } else {
            setPage(() => 1) // this will auto call getMyTeamData throw useEffect
        }
    }

    const handleDeleteFilterCart = (selectedOption, index, tempselectedOptions) => {
        
        tempselectedOptions[selectedOption].length = 0
        ///// check is filter empty
        let onlyDataSelectedOptions = removeEmptyArrays(JSON.parse(JSON.stringify(tempselectedOptions)));
        let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
        ////

        setSelectedOptions(() => tempselectedOptions)

        if(selectedOptionsArray.length == 0) {
            if(page == 1) {
                getMyTeamData()
            } else {
                setPage(() => 1)
            }
        } else {
            if(page == 1) {
                getMyTeamData(tempselectedOptions, true)
            } else {
                setPage(() => 1)
            }

        }


    }

    const handleSetInitialSelectedOptions = (initialSelectedOption) => {
        setSelectedOptions(() => initialSelectedOption)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    const [open, setOpen] = React.useState({})
    const handleClick = (event, name) => {

        console.log('handleClick name',name)
        let tempopen = JSON.parse(JSON.stringify(open))
        console.log('handleClick tempopen',tempopen)
        tempopen[name] = true
        // console.log('event.currentTarget', event.currentTarget)
        setAnchorEl(event.currentTarget);
        setOpen(() => tempopen)
    };
    const handleClose = (name) => {
        setAnchorEl(null);
        console.log('handle close name', name)

        let tempopen = JSON.parse(JSON.stringify(open))
        // console.log('handleClick tempopen',tempopen)
        tempopen[name] = false

        console.log('tempopen----', tempopen)
        setOpen(() => tempopen)
    };

    const renderFilterCards = () => {
        let tempfilterData = JSON.parse(JSON.stringify(filterData))
        let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
        let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
        let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);

        
        return selectedOptionsArray.map((selectedOption, index) => {
            
            console.log('tempfilterData--', tempfilterData)
            console.log('selectedOption--', selectedOption)
            return (
                <>
                
                <div 
                    key={index} 
                    className="d-flex filter-card"
                    // id={`${returnNameByKey(selectedOption)}-button`}
                    // aria-controls={open ? `${returnNameByKey(selectedOption)}-menu` : undefined}
                    // aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    // onClick={handleClick}
                    id="basic-button"
                    aria-controls={open[selectedOption] ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open[selectedOption] ? 'true' : undefined}
                    onClick={(event) => handleClick(event, selectedOption)}
                >
                    <div className="title">
                        {returnNameByKey(selectedOption)}:
                        {console.log(tempSelectedOptions[selectedOption][0])}
                        {(getObjById(tempSelectedOptions[selectedOption][0], tempfilterData[selectedOption])).name}
                    </div>
                    {
                        (tempSelectedOptions[selectedOption].length > 1) ? (

                            <div className="filter-count">
                                
                                {`+${tempSelectedOptions[selectedOption].length - 1}`}
                            </div>
                        ) : null
                    }
                    <div onClick={() => handleDeleteFilterCart(selectedOption, index, JSON.parse(JSON.stringify(selectedOptions)))} className="delete"><IoClose /></div>
                    
                </div>
                {/* <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    
                >jknjknjk</Button> */}
                <Menu
                    sx={{
                        '& .MuiPopover-paper': {
                        //   background: 'blue',
                          borderRadius: '8px',
                        },
                        '& .MuiMenuItem-root': {
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '20px',
                            textAlign: 'left',
                            pointerEvents: 'none'
                        }
                      }}
                    // id={`${returnNameByKey(selectedOption)}-menu`}
                    // anchorEl={anchorEl}
                    // open={open}
                    // onClose={handleClose}
                    // MenuListProps={{
                    // 'aria-labelledby': `${returnNameByKey(selectedOption)}-button`,
                    // }}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open[selectedOption]}
                    onClose={() => handleClose(selectedOption)}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {tempSelectedOptions[selectedOption].map((item, index) => (
                    <MenuItem 
                        key={index} 
                        onClick={() => handleClose(selectedOption)}
                        // sx={{
                        //     //styleName: Text sm/Medium;
                        //     fontFamily: 'Inter',
                        //     fontSize: '14px',
                        //     fontWeight: '500',
                        //     lineHeight: '20px',
                        //     textAlign: 'left',

                        // }}
                    >
                        {(getObjById(item, tempfilterData[selectedOption])).name}
                    </MenuItem>
                    ))}
                </Menu>
                </>

            )
    });
    };

    const checkIsThereAnyFilter = (selectedOptions) => {
        let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions))
        ///// check is filter empty
        let onlyDataSelectedOptions = removeEmptyArrays(JSON.parse(JSON.stringify(tempselectedOptions)));
        let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
        ////

        if(selectedOptionsArray.length == 0) {
           return false
        } else {

            return true
        }
    }
    
    const handleSort = (tempmyTeamData, soryBy) => { //myTeamData, 'employeeName'
        const getSortedList = (sortBy, sortDir, filteredData, isFiltered) => {
            setMyTeamLoader(true);
            // api/new-employees/myTeam/filter?page=0&size=10&sortBy=employeeGrade&sortDir=desc
            let data = null

            APIList.getSortedEmpList({
                payload: {
                    employeeEligibilityDTO: (isFiltered == true) ? filteredData : {}, //{},
                    filterData: (isFiltered == true) ? true : false, //false,
                    keyword: "",
                    managerId: employeeDetails?.id,
                    companyId: employeeDetails?.company?.id,
                    // "managerId": "4b6fa605-ba6c-41ed-8d35-6159cf78c6de",
                    // "companyId": "ba02f418-e44d-467c-9d5d-421a2d966460",
                    page: "My Team",
                    hrbp: activeTab === 'Hrbp' ? true : false
                },
                page: page - 1,
                size: 10,
                sortBy,
                sortDir,
            }).then((res) => {
                setMyTeamData(() => res.data)
                setMyTeamLoader(false);
            }
                
            ).catch(err => {
                setMyTeamLoader(false);
                console.log(err)
            })
        }
        let tempsortType = {...sortType}

        if(tempsortType.sortBy == soryBy) {
            if(tempsortType.type == 'descending') {
                checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getSortedList(soryBy, 'desc', selectedOptions, true) : getSortedList(soryBy, 'desc') //sortBy, sortDir  asc and desc
                tempsortType.type = 'ascending'

                tempsortType.sortBy = soryBy
                setSortType(() => tempsortType)
            } else {
                checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getMyTeamData(selectedOptions, true) : getMyTeamData()
                tempsortType.type = 'descending'

                tempsortType.sortBy = ''
                setSortType(() => tempsortType)
            }

            

        } else {
            checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getSortedList(soryBy, 'asc', selectedOptions, true) :  getSortedList(soryBy, 'asc') //sortBy, sortDir  asc and desc

            tempsortType.type = 'descending'

            tempsortType.sortBy = soryBy
            setSortType(() => tempsortType)

        }

    }

    // const handleSort = (tempmyTeamData, soryBy) => { //myTeamData, 'employeeName' second
    //     let tempsortType = {...sortType}

    //     if(tempsortType.sortBy == soryBy) {
    //         console.log('------ if ', tempsortType)

    //         let sortedData = tempmyTeamData.data.sort((a, b) => {
    //             if (a[soryBy] < b[soryBy]) {
    //                 if(tempsortType.type == 'ascending') {  //ascending, descending
    //                     return -1;
    //                 } else {
    //                     return 1;
    //                 }
    //             }
    //             if (a[soryBy] > b[soryBy]) {
    //                 if(tempsortType.type == 'ascending') {  //ascending, descending
    //                     return 1;
    //                 } else {
    //                     return -1;
    //                 }
    //             }
    //             return 0;
    //         });
    
    
    
    //         tempmyTeamData.data = sortedData
    //         setMyTeamData(() => tempmyTeamData)
    
    //         if(tempsortType.type == 'ascending') {
    //             tempsortType.type = 'descending'
    //             setSortType(() => tempsortType)
    //         } else {
    //             tempsortType.type = 'ascending'
    //             setSortType(() => tempsortType)
    //         }

    //     } else {
    //         console.log('------ else ')
    //         let sortedData = tempmyTeamData.data.sort((a, b) => {
    //             if (a[soryBy] < b[soryBy]) {
    //                 return -1
    //             }
    //             if (a[soryBy] > b[soryBy]) {
    //                 return 1
    //             }
    //             return 0;
    //         });
    
    
    
    //         tempmyTeamData.data = sortedData
    //         setMyTeamData(() => tempmyTeamData)

    //         tempsortType.type = 'descending'

    //         tempsortType.sortBy = soryBy
    //         setSortType(() => tempsortType)

    //     }

        

    //     console.log('sorted tempmyTeamData', tempmyTeamData)



    // }

    // const handleSort = (tempmyTeamData, soryBy) => { //myTeamData, 'employeeName' first
    //     // let sortedData = tempmyTeamData.data.sort((a,b) => b[soryBy] - a[soryBy])
    //     let sortedData = tempmyTeamData.data.sort((a, b) => {
    //         if (a[soryBy] < b[soryBy]) {
    //             if(sortType == 'ascending') {  //ascending, descending
    //                 return -1;
    //             } else {
    //                 return 1;
    //             }
    //         }
    //         if (a[soryBy] > b[soryBy]) {
    //             if(sortType == 'ascending') {  //ascending, descending
    //                 return 1;
    //             } else {
    //                 return -1;
    //             }
    //         }
    //         return 0;
    //     });



    //     tempmyTeamData.data = sortedData
    //     setMyTeamData(() => tempmyTeamData)

    //     if(sortType == 'ascending') {
    //         setSortType(() => 'descending')
    //     } else {
    //         setSortType(() => 'ascending')
    //     }

    //     console.log('sorted tempmyTeamData', tempmyTeamData)



    // }

    const handleSortDomCreateion = (domSortBy) => {
        if(sortType.sortBy == domSortBy) {
            if(sortType.type == 'descending') {
                return (
                    <IoArrowUp

                        style={{cursor: 'pointer', pointerEvents: myTeamLoader ? 'none': 'auto', opacity: myTeamLoader ? '30%' : '100%'}} 
                        onClick={() => {handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy)}} 
                    />

                )
            } else {
                return (
                    <IoArrowDownOutline
                        style={{cursor: 'pointer', pointerEvents: myTeamLoader ? 'none': 'auto', opacity: myTeamLoader ? '30%' : '100%'}} 
                        onClick={() => {handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy)}} 
                    />
                )
            }
        } else {
            return (
                    <TbArrowsSort
                        style={{cursor: 'pointer', pointerEvents: myTeamLoader ? 'none': 'auto', opacity: myTeamLoader ? '30%' : '100%'}} 
                        onClick={() => {handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy)}} 
                    />
                )
        }
    }


    return (
        <div className="emp-profile-main">
            <p className="header-title">Team View</p>
            <div className="breadcumb">
                {/* showint route (team view >) */}
                {/* <p 
                    // onClick={() => history.push(`/team-view`)} 
                    onClick={() => window.location.reload()} 
                    
                    className="mainPage"
                    // history.push({pathname:`/no-feedback/${emp?.employeeId}`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}});
                >Team View</p>
                <img src={rightArrow} alt="Right Arrow" /> */}
                    

                {/* <div className="nav-tabs-outer">
                    <div className="tabs">
                        {
                        // employeeDetails?.permissions?.includes(
                        //     "team-view-Member-toggle"
                        // ) && 
                            (
                            // <div
                            // className={`tab ${activeTab === "Member" ? "active" : ""}`}
                            // onClick={() => setActiveTab("Member")}
                            // >
                            // Member
                            // </div>
                            <p 
                                className={`p ${activeTab === "Member" ? "active" : "inactive"}`}
                                onClick={() => setActiveTab("Member")}
                            >Manager</p>
                        )}
                        {
                        // employeeDetails?.permissions?.includes(
                        //     "team-view-hrbp-toggle"
                        // ) && 
                            (
                            // <div
                            // className={`tab ${activeTab === "Hrbp" ? "active" : ""}`}
                            // onClick={() => setActiveTab("Hrbp")}
                            // >
                            // Hrbp
                            // </div>
                            <p 
                                className={`p ${activeTab === "Hrbp" ? "active" : "inactive"}`}
                                onClick={() => setActiveTab("Hrbp")}
                            >HRBP</p>
                        )}
                        
                    </div>

                </div> */}


                {/* <p className="subPage">Member</p> */}
            </div>
            <div className="profile">
   
                <div className="searchBar">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="d-flex g-3 align-items-center">
                        
                            <p className="search-title">
                                {/* Search for Team Members */}
                                Team members
                            </p>

                            <div className="nav-tabs-outer">
                            <div className="tabs">
                                {employeeDetails.isManager &&
                                // employeeDetails?.permissions?.includes(
                                //     "team-view-Member-toggle"
                                // ) && 
                                    (
                                    // <div
                                    // className={`tab ${activeTab === "Member" ? "active" : ""}`}
                                    // onClick={() => setActiveTab("Member")}
                                    // >
                                    // Member
                                    // </div>
                                    <p 
                                        className={`p ${activeTab === "Member" ? "active" : "inactive"}`}
                                        onClick={() => setActiveTab("Member")}
                                    >Manager</p>
                                )}
                                {
                                employeeDetails.isHrbp &&
                                // employeeDetails?.permissions?.includes(
                                //     "team-view-hrbp-toggle"
                                // ) && 
                                    (
                                    // <div
                                    // className={`tab ${activeTab === "Hrbp" ? "active" : ""}`}
                                    // onClick={() => setActiveTab("Hrbp")}
                                    // >
                                    // Hrbp
                                    // </div>
                                    <p 
                                        className={`subPage ${activeTab === "Hrbp" ? "active" : "inactive"}`}
                                        onClick={() => setActiveTab("Hrbp")}
                                    >HRBP</p>
                                )}
                                
                            </div>
                        

                        </div>
                    </div>

                        <div className="right-container-search">
                            <Autocomplete
                                id="searchemp"
                                disableClearable
                                options={myTeamDataSearch?.data || []}
                                fullWidth
                                freeSolo
                                disableCloseOnSelect
                                onInputChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                                getOptionLabel={(option) => option?.employeeName || ""}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        {...props}
                                        style={{
                                            display: "flex",
                                            padding: 10,
                                            borderBottom: "0.6px solid #F3F3F3",
                                            // width: "100%",
                                            width: '320px !important'
                                        }}
                                        onClick={() => clickEmp(option?.employeeId, option?.company)}
                                    >
                                        <div className={"searchOptions"} style={{ display: "flex" }}>
                                            <div><Avatar name={option?.employeeName || 'Unknown User'} size="40" round={true} /></div>
                                            <div style={{ margin: "0rem 1.2rem" }}>{option?.employeeName}
                                                <br />{option?.employeeLevel}</div>
                                        </div>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Search using Name"
                                        fullWidth
                                        variant="outlined"
                                        // style={{ width: "50%", margin: "0", padding: "0", height: "2.2rem", float: "right" }}
                                        style={{ width: "100%", margin: "0", padding: "0", height: "2.2rem", float: "right" }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { height: "2.2rem", padding: "0 8px", lineHeight: "25px" }, // Adjust internal padding and height
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {searchLoading ? <div className="d-flex"><CircularProgress style={{width:'20px', height: '20px'}}  /></div> : <img src={searchIcon} className="searchIcon" alt="Search Icon" />}
                                                    {/* <img src={searchIcon} className="searchIcon" alt="Search Icon" /> */}
                                                </InputAdornment>

                                            ),
                                        }}
                                    />
                                )}
                            />

                            <div
                                className="search-filters d-flex align-items-baseline"
                                onClick={() => settingHandler()}
                                style={{
                                    pointerEvents: isSettingDropDownOpen ? "none" : "auto",
                                }}
                            >
                                <div className="filters-icon">
                                    <IoFilter />
                                </div>
                                <div
                                    className="filters-title"
                                >
                                    Filters
                                </div>
                                <div className="filters-count">
                                    {(() => {
                                        let count = 0
                                        for (let key in selectedOptions) {
                                            // if (obj.hasOwnProperty(key)) {
                                            //     console.log(key, obj[key]);
                                            // }
                                            console.log('key', key)
                                            count = count + selectedOptions[key].length
                                        }
                                        if(!isEmptyNullUndefined(count) && !(count == 0)) {
                                            return (`+${count}`)
                                        }
                                        // return count
                                    })()}
                                    {/* 20 */}
                                </div>
                            </div>
                        {isSettingDropDownOpen ? (
                        <div ref={dropdownRef}>
                            <div>
                                <Filters handleCloseFilter={() => handleCloseFilter()} handleFilteredData={(filteredData) => handleFilteredData(filteredData)} parentSelectedOptions={selectedOptions} handleUpdateParentfilterDataList={(filterDataList) => handleUpdatefilterDataList(filterDataList)} handleSetInitialSelectedOptions={(initialSelectedOption) => handleSetInitialSelectedOptions(initialSelectedOption)} />
                            
                            </div>
                        </div>

                        ) : (
                        <div></div>
                        )}

                        </div>
                    </div>
                    {
                        // only show when there filter present
                        (() => {
                            let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
                            let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
                            let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);

                            if(selectedOptionsArray.length > 0) {
                                return (
                                    <div className="d-flex justify-content-between my-3">
                                        <div className="d-flex gap-4 overflow-auto w-75 pb-1">
                                            {renderFilterCards()}
                                        </div>
                                        <div onClick={() => handleResetFilter()} className="reset-filters">Reset Filters</div>
                                    </div>
                                )
                            } 
                        })()
                    }
                    
                    
                </div>

                {/* <div className="search">
                        <input placeholder="Search" className="searchInput" value={searchvalue} onChange={handleChangeSearch} />
                        <img src={searchIcon} className="searchIcon" alt="Search Icon" />

                    </div>
                    <div className={"searchOptions"}>
                        <div> <Avatar name={"suraj" || 'Unknown User'} size="40" round={true} /></div>
                        <div>Name<br />designation</div>
                    </div> */}
                <div className="profile-table table-container-sample">
                    <table>
                        <thead>
                            <tr>
                                <th className="sticky">
                                    <div className="filter">
                                        <p className="label">Name</p>
                                        {!checkIsSubEmpOpen(JSON.parse(JSON.stringify(myTeamData))) && (
                                            handleSortDomCreateion('employeeName')
                                        )}
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <p className="label">Grade</p>
                                        {!checkIsSubEmpOpen(JSON.parse(JSON.stringify(myTeamData))) && (

                                            handleSortDomCreateion('employeeGrade')
                                        )}
                                    </div>

                                </th>
                                <th>
                                    <div className="filter">
                                        <p className="label">PhonePe Tenure</p>
                                    </div>

                                </th>
                                <th>
                                    <div className="filter">
                                        <p className="label">Time in Level</p>
                                    </div>

                                </th>
                                <th>
                                    <div className="filter">
                                        <p className="label">Last Performance Rating</p>
                                    </div>

                                </th>
                                <th>
                                    <div className="filter">
                                        <p className="label">Performance / Competency Profile</p>
                                    </div>

                                </th>

                                {/* // commented 7 july
                                <th>
                                    <div className="filter">
                                        <p className="label">IDP Idle since</p>
                                    </div>
                                </th>
                                <th>Time in Level / Pace of Promotion</th>
                                <th className="large-content-cell">Last Performance Rating</th>
                                <th>Talent Card</th> */}
                                
                                
                                
                                {/* <th>Designation</th> */}
                                {/* <th>PhonePe Tenure</th> */}
                                {/* <th>Level</th> */}



                                {/* <th>Time since last promotion</th> */}
                                {/* // commented 7 july
                                <th>
                                    <div className="filter">
                                        <p className="label">EEP Completion</p>
                                    </div>
                                </th>
                                <th>IDP</th> */}
                            </tr>
                        </thead>
                        {myTeamLoader ? (
                            <tbody>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                            
                                {
                                    myTeamData?.data?.map((val, index) => (
                                        <tr key={index} style={{ cursor: "pointer" }}>
                                            {console.log('val',val)}
                                            {console.log('subEmployeeLoadId',subEmployeeLoadId)}
                                            {console.log(console.log(val.employeeId == subEmployeeLoadId))}
                                            <td className="sticky">
                                                <div className="details" style={{ marginLeft: Number(val?.level ? val.level : 0) * 17, paddingLeft: Number(val?.level ? 1 : 0) * 15, borderLeft: val?.level ? "1px solid #e3e3e3" : "" }}>
                                                    {val?.isManager && !val?.isOpened ?
                                                        (((subEmployeeLoadId == val?.employeeId) && (isSubEmployeeLoading)) ? <CircularProgress style={{width: '25px', height: '25px'}} /> : <img style={{opacity: isSubEmployeeLoading ? '30%' : '100%', pointerEvents: isSubEmployeeLoading ? 'none' : 'auto'}} src={plus} alt="plus" onClick={() => getEmployeesPlus(val?.employeeId, val?.company, val?.level ? val.level : 0, index + 1)} />)
                                                        : val?.isManager && val?.isOpened ?
                                                            <img style={{cursor: checkIsParentSubEmpOpen(val?.employeeId, index, JSON.parse(JSON.stringify(myTeamData))) ? 'none' : 'pointer', pointerEvents: checkIsParentSubEmpOpen(val?.employeeId, index, JSON.parse(JSON.stringify(myTeamData))) ? 'none' : 'auto', opacity: checkIsParentSubEmpOpen(val?.employeeId, index, JSON.parse(JSON.stringify(myTeamData))) ? '0.3' : '1'}} src={minusClose} alt="minus" onClick={() => getEmployeeMinus(val?.employeeId,index+1)} />
                                                            : <img src={plus} alt="plus" className="hidden" />}

                                                    {val?.profilePhotoPath ? (
                                                        <img src={val.profilePhotoPath} className="userImage" alt="User Profile" onClick={() => clickEmp(val?.employeeId, val?.company)} />
                                                    ) : (
                                                        <Avatar name={val?.employeeName || 'Unknown User'} size="40" round={true} onClick={() => clickEmp(val?.employeeId, val?.company)} />
                                                    )}
                                                    <div className="detailsInner" onClick={() => clickEmp(val?.employeeId, val?.company)}>
                                                        <p className="title">{val?.employeeName}</p>
                                                        {/* <p className="empId">Emp id: {val?.employeeId}</p> */}
                                                        <p className="empDesignation">{val?.employeeDesignation}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{val?.employeeGrade}</td>
                                            <td>{val?.tenure}</td>
                                            <td>{val?.timeInLevel}</td>
                                            <td>{val?.lastPerformanceRating}</td>
                                            <td onClick={() => clickEmp(val?.employeeId, val?.company)}  className="table-action-button">View</td>

                                            {/* // commented 7 july
                                            <td>{val?.idpIdle}</td>
                                            <td>{val?.timeInLevel} / {val?.paceOfPromotion}</td>
                                            <td>{val?.lastPerformanceRating}</td>
                                            <td className="table-action-button">View</td>
                                            // */}
                                            
                                            
                                            {/* <td>{val?.employeeDesignation}</td>
                                            <td>{val?.tenure}</td>
                                            <td>{val?.employeeLevel}</td> */}


                                            {/* <td>{val?.timeSinceLastPromotion}</td> */}
                                            {/* <td>{val?.eepCompletion}</td> */}
                                            {/* // commented 7 july
                                            <td className="table-action-button">View Plan</td>
                                            <td className="table-action-button">View Plan</td>
                                            // */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        )
                        }
                        
                    </table>
                    {
                        isEmptyNullUndefined(myTeamData?.data) && (

                            <Stack width={'fullWidth'} justifyContent={'center'} display={'flex'} textAlign={'center'}>
                                No data found...!
                            </Stack>
                        )
                    }
                </div>
                    <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
                        {
                            !myTeamLoader && <Pagination count={myTeamData?.totalPages} page={page} onChange={handleChange} />
                        }
                    </div>
            </div>
        </div>
    );
}

export default TeamView;
