import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import APIList from "../../../api";
import { RiErrorWarningFill } from 'react-icons/ri'; 
import { toast } from 'react-toastify';

const DashboardView = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    loading: null,
    data: {},
  });

  const userToken = useSelector((state) => state?.user?.userToken);

  useEffect(() => {
    if (userToken?.id_token !== undefined) {
      const dashboardData = async () => {
        try {
          setIsLoading(true);
          const res = await APIList.getDashboardData({
            companyId: 'Tester LLC',
            annualCycleId : 'Review cycle 21 oct',
            employeeId: [],
          });
          
          if (res?.status === 200) {
            setData({
              loading: false,
              data: res.data,
            });
          }
        } catch (err) {
          toast.error(
            <div className="flex flex-row items-center">
              <RiErrorWarningFill className="w-8 h-8" />
              <span className="ml-2">{err.message}</span>
            </div>
          );
        } finally {
          setIsLoading(false);
        }
      };

      dashboardData();
    }
  }, [userToken]);

  return (
    <div className="h-screen flex flex-col">
      <div className="flex items-center p-4 bg-white shadow-sm">
        <h1 className="text-xl font-semibold">Dashboard</h1>
        <div className="breadcumb ml-4"></div>
      </div>
      
      <div className="flex-1 relative">
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <iframe
            src={data.data}
            // className="w-full h-full border-none"
            title="Dashboard Content"
            style={{
              width: '100%',
              height: 'calc(100vh - 115px)'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardView;