// import Dashboard from "../../../../assets/images/dynamicSidebar/home";
// import Profile from "../../../../assets/images/dynamicSidebar/profile";
// import SelfAssessment from "../../../../assets/images/dynamicSidebar/performance";
// import Givefeedback from "../../../../assets/images/dynamicSidebar/giveFeedback";
// import RatingCollab from "../../../../assets/images/dynamicSidebar/msbr";
// import MyTeam from "../../../../assets/images/dynamicSidebar/myteam";
import Myteam from "../../../../assets/images/dynamicSidebar/myteam";
import EmpProfile from "../../../../assets/images/dynamicSidebar/empProfile";
import EmpPlan from "../../../../assets/images/dynamicSidebar/empPlan";
import TalentNotes from "../../../../assets/images/dynamicSidebar/talentNotes";
import WorkFlows from "../../../../assets/images/dynamicSidebar/workFlows";
import { PiDotDuotone } from "react-icons/pi";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import SessionPlanThree from "../../../pages/sessionPlanThree";
// import SessionPlanFourth from "../../../pages/sessionPlanFourth";
// import SessionPlanTwo from "../../../pages/sessionPlanTwo";
// import SessionPlan from "../../../pages/sessionPlan";
// import FunctionalLeader from "../../../../assets/images/dynamicSidebar/functionalLeader";
// import ManagerCalibration from "../../../../assets/images/dynamicSidebar/functionalLeader"
import ThreeSixty from "../../../../assets/images/dynamicSidebar/threeSixty"


export const allRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <Myteam />,
  },
  {
    path: "/team-view",
    name: "Team View",
    icon: <Myteam />,
  },

  {
    // path: "/session-plan",
    name: "My Plan",
    icon: <Myteam />,
    subcategory: [
      {
        path: "/my-plan",
        name: "My Plan",
        icon: <Myteam />,
      },
      {
        path: "/my-plan",
        name: "Check-Ins",
        icon: <Myteam />,
      }
    ]

  },

  {
    // path: "employee-profile",
    path: "/employee-profile/profile",
    name: "Employee Profile",
    icon: <EmpProfile />,
    // subcategory: [          
    // {
    //   path: "/employee-profile/experience",
    //   name: "Experience",
    //   icon: <PiDotDuotone />,
    // },
    // {
    //   path: "/employee-profile/Education",
    //   name: "Education & Certification",
    //   // icon: <SelfAssessment />,
    // },
    // {
    //   path: "/employee-profile/Performance-History",
    //   name: "Performance History",
    //   // icon: <Givefeedback />,
    // } ,
    // {
    //   path: "/employee-profile/Interest-Aspiration",
    //   name: "Interest & Aspiration",
    //   // icon: <Givefeedback />,
    // } ,
    // {
    //   path: "/employee-profile/Learning-Development",
    //   name: "Learning & Development",
    //   // icon: <Givefeedback />,
    // } ,
    // ]
  },

  {
    path: "/ask-for-feedback",
    name: "360 Feedback",
    icon: <ThreeSixty />,
    subcategory: [
      {
        path: "/ask-for-feedback",
        name: "Ask For Feedback",
        icon: <ThreeSixty />,
      },
      // {
      //   path: "/give-peer-feedback",
      //   name: "Give Feedback",
      //   icon: <ThreeSixty />,
      // },
      {
        path: "/give-feedback",
        name: "Give Feedback",
        icon: <ThreeSixty />,
      },
      {
        path: "/approve-nominations",
        name: "Approve nominations",
        icon: <ThreeSixty />,
      },
      {
        path: "/view-nominations",
        name: "View nominations",
        icon: <ThreeSixty />,
      },
    ],
  },

  {
    path: "/three-sixty-feedback",
    name: "360 Feedback",
    icon: < ThreeSixty />,
  },
  {
    path: "/self-assesment/request-feedback",
    name: "Request Feedback",
    icon: <ThreeSixty />,
  },
  {
    path: "/give-peer-feedback",
    name: "Provide Feedback",
    icon: <ThreeSixty />,
  },
  {
    path: "/self-assesment/aprove",
    name: "Approve 360",
    icon: <ThreeSixty />,
  },
  /////// hide 1 - july //start///    
  // {
  //   path: "Employee-Plan",
  //   name: "Employee Plan",
  //   icon: <EmpPlan />,
  //   subcategory: [          
  //     {
  //       path: "/Employee-Plan/Summary",
  //       name: "Summary",
  //       // icon: <SelfAssessment />,
  //     },
  //     {
  //       path: "/Employee-Plan/My-Plan",
  //       name: "My Plan",
  //       // icon: <SelfAssessment />,
  //     },
  //     {
  //       path: "/Employee-Plan/Check-Ins",
  //       name: "Check-Ins",
  //       // icon: <SelfAssessment />,
  //     },
  //   ]
  // } ,    
  // {
  //   path: "/Talent-Notes",
  //   name: "Talent Notes",
  //   icon: <TalentNotes />,
  // },

  // {
  //   path: "/Workflows",
  //   name: "Workflows",
  //   icon: <WorkFlows />,
  // },  
  /////// hide 1 - july //start///    
];

export const prodRoutes = [
  {
    path: "/profile",
    name: "My Profile",
    // icon: <Profile />,
  },
  {
    path: "/feedback",
    name: "My Team",
    // icon: <MyTeam />,
  },

]