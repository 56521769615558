import React, { useState } from 'react';
import { Grid, Typography, Select, MenuItem, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

const YourTeamMember = ({ }) => {
    const [teamMember, setTeamMember] = useState('select');

    const handleTeamMemberChange = (event) => {
        setTeamMember(event.target.value);
    };

    return (
        <Grid container className="your-team-member-card">
            <Grid item xs={12} md={9} sm={9}>
                <Typography className="your-team-members">{"Your Team members"}</Typography>
                <Typography className="inside-check-ins">{"Navigate inside for Check-Ins"}</Typography>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
    <FormControl fullWidth>
        <InputLabel 
            id="demo-simple-select-label" 
            style={{ textAlign: 'center', left: '12%', top:'20%', transform: 'translateX(-50%)' }} 
            shrink
        >
            Select
        </InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={teamMember}
            size="small"
            defaultValue="Select"
            onChange={handleTeamMemberChange}
        >
            <MenuItem value={10}>Team Member 1</MenuItem>
            <MenuItem value={20}>Team Member 2</MenuItem>
            <MenuItem value={30}>Team Member 3</MenuItem>
        </Select>
    </FormControl>
</Grid>

        </Grid>
    );
};

export default React.memo(YourTeamMember);
