import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Avatar, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import APIList from "../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";
import SideDrawer from "../../common/sideDrawerWithCloseHandle";
import AddRespondentForm from "./AddRespondentForm";
import { LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import HeaderWellcomeCard from "./HeaderWellcomeCard";
import NominationsCard from "./NominationCard";
import DirectReportList from './DirectReportList';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from "@mui/material";
import { isEmptyNullUndefined, removeEmptyArrays } from "../../utils/utils";
import { getStatusChip } from "./utils";
import { IoArrowDownOutline, IoArrowUp, IoFilter } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import Filters from "./Filters";
import Pagination from '@mui/material/Pagination';
import Message from './Message';
import Manager from './Manager'
import InfoCard from './InfoCard'
import { BsFillCheckCircleFill } from "react-icons/bs";
import HeadDocumentIcon from "../../../assets/images/AskForFeedback/headDocument.svg";
import HeadBellIcon from "../../../assets/images/AskForFeedback/headBell.svg";


const AskForFeedback = () => {
  const emails = ['username@gmail.com', 'user02@gmail.com'];
  const dropdownRef = useRef(null);
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const dispatch = useDispatch();

  const initialFilters = {
    // employeeGrade: [],
    // employeeLevel: [],
    // employeeSubfunction: [],
    // gender: [],
    // pod: [],
    // phonepeTenure: [],
    // timeSinceLastPromotion: [],
  }
  const data = [
    {
      name: 'Venkateshan V',
      role: 'Software Engineer',
      email: 'venkateshan@exmaple.com',
      criticality: 'Not Critical',
      nominationStatus: 'Approved',
      avatar: 'path_to_avatar1.jpg',
    },
    {
      name: 'Hitesh Sharma',
      role: 'Architect',
      email: 'hiteshsharma@exmaple.com',
      criticality: 'Critical',
      nominationStatus: 'Approval Pending',
      avatar: 'path_to_avatar2.jpg',
    },
    // Additional rows
  ];

  // const [open, setOpen] = React.useState({}) // hide 13-11
  const [filterData, setFilterData] = useState(JSON.parse(JSON.stringify(initialFilters)))
  const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(JSON.parse(JSON.stringify(initialFilters)));
  const [page, setPage] = useState(1);
  const [myTeamLoader, setMyTeamLoader] = useState(false);
  const [myTeamData, setMyTeamData] = useState([]);
  const [infoCardData, setInfoCardData] = useState(null)


  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });
  const [sortType, setSortType] = useState({
    type: 'descending',
    sortBy: ''
  })

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      getMyTeamData()
    }

  }, [employeeDetails.id]);

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails.id)) {
      let isFiltered = checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions)))
      getMyTeamData(selectedOptions, isFiltered)
    }
  }, [page]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /////////// for dilog ///////////
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };


  /////////////////////////////

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingDropDownOpen(false);
    }
  };

  const handleSetInitialSelectedOptions = (initialSelectedOption) => {
    setSelectedOptions(() => initialSelectedOption)
  }

  const handleUpdatefilterDataList = (filterDataList) => {
    setFilterData(() => filterDataList)
  }

  const settingHandler = () => {
    if (!isSettingDropDownOpen) {
      setIsSettingDropDownOpen(true);
    }
  };

  const handleCloseFilter = () => {
    setIsSettingDropDownOpen(() => false)
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const updateStakeholdersList = () => {
    getMyTeamData()
  }

  const handleSortDomCreateion = (domSortBy) => {
    if (sortType.sortBy == domSortBy) {
      if (sortType.type == 'descending') {
        return (
          <IoArrowUp

            style={{ cursor: 'pointer', pointerEvents: myTeamLoader ? 'none' : 'auto', opacity: myTeamLoader ? '30%' : '100%' }}
            onClick={() => { handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy) }}
          />

        )
      } else {
        return (
          <IoArrowDownOutline
            style={{ cursor: 'pointer', pointerEvents: myTeamLoader ? 'none' : 'auto', opacity: myTeamLoader ? '30%' : '100%' }}
            onClick={() => { handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy) }}
          />
        )
      }
    } else {
      return (
        <TbArrowsSort
          style={{ cursor: 'pointer', pointerEvents: myTeamLoader ? 'none' : 'auto', opacity: myTeamLoader ? '30%' : '100%' }}
          onClick={() => { handleSort(JSON.parse(JSON.stringify(myTeamData)), domSortBy) }}
        />
      )
    }
  }

  const handleFilteredData = (selectedOptions) => {
    checkIsThereAnyFilter(selectedOptions) ? getMyTeamData(selectedOptions, true) : getMyTeamData() // true for isFiltered
    handleCloseFilter()

    setSelectedOptions(() => selectedOptions)
    console.log('selectedOptions', selectedOptions)

    //
    let tempfilterData = JSON.parse(JSON.stringify(filterData))
    let tempSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    let onlyDataSelectedOptions = removeEmptyArrays(tempSelectedOptions);
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    // setting initialOpen 
    let tempOpen = {}
    selectedOptionsArray?.forEach((e, i) => {
      tempOpen[e] = null
    })
    // setOpen(() => tempOpen)
  }

  const checkIsThereAnyFilter = (selectedOptions) => {
    let tempselectedOptions = JSON.parse(JSON.stringify(selectedOptions))
    ///// check is filter empty
    let onlyDataSelectedOptions = removeEmptyArrays(JSON.parse(JSON.stringify(tempselectedOptions)));
    let selectedOptionsArray = Object.keys(onlyDataSelectedOptions);
    ////

    if (selectedOptionsArray.length == 0) {
      return false
    } else {

      return true
    }
  }

  const handleSort = (tempmyTeamData, soryBy) => { //myTeamData, 'employeeName'
    const getSortedList = (sortBy, sortDir, filteredData, isFiltered) => {
      setMyTeamLoader(true);
      // api/new-employees/myTeam/filter?page=0&size=10&sortBy=employeeGrade&sortDir=desc
      let data = null

      APIList.getSortedAssessmentResponseFeedbackRequestDirectReportsRequest({
        payload: {
          keyword: "",
          employeeId: employeeDetails?.id,
          employeeEligibilityDTO: (isFiltered == true) ? filteredData : {
            "employeeLevel": [],
            "employeeType": [],
            "employeeStatus": [],
            "employementStatus": [],
            "employeeFunction": [],
            "employeeSubFunction": []
          }, //{},
          // filterData: (isFiltered == true) ? true : false, //false,
          // companyId: employeeDetails?.company?.id,
          //   "employeeEligibilityDTO": {
          //     "employeeLevel": [],
          //     "employeeType": [],
          //     "employeeStatus": [],
          //     "employementStatus": [],
          //     "employeeFunction": [],
          //     "employeeSubFunction": []
          // },
        },
        page: page - 1,
        size: 10,
        sortBy,
        sortDir,
      }).then((res) => {
        setMyTeamData(() => res.data)
        setMyTeamLoader(false);
      }

      ).catch(err => {
        setMyTeamLoader(false);
        console.log(err)
      })
    }
    let tempsortType = { ...sortType }

    if (tempsortType.sortBy == soryBy) {
      if (tempsortType.type == 'descending') {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getSortedList(soryBy, 'desc', selectedOptions, true) : getSortedList(soryBy, 'desc') //sortBy, sortDir  asc and desc
        tempsortType.type = 'ascending'

        tempsortType.sortBy = soryBy
        setSortType(() => tempsortType)
      } else {
        checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getMyTeamData(selectedOptions, true) : getMyTeamData()
        tempsortType.type = 'descending'

        tempsortType.sortBy = ''
        setSortType(() => tempsortType)
      }



    } else {
      checkIsThereAnyFilter(JSON.parse(JSON.stringify(selectedOptions))) ? getSortedList(soryBy, 'asc', selectedOptions, true) : getSortedList(soryBy, 'asc') //sortBy, sortDir  asc and desc

      tempsortType.type = 'descending'

      tempsortType.sortBy = soryBy
      setSortType(() => tempsortType)

    }

  }

  const resendRequest = (row) => {
    setMyTeamLoader(true);
    APIList.sendFeedbackRequest({
      requestedBy: {
        id: employeeDetails?.id
      },
      requestedTo: {
        id: row?.requestedTo?.id
      },
      relationship: "Peer",
      message: row.message,
      isResend: true,
      managerInitiatesPeerFeedback: false,
      initiateManager: null,
      isCritical: false,
      feedbackFormId: null

    })
      .then((res) => {
        toast.success(
          <div className="flex flex-row">
            <BsFillCheckCircleFill
              style={{ width: "2rem", height: "2rem" }}
            />
            {` Request sent successfully`}
          </div>
        );
        getMyTeamData()
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.message}
          </div>
        );
        setMyTeamLoader(true);
      })
  }

  const AssessmentResponseDeleteFeedbackRequest = (id) => {
    setMyTeamLoader(true);
    APIList.AssessmentResponseDeleteFeedbackRequest(id).then((res) => {
      toast.success(
        <div className="flex flex-row">
          <BsFillCheckCircleFill
            style={{ width: "2rem", height: "2rem" }}
          />
          {` Request deleted successfully`}
        </div>
      );
      getMyTeamData()
      // setMyTeamLoader(false);
    }).catch((err) => {
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );
      setMyTeamLoader(false);
    })
  }

  const getMyTeamData = (filteredData, isFiltered) => {
    setMyTeamLoader(true);
    APIList.getAssessmentResponseFeedbackRequestSentRequest({
      payload: {
        employeeEligibilityDTO: (isFiltered == true) ? filteredData : {
          "employeeLevel": [],
          "employeeType": [],
          "employeeStatus": [],
          "employementStatus": [],
          "employeeFunction": [],
          "employeeSubFunction": []
        }, //{},
        // filterData: (isFiltered == true) ? true : false, //false,
        keyword: "",
        // managerId: employeeDetails?.id,
        // companyId: employeeDetails?.company?.id,
        // page: "My Team",
        // hrbp: activeTab === 'Hrbp' ? true : false

        "keyword": "",
        "employeeId": employeeDetails?.id,

        //   "employeeEligibilityDTO": {
        //     "employeeLevel": [],
        //     "employeeType": [],
        //     "employeeStatus": [],
        //     "employementStatus": [],
        //     "employeeFunction": [],
        //     "employeeSubFunction": []
        // },
      },
      page: page - 1,
      size: 10
    }).then((res) => {
      setMyTeamData(res?.data);
      setMyTeamLoader(false);
    }).catch((err) => {
      toast.error(
        <div style={{ display: "flex", flexDirection: "row" }}>
          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
          &nbsp;&nbsp;{err?.title}
        </div>
      );
      setMyTeamLoader(false);
    })
  }

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  return (
    <Grid container className="ask-for-feedback-page">

      {/*----- Header -----*/}
      <Grid item xs={12} md={12} sm={12} className="header-grid">
        <Typography className="header-title">{"360 Feedback"}</Typography>

        <Grid className="d-flex gap-15">
          <img src={HeadDocumentIcon} className="headIcon" alt="profilePic"></img>
          <img src={HeadBellIcon} className="headIcon" alt="profilePic"></img>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sm={12}>

        {/*----- WelCome msg card -----*/}
        <HeaderWellcomeCard />

        {/*----- Ask For Feedback & Nomination Closure On -----*/}
        <Grid container className='ask-for-feedback-grid'>
          <Grid item xs={12} md={6} sm={6}>
            <Typography className="ask-for-feedback">{"Ask For Feedback"}</Typography>
          </Grid>

          <Grid item xs={12} md={6} sm={6} className="ac-center">
            <Typography className="nomination-closure-on">{"Nomination Closure on 20/10/2024"}</Typography>
          </Grid>

          <Grid item xs={12} md={12} sm={12} className="sub-card">
            <Typography className="description">{'Click on "Add Stakeholder(s)" to set up nominations for your 360 Feedback. Once you add them, click on "Submit" to send nomination for approval by your manager'}</Typography>
          </Grid>
        </Grid>

        {/*----- Nominations Summary -----*/}
        <Grid container className='ask-for-feedback-grid'>
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="nominations-summary">{"Nominations Summary"}</Typography>
          </Grid>

          <Grid item xs={12} md={12} sm={12} className="sub-card">
            <Grid container>
              <Grid item xs={12} md={4} sm={4}>
                <Grid className="sub-card d-flex-jc-sb">
                  <Typography className="nomination-sub-txt">{"Added by Employee"}</Typography>
                  <Typography className="nomination-count-txt">{"4"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sm={4} className="plr-8">
                <Grid className="sub-card d-flex-jc-sb">
                  <Typography className="nomination-sub-txt">{"Added by Manager"}</Typography>
                  <Typography className="nomination-count-txt">{"4"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Grid className="sub-card d-flex-jc-sb">
                  <Typography className="nomination-sub-txt">{"Total Nominations Approved"}</Typography>
                  <Typography className="nomination-count-txt">{"4"}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <div className="add-respondent">
          <button className="button" onClick={() => setIsOpenDrawer(true)}>
            Add a Respondent
          </button>
        </div> */}

        {/* <div style={{
          alignItems: 'flex-start',
        }} className="main">
          <div style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '100%',
          }} className="ratingInner">
            <Accordion
              sx={{
                boxShadow: 'none !important',
                '& .MuiAccordionSummary-root': {
                  paddingLeft: 0,
                  boxShadow: 'none !important',
                  '& .MuiAccordionSummary-content': {
                    marginTop: 0,
                    paddingTop: 0,
                    alignSelf: 'flex-start',
                  }
                },
                background: '#F9FAFB'
              }}
              expanded={expanded.panel1} onChange={handleChangeAccordian('panel1')}>
              <AccordionSummary
                sx={{
                  pointerEvents: 'none',
                }}
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div style={{
                  marginTop: '5px',
                  marginRight: '0.5rem',
                }}>
                  {
                    (() => {
                      if (expanded.panel1 == true) {
                        return (
                          <LuMinusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel1');
                            }}
                          />
                        )
                      } else {
                        return (
                          <LuPlusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel1');
                            }}
                          />
                        )
                      }
                    })()
                  }

                </div>
                <p className="ratingTitle">
                  <span style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }} className="name">Nomination Summary</span>
                </p>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0 !important' }}>
                <div>
                  <p className="date">Nomination Closure on 20/10/2024</p>
                  <div className="summary">
                    <div className="stakeholders">Stakeholders</div>

                    <div className="summary-cards">
                      {!myTeamLoader ? (!isEmptyNullUndefined(myTeamData) && <NominationsCard data={{
                        rowOne: { 'Approved': myTeamData.nominationSummary.managerApprovedRequestCount },
                        rowTwo: { 'Approval Pending': myTeamData.nominationSummary.pendingForApprovalRequestCount },
                        rowThree: { 'Rejected By Manager': myTeamData.nominationSummary.managerRejectedRequestCount },
                      }} />) : <Skeleton width={'30%'} height={'13rem'} sx={{ "-webkitTransform": 'none', borderRadius: '12px' }} />}
                      {!myTeamLoader ? (!isEmptyNullUndefined(myTeamData) && <NominationsCard data={{
                        rowOne: { 'Nominations': myTeamData.nominationSummary.managerInitiatedRequestCount },
                        rowTwo: null,
                        rowThree: null,
                      }} />) : <Skeleton width={'33%'} height={'13rem'} sx={{ "-webkitTransform": 'none', borderRadius: '12px' }} />}
                      {!myTeamLoader ? (!isEmptyNullUndefined(myTeamData) && <NominationsCard data={{
                        rowOne: { 'Total': myTeamData.nominationSummary.allApprovedRequestCount },
                        rowTwo: null,
                        rowThree: null,
                      }} />) : <Skeleton width={'33%'} height={'13rem'} sx={{ "-webkitTransform": 'none', borderRadius: '12px' }} />}
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

          </div>
        </div> */}


        <Grid container className='list-of-stakeholders-grid'>
          <Grid item xs={12} md={6} sm={6}>
            <Typography className="list-of-stakeholders">{"List of Stakeholders"}</Typography>
          </Grid>

          <Grid item xs={12} md={6} sm={6} className="ac-center">
            <div className="add-respondent">
              <button className="button" onClick={() => setIsOpenDrawer(true)}>
                Add a Respondent
              </button>
            </div>
          </Grid>
        </Grid>

        {/* <div style={{
          alignItems: 'flex-start',
        }} className="main">
          <div style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '100%',
          }} className="ratingInner">
            <Accordion
              sx={{
                boxShadow: 'none !important',
                '& .MuiAccordionSummary-root': {
                  paddingLeft: 0,
                  boxShadow: 'none !important',
                  '& .MuiAccordionSummary-content': {
                    marginTop: 0,
                    paddingTop: 0,
                    alignSelf: 'flex-start',
                  }
                },
                background: '#F9FAFB'
              }}
              expanded={expanded.panel2} onChange={handleChangeAccordian('panel2')}> */}
        {/* <AccordionSummary
                sx={{
                  pointerEvents: 'none',
                }}
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <div style={{
                  marginTop: '5px',
                  marginRight: '0.5rem',
                }}>
                  {
                    (() => {
                      if (expanded.panel2 == true) {
                        return (
                          <LuMinusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel2');
                            }}
                          />
                        )
                      } else {
                        return (
                          <LuPlusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel2');
                            }}
                          />
                        )
                      }
                    })()
                  }

                </div>
                <p className="ratingTitle">
                  <span style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }} className="name">List of Stakeholders</span>
                </p>
              </AccordionSummary> */}
        {/* <AccordionDetails sx={{ padding: '0 !important' }}> */}
        <div>
          <div className="w-100 d-flex justify-content-between table-header-filter-container">
            {/*----- Added by manager -----*/}
            <div className="manager-symbol d-flex">
              <div className="icon">
                <Manager />
              </div>
              <div className="title">
                - Added by manager
              </div>
            </div>

            {/*----- Filter -----*/}
            <div className="d-flex">
              <div
                className="search-filters d-flex align-items-baseline"
                onClick={() => settingHandler()}
                style={{
                  pointerEvents: isSettingDropDownOpen ? "none" : "auto",
                }}
              >
                <div className="filters-icon">
                  <IoFilter />
                </div>
                <Typography className="filters-title">{"Filters"}</Typography>
                <Typography className="filters-count">
                  {(() => {
                    let count = 0
                    for (let key in selectedOptions) {
                      count = count + selectedOptions[key].length
                    }
                    if (!isEmptyNullUndefined(count) && !(count == 0)) {
                      return (`+${count}`)
                    }
                  })()}
                </Typography>
              </div>

              {isSettingDropDownOpen ? (
                <div ref={dropdownRef}>
                  <div>
                    <Filters handleCloseFilter={() => handleCloseFilter()} handleFilteredData={(filteredData) => handleFilteredData(filteredData)} parentSelectedOptions={selectedOptions} handleUpdateParentfilterDataList={(filterDataList) => handleUpdatefilterDataList(filterDataList)} handleSetInitialSelectedOptions={(initialSelectedOption) => handleSetInitialSelectedOptions(initialSelectedOption)} />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          {!isEmptyNullUndefined(myTeamData) && <TableContainer style={{ borderRadius: '0px 0px 12px 12px' }} component={Paper}>
            <Table >
              <TableHead style={{ background: '#F9FAFB' }}>
                <TableRow>
                  <TableCell className="table-header-style table-name-sticky-coll-header" style={{ textAlign: 'center' }}>
                    Name
                    {handleSortDomCreateion('employeeName')}
                  </TableCell>
                  <TableCell className='table-header-style' style={{ textAlign: 'center' }}>
                    Email Id
                    {handleSortDomCreateion('employeeEmail')}
                  </TableCell>
                  <TableCell className='table-header-style' style={{ textAlign: 'center' }}>
                    Nomination Status
                    {handleSortDomCreateion('status')}
                  </TableCell>
                  <TableCell className='table-header-style' style={{ textAlign: 'center' }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myTeamLoader ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  myTeamData.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className='table-name-sticky-coll-body'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {
                            row?.requestedTo?.profilePhoto ? (
                              <img width={'40px'} height={'40px'} src={row?.requestedTo?.profilePhoto} className="userImage" alt="User Profile" />
                            ) : (
                              <Avatar src={row?.requestedTo?.name} alt={row?.requestedTo?.name} />
                            )
                          }
                          <div style={{ marginLeft: '10px' }}>
                            <div className=" d-flex align-items-center gap-2">
                              <div className='table-employee-name'>{row.requestedTo.name}</div>
                              <div className="message-namager-icon d-flex align-items-center gap-2">
                                <div className="message-icon d-flex align-items-center">
                                  <Message onClick={() => { setInfoCardData(row); handleClickOpen() }} />
                                </div>
                                <div className="manager-icon d-flex align-items-center">
                                  {row?.managerInitiatesPeerFeedback && <Manager />}
                                </div>
                              </div>
                            </div>
                            <div className='table-employee-designation'>designation</div>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell style={{ textAlign: 'center' }}>{row.requestedTo.emailId}</TableCell>
                      {/* <TableCell style={{ textAlign: 'center' }}>{row.isCritical ? 'Critical' : 'Not Critical'}</TableCell> */}
                      <TableCell style={{ textAlign: 'center' }}>
                        {getStatusChip(row.status)}
                        {/* {(() => {
                          if((row.status === 'Pending') || (row.status === 'Submitted') || (row.status === 'Declined')) {
                            return "Approved"
                          } else if(row.status === 'Pending For Approval') {
                            return "Approval Pending"
                          } else if(row.status === 'Denied') {
                            return "Denied"
                          } else {
                            return row.status
                          }
                        })()} */}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {/* <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}>⋮</button> */}
                        {/* <button className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Delete</button> */}
                        {(() => {
                          if ((row.status === 'Pending') || (row.status === 'Submitted') || (row.status === 'Declined')) {
                            // return "Approved" dont show any button
                            return <button disabled className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}></button>
                          } else if (row.status === 'Pending For Approval') {
                            // return "Approval Pending"
                            return <button onClick={() => AssessmentResponseDeleteFeedbackRequest(row.id)} className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Delete</button>
                          } else if (row.status === 'Denied') {
                            // return "Denied"
                            return <button onClick={() => resendRequest(row)} className="table-action-button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Resend</button>
                          } else {
                            // return row.status
                          }
                        })()}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>}

          {/*----- No data found...! -----*/}
          {isEmptyNullUndefined(myTeamData?.data) && (
            <Stack width={'fullWidth'} justifyContent={'center'} display={'flex'} textAlign={'center'}>
              No data found...!
            </Stack>
          )}
          <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
            {!myTeamLoader && <Pagination count={myTeamData?.totalPages} page={page} onChange={handleChange} />}
          </div>
        </div>

        {/* </AccordionDetails>
            </Accordion> 
          </div>
        </div>*/}

        {/* section 3 Direct reports */}
        {/* <div className="main">
        <div
          className="header"
          onClick={(e) => {
            e.stopPropagation();
            togglePanel("panel3");
          }}
        >
          <div className="icon">
            {expanded.panel3 ? (
              <LuMinusCircle
                style={{
                  cursor: "pointer",
                  color: "#7F56D9",
                  width: "25px",
                  height: "25px",
                }}
              />
            ) : (
              <LuPlusCircle
                style={{
                  cursor: "pointer",
                  color: "#7F56D9",
                  width: "25px",
                  height: "25px",
                }}
              />
            )}
          </div>
          <div className="title">Direct Reports</div>
          <div
            className="info"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            i
          </div>
        </div>
        {expanded.panel3 && (
          <div className="summary">
            <p>Add table here</p>

            <DirectReportList data={data} />
          </div>
        )}
      </div> */}

        {/*----- Direct Reports Table -----*/}
        <Grid container className='list-of-stakeholders-grid'>
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="list-of-stakeholders">{"Direct Reports"}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <DirectReportList data={data} />
          </Grid>
        </Grid>

        {/* <div style={{ alignItems: 'flex-start', }} className="main">
          <div style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '100%',
          }} className="ratingInner">
            <Accordion
              sx={{
                boxShadow: 'none !important',
                '& .MuiAccordionSummary-root': {
                  paddingLeft: 0,
                  boxShadow: 'none !important',
                  '& .MuiAccordionSummary-content': {
                    marginTop: 0,
                    paddingTop: 0,
                    alignSelf: 'flex-start',
                  }
                },
                background: '#F9FAFB'
              }}
              expanded={expanded.panel3} onChange={handleChangeAccordian('panel3')}>
              <AccordionSummary
                sx={{
                  pointerEvents: 'none',
                }}
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <div style={{
                  marginTop: '5px',
                  marginRight: '0.5rem',
                }}>
                  {
                    (() => {
                      if (expanded.panel2 == true) {
                        return (
                          <LuMinusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel3');
                            }}
                          />
                        )
                      } else {
                        return (
                          <LuPlusCircle
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              color: '#7F56D9',
                              height: '25px',
                              pointerEvents: 'all',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePanel('panel3');
                            }}
                          />
                        )
                      }
                    })()
                  }
                </div>
                <p className="ratingTitle">
                  <span style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }} className="name">Direct Reports</span>
                </p>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0 !important' }}>
                <DirectReportList data={data} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div> */}

        <div>
          {isOpenDrawer && (
            <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
              <AddRespondentForm
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                updateStakeholdersList={updateStakeholdersList}
              />
            </SideDrawer>
          )}
        </div>
        <InfoCard
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          data={infoCardData}
        />
      </Grid>
    </Grid>
  );
};

export default AskForFeedback;
