import React from "react";
import { Grid, Typography } from "@mui/material";
import { LuDelete, LuSearch } from "react-icons/lu"; // React Icons
import { FaUserCircle, FaTimesCircle, FaMinusCircle } from "react-icons/fa";

function viewProfile() {
  return (
    <div>
      <Grid item xs={12} md={12} sm={12} className="view-profile">
        <Grid className="header-profile">
          <Grid className="d-flex">
            <FaUserCircle
              size={60}
              className="iconHW60"
              aria-label="Profile Picture"
              title="Profile Picture"
            /> {/* Updated accessibility properties */}
            <Grid className="ml-10">
              <Typography className="profile-user-name" component="h1">
                {"Gowtham Vairavan"}
              </Typography>
              <Grid className="d-flex mt-6">
                <Typography className="profile-architect" component="h2">
                  {"Architect"}
                </Typography>
                <Typography className="dept-eng-dev" component="h2">
                  {"Dept: Engineering Development"}
                </Typography>
              </Grid>
              <Typography className="emp-id" component="p">
                {"Employee ID: 410905"}
              </Typography>
            </Grid>
          </Grid>
          <FaTimesCircle
            size={30}
            className="drawer-close-icon"
            aria-label="Close Drawer"
            title="Close Drawer"
          /> {/* Updated accessibility properties */}
        </Grid>

        {/* ------ Personal Details ------ */}
        <Grid className="details-grid">
          <Grid className="d-flex">
            <FaMinusCircle
              size={20}
              className="minus-circle"
              aria-label="Collapse Section"
              title="Collapse Section"
            /> {/* Updated accessibility properties */}
            <Typography className="details-label" component="h3">
              {"Personal Details"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Grade:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"A"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"DOJ:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"18th Nov 2019"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Previous Company:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Raymond FMCG"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Education:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"XIM - B (2018)"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Latest Performance Rating:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Strong Performance"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Criticality:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Medium"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Potential:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"High"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Tribal Knowledge:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Basic"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Last Promoted on:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"18th Nov 2020"}
            </Typography>
          </Grid>
        </Grid>

        {/* ------ Organizational Details ------ */}
        <Grid className="details-grid">
          <Grid className="d-flex">
            <FaMinusCircle
              size={20}
              className="minus-circle"
              aria-label="Collapse Section"
              title="Collapse Section"
            /> {/* Updated accessibility properties */}
            <Typography className="details-label" component="h3">
              {"Organizational Details"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Employee Function:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Corporate Business"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Employee Sub Function:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Account Management"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Manager Name:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"Prasad"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Manager Id:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"410954"}
            </Typography>
          </Grid>

          <Grid className="list-item">
            <Typography className="list-item-title" component="p">
              {"Manager Email Id:"}
            </Typography>
            <Typography className="list-item-value" component="p">
              {"prasad@phonepe.com"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default viewProfile;
