import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { allRoutes, managerRoutes, prodRoutes } from "./routes";
// import Avatar from "react-avatar";
import { actions as logInActions } from "../../../redux/login/actions";
import { IoIosArrowBack } from "react-icons/io";
// import Logout from "../../../assets/images/sidebar/logout";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { isEmptyNullUndefined } from "../../utils/utils";
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";
import { IoMdLogOut } from "react-icons/io";
import APIList from "../../../api";
import { injectStore } from "../../../api/apiServices";
import { actions as AnnualcycleActions } from "../../../redux/annualCycle/actions";

const DynamicSidebar = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const employeeDetails = useSelector((state) => state?.empData?.empData);
  // const allRequestCounts = useSelector((state) => state?.countsReducer?.counts);
  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );

  const [routes, setRoutes] = useState(null);
  const [sidebarCollide, setSidebarCollide] = useState(false);
  const [aciveTab, setAciveTab] = useState("");
  const [activeHead, setActiveHead] = useState(null);

  let currentPath = window.location.pathname;

  if(!window.location.pathname.split("/").includes("login") && isEmptyNullUndefined(userToken?.id_token)){
    console.log("window.location.pathname", window.location.pathname);
    localStorage.setItem("gotToPath", window.location.pathname);
  }

  useEffect(() => {
    console.log("is here");
    if (!isEmptyNullUndefined(userToken)) {
      dispatch(EmployeeDataActions.EmployeeDataRequest());
    } else {
      history.push("/login");
    }
  }, [userToken]);

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails?.id)) {
      // dispatch(
      //   DropdownDataActions.DropdownDataRequest({
      //     companyID: employeeDetails?.company?.id,
      //   })
      // );

      // dispatch(
      //   PmscycleActions.PmscycleDataRequest({
      //     companyID: employeeDetails?.company?.id,
      //   })
      // );
      console.log('am i called?')
      dispatch(
        AnnualcycleActions.AnnualcycleDataRequest({
          companyID: employeeDetails?.company?.id,
        })
      );
      // dispatch(
      //   NotificationActions.NotificationCountDataRequest({
      //     employeeId: employeeDetails?.id,
      //   })
      // );
      // dispatch(
      //   AnnualcycleActions.ManFeedbackCloseLoopRequest({
      //     companyId: employeeDetails?.company?.id,
      //   })
      // );
      // dispatch(
      //   GoalcycleActions.GoalcycleDataRequest({
      //     companyId: employeeDetails?.company?.id,
      //   })
      // );
      // dispatch(
      //   GoalcycleActions.ActiveGoalCycleDataRequest({
      //     companyId: employeeDetails?.company?.id,
      //     employeeId: employeeDetails?.id,
      //   })
      // );
    }
  }, [employeeDetails?.id, userToken]);

  useEffect(() => {
    if (aciveTab !== currentPath) {
      setAciveTab(currentPath);
    }
  }, [currentPath]);

  useEffect(() => {
    setRoutes(allRoutes);
  }, []);

  const handleSidebarCollide = () => {
    setSidebarCollide(!sidebarCollide);
    // console.log('in')
    // console.log(sidebarCollide)
  };

  const handleSidebarCollideTypeClassName = (element) => {
    // console.log('location', location.pathname)
    if (
      location.pathname === "/login" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/account/reset/finish"
    ) {
      if (element === "sidebar") {
        return "sidebar-login-page";
      } else {
        if (element === "main") {
          return "main-login-page";
        }
      }
    } else {
      if (element === "sidebar") {
        if (sidebarCollide) {
          return "sidebar-Collide";
        } else {
          return "sidebar";
        }
      } else if (element === "main") {
        if (sidebarCollide) {
          return "main-Collide";
        } else {
          return "main";
        }
      }
    }
  };

  const handleNavigation = (route, isSubcat) => {
    setAciveTab(route?.path);

    if (!route?.subcategory) {
      history.push(route?.path);

      if (!isSubcat) {
        setActiveHead(null);
      }
    } else {
      history.push(route?.path);
      setSidebarCollide(false);
      if (activeHead === route?.path) {
        !sidebarCollide && setActiveHead(null);
      } else {
        setActiveHead(route?.path);
      }
    }
  };

  // const handleLogOut = () => {
  //   console.log('userToken',userToken)
  //   console.log(userToken?.id_token)
  //   let jwtToken = userToken.id_token
  //   const submitApi = (empId, compId) => {

  //     APIList.logOutUser({jwtToken}).then((res) =>{
  //     }
  //     ).catch(err => {
  //         console.log(err)
  //     } );
  // }

  //   submitApi()
  //   dispatch(logInActions.clearLogInToken());
  //     localStorage.setItem("loggedOutFromFooter",true);
  //     setTimeout(() => {
  //       history.push('/login')
  //     }, 100
  //   );
  // }

  const handleLogOut = () => {
    
    const jwtToken = userToken?.id_token;

    if (!jwtToken) {
      alert.error("JWT token is missing.");
      return;
    }

    const submitApi = (empId, compId) => {
      APIList.logOutUser({ jwtToken })
        .then((res) => {
          console.log("Logged out successfully.");
        })
        .catch((err) => {
          console.error("Error during logout:", err);
        });
    };

    submitApi();
    dispatch(logInActions.clearLogInToken());
    localStorage.setItem("loggedOutFromFooter", true);

    setTimeout(() => {
      history.push("/login");
    }, 100);
  };

  return (
    <div className="main-container">
      <div className={handleSidebarCollideTypeClassName("sidebar")}>
        <div className="sidebar-main-outer">
          <div className="sidebarMain">
            <div className="headMiddle">
              <div className="header">
                <div className="user-container">
                  <div className="user-container-margin">
                    {/* <div className="icon-container">
                        <div className="icon-size">
                          {employeeDetails?.profilePhoto ? 
                              <img src={employeeDetails?.profilePhoto?.path} className="user" alt="User Image" />
                              :
                              <Avatar 
                                  name={employeeDetails.name} 
                                  size="70" 
                                  className="user"
                                  color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                              />
                          }
                        </div>
                      </div> */}
                    <div className="title-container">
                      <div className="align-title">
                        <div className="upper-title">Hello</div>
                        {/* <div className="lower-title">Sayed Adil Hussain !!</div> */}
                        <div className="lower-title">
                          {employeeDetails.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle">
                <section className="routes">
                  {routes?.map((route, index) => {
                    // if (route?.name === "Home" && !employeeDetails?.permissions?.includes("home")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "My Profile" && !employeeDetails?.permissions?.includes("view-profile")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "My Assessment" && !employeeDetails?.permissions?.includes("self-assessment")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "My Team" && !employeeDetails?.permissions?.includes("my-team")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "Rating Calibration" && !employeeDetails?.permissions?.includes("rating-calibration")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "Manager Calibration" && !employeeDetails?.permissions?.includes("manager calibration")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    // if (route?.name === "Functional Leader" && !employeeDetails?.permissions?.includes("functional-leader")) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }

                    // if (route?.name === "Feedback"
                    //       && !employeeDetails?.permissions?.includes("request-peer-feedback")
                    //       && !employeeDetails?.permissions?.includes("give-peer-feedback")
                    //       && !employeeDetails?.permissions?.includes("approve-page")
                    //     ) {
                    //   // Exclude route-item when route.name is "xyz"
                    //   return null;
                    // }
                    return (
                      <div className="route-item" key={index}>
                        <div
                          className={`category ${
                            aciveTab === route?.path ||
                            activeHead === route?.path
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleNavigation(route, false)}
                        >
                          <span className="flex"> {route?.icon} </span>
                          <span className="flex flex-center w-full justify-between">
                            {route?.name}
                            {route?.subcategory ? (
                              activeHead === route?.path ? (
                                <BsChevronUp />
                              ) : (
                                <BsChevronDown />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        {route?.subcategory &&
                          activeHead === route?.path &&
                          !sidebarCollide &&
                          route?.subcategory?.map((sub) => {
                            // if (sub?.name === "Request Feedback" && !employeeDetails?.permissions?.includes("request-peer-feedback")) {
                            //   // Exclude route-item when route.name is "xyz"
                            //   return null;
                            // }
                            // if (sub?.name === "Give Feedback" && !employeeDetails?.permissions?.includes("give-peer-feedback")) {
                            //   // Exclude route-item when route.name is "xyz"
                            //   return null;
                            // }
                            // if (sub?.name === "Approve" && !employeeDetails?.permissions?.includes("approve-page")) {
                            //   // Exclude route-item when route.name is "xyz"
                            //   return null;
                            // }
                            return (
                              <div
                                key={index}
                                className={`sub-cat ${
                                  aciveTab === sub?.path ||
                                  window.location.pathname === sub?.path
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleNavigation(sub, true)}
                              >
                                {sub?.name}
                                {/* {sub?.name === "Give Feedback" ? `${sub?.name} (${allRequestCounts?.pendingRequestCount})` : sub?.name} */}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </section>
              </div>
              {/* <div className="bottom">
                  <p className="feedbackTitle">Share feedback to:</p>
                  <p className="feedbackMail">employee.profile@phonepe.com</p>
                </div> */}
            </div>
            <div className="footer">
              {
                // employeeDetails?.company?.showAppraisal &&
                <div className="logout-size">
                  <div className="title">Logout</div>
                  <div onClick={() => handleLogOut()} className="logout-icon">
                    <IoMdLogOut />
                  </div>
                </div>
              }
            </div>
          </div>
          {/* <div className="for-collapse" onClick={() => handleSidebarCollide()}>
            <div className="collapsible-nav-bar">
                    <div className="collapsible-nav-size">
                      <IoIosArrowBack style={{transform: sidebarCollide ? "rotate(-180deg)" : "rotate(0deg)"}} />
                    </div>
                  </div>
              </div> */}
        </div>
      </div>
      <main className={handleSidebarCollideTypeClassName("main")}>
        {/* <div style={{
              height: '3.6rem'
            }} /> */}
        {children}
      </main>
    </div>
  );
};

export default DynamicSidebar;
