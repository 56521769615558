import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5305 10.7789C12.051 8.58086 10.567 6.9032 8.68923 6.26444C9.34256 5.75695 9.77073 4.97294 9.77073 4.08394C9.77073 2.55619 8.52765 1.31311 6.9999 1.31311C5.47215 1.31311 4.22906 2.55619 4.22906 4.08394C4.22906 4.97353 4.65781 5.75753 5.31115 6.26444C3.4334 6.9032 1.9494 8.58086 1.4699 10.7789C1.37015 11.2368 1.4839 11.7122 1.78256 12.082C1.93414 12.2714 2.12644 12.4242 2.34519 12.529C2.56394 12.6339 2.8035 12.688 3.04606 12.6875H10.9549C11.1974 12.6879 11.437 12.6337 11.6557 12.5288C11.8744 12.424 12.0667 12.2713 12.2184 12.082C12.3651 11.9023 12.4693 11.6917 12.5235 11.4661C12.5776 11.2405 12.5802 11.0056 12.5311 10.7789H12.5305ZM5.10465 4.08336C5.10465 3.03803 5.95515 2.18753 7.00048 2.18753C8.04581 2.18753 8.89631 3.03803 8.89631 4.08336C8.89631 5.12869 8.04581 5.97919 7.00048 5.97919C5.95515 5.97919 5.10465 5.12869 5.10465 4.08336ZM7.69231 9.18986L7.00048 9.8817L6.30865 9.18986L6.77356 6.86528C6.8494 6.86119 6.92406 6.85478 7.00048 6.85478C7.0769 6.85478 7.15156 6.86178 7.2274 6.86528L7.69231 9.18986ZM11.5365 11.5337C11.4666 11.621 11.378 11.6914 11.2772 11.7398C11.1765 11.7881 11.0661 11.8132 10.9543 11.8131H3.04606C2.81915 11.8131 2.6074 11.711 2.4639 11.5337C2.39898 11.4558 2.35273 11.3642 2.32866 11.2657C2.3046 11.1672 2.30337 11.0646 2.32506 10.9655C2.75906 8.97403 4.13806 7.47953 5.8519 7.01111L5.40448 9.24761C5.3759 9.39111 5.42081 9.53928 5.52406 9.64311L6.69073 10.8098C6.73127 10.8505 6.77945 10.8828 6.83251 10.9048C6.88556 10.9269 6.94245 10.9382 6.9999 10.9382C7.05735 10.9382 7.11423 10.9269 7.16729 10.9048C7.22034 10.8828 7.26852 10.8505 7.30906 10.8098L8.47573 9.64311C8.52657 9.59205 8.56407 9.52927 8.58493 9.4603C8.60578 9.39133 8.60935 9.31829 8.59531 9.24761L8.1479 7.01111C9.86173 7.47953 11.2407 8.97461 11.6747 10.9655C11.7185 11.1662 11.6695 11.3674 11.5365 11.5337Z"
      fill="#444CE7"
    />
  </svg>
);
export default SVGComponent;
