import React, { Suspense, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../components/pages/home";
import EmpProfile from "../components/pages/employeeProfile";
import EmpPlan from "../components/pages/employeePlan";
import TalentNotes from "../components/pages/talentNotes";
import LogIn from "../components/common/Login";
import teamview from "../components/pages/teamview";
import Dashboard from "../components/pages/dashboard";
import SessionPlan from "../components/pages/sessionPlan";
import ThreeSixty from "../components/pages/threeSixtyFeedBack";
import RequestFeedback from "../components/pages/requestFeedback";
import SentRequestTab from "../components/pages/requestFeedback/sentRequestTab";
import GivePeerFedback from "../components/pages/givePeerFeedback";
import ApproveRequest from "../components/pages/approveRequest";
import PeedFeedBack from "../components/pages/peerFeedBack";
import AskForFeedback from "../components/pages/askForFeedback";
import ApproveNominations from "../components/pages/approveNominations";
import ViewNominations from "../components/pages/viewNominations";
import GiveFeedback from "../components/pages/giveFeedback"

const AppRouters = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
        />
        <div>
          <Route exact path="/" component={LogIn} />
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/login/:xyz" component={LogIn} />

          <Route exact path="/home" component={Home} />
          <Route exact path="/employee-profile/:id" component={EmpProfile} />
          <Route exact path="/employee-plan" component={EmpPlan} />
          <Route exact path="/talent-notes" component={TalentNotes} />
          <Route exact path="/team-view" component={teamview} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/my-plan" component={SessionPlan} />
          <Route exact path="/three-sixty-feedback" component={ThreeSixty} />
          <Route exact path="/ask-for-feedback" component={AskForFeedback} />

          <Route
            exact
            path="/self-assesment/request-feedback"
            component={RequestFeedback}
          />
          <Route exact path="/SentRequestTab" component={SentRequestTab} />
          <Route exact path="/give-peer-feedback" component={GivePeerFedback} />
          <Route
            exact
            path="/self-assesment/aprove"
            component={ApproveRequest}
          />
          <Route exact path="/peer-feedBack/:id" component={PeedFeedBack} />

          <Route
            exact
            path="/approve-nominations"
            component={ApproveNominations}
          />

          <Route
            exact
            path="/view-nominations/:empId/:id"
            component={ViewNominations}
          />

          <Route
            exact
            path="/give-feedback"
            component={GiveFeedback}
          />

        </div>
      </>
    </Suspense>
  );
};

export default AppRouters;
