import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import PropTypes from 'prop-types';


export default function SideDrawer({isOpen, setIsOpen, children}) { 

const [disappear, setDisappear] = useState(false);

function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          event.target.closest('.confirm-action')
        ) {
            setDisappear(true);
            setTimeout(() => {
              setIsOpen(false);
            }, 400);            
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const handleCloase = () => {
    setDisappear(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 400);
  }
 

  return (
          <div className={`${disappear ? "d-sidedrawer":"sidedrawer"}`} ref={wrapperRef}>

            <div className='close-drawer-icon'>
              <BsXLg onClick={() => handleCloase()} />  
            </div>

            <div className='drawer-children-main'>
              {children}
            </div>  

          </div>
        //   <React.Fragment key={'right'} >
        //   <Drawer
        //     anchor={'right'}
        //     open={isOpen}
        //     onClose={handleCloase}
        //     sx={{
        //       "& .MuiDrawer-paper":{
        //         width:"50%"
        //       }
        //     }}
        //   >
        //     <Stack sx={{margin:"1rem"}}>
        //       <GrClose 
        //       style={{
        //         fontSize:"1.25rem",
        //         fontWeight:"600",
        //         cursor:"pointer"
        //       }}
        //       onClick={() => handleCloase()} />  
        //         <Stack sx={{margin:"1rem 0px"}}>
        //         {children}
        //         </Stack>
        //       </Stack>
        //   </Drawer>
        // </React.Fragment>
  );
}

SideDrawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.node,
};