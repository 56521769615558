import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function InfoCard(props) {
  const { onClose, selectedValue, open, data } = props;

  console.log('data', data)

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle  style={{
        background: '#f4f8fa',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '28px',
        display: 'flex',
        justifyContent: 'space-between'

      }}>Your Message To Respondent
      <div onClick={() => handleClose()} style={{
        color: '#98A2B3',
        fontWeight: '400',
        cursor: 'pointer'
      }} className='close-card-respondent'>
        X
      </div>
      </DialogTitle>
        <div class="message-card-manager-info">
            <div class="message-header">
                {/* <img src="profile-picture.jpg" alt="User" class="profile-pic" /> */}
                {
                  data?.requestedBy?.profilePhoto ? (
                    <img width={'40px'} height={'40px'} src={data?.requestedBy?.profilePhoto} className="userImage" alt="User Profile" />
                  ) : (
                    <Avatar src={data?.requestedBy?.name} alt={data?.requestedBy?.name} />
                  )
                }
                <div class="user-info">
                <div class="user-name">{data?.requestedBy?.name}</div>
                <div class="user-id">Emp ID: {data?.requestedBy?.employeeId}</div>
                </div>
            </div>
            <p class="message-content">
            <div dangerouslySetInnerHTML={{ __html: data?.message }} />
                {/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. */}
            </p>
        </div>
    </Dialog>
  );
}

export default InfoCard;

InfoCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};