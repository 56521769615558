import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import notes1 from "../../../../assets/images/minus-circle.svg"
import member1 from "../../../../assets/images/member1.svg"
import member2 from "../../../../assets/images/aj.svg"
import dot from "../../../../assets/images/graydot.svg"
import send from "../../../../assets/images/send.svg"
import close from "../../../../assets/images/x-close.svg"
import notesBg from "../../../../assets/images/notesBg.png"


const Notes = ({ }) => {

    return (
        <><Grid container className="notes-card">
            <Grid item xs={12} md={12} sm={12} className="note-header">
                <Typography className="notes-label">{"Notes"}</Typography>
            </Grid>

            <Grid item xs={12} md={12} sm={12} className="note-desc-p">
                <div className="notes-bg">
                    <img src={notesBg} className="notes-bg" />
                </div>
                <Typography className="note-description">{"Please click any Output / Development Goal OR Reflection section to edit Notes"}</Typography>
            </Grid>

        </Grid>
        
        <Grid container className="notes-card mt-18">
                {/* <Grid item xs={12} md={12} sm={12} className="note-header">
                <div className="d-flex w-100 gap-20 justify-content-between">
                <Typography className="notes-label">{"Mentorship Notes"}</Typography>
                                                  <div className="contact1">
                                      <div className="mailOuter">
                                          <img className="mr-3" src={notes1} />
                                          <img src={close} />
                                      </div>
                                                                           
                                  </div>
                                
                                  </div>
                   
                </Grid> */}

                <Grid item xs={12} md={12} sm={12} className="note-sub-title ">
                    {/* <div className="notes-bg">
                        <img src={notesBg} className="notes-bg" />
                    </div> */}
                    {/* <Typography className="note-description-bold">Development of Python lo...</Typography>
                    <div className='teamList'>
                    <div className="membership-list">
                            <img src={member1} />
                            <div className="aboutInner">
                                <p className="title">Ganesh Mehta</p>
                                <div className="d-flex w-100 align-items-center">
                                                                <p className="created mb-0">You</p> <img className="dotIcon" src={dot} /> <p className="created mb-0"><span className="dateTitle">July 06, 2024</span></p>
                                                            </div>
                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise.</p>
                            </div>
                        </div>
                        <div className="membership-list">
                            <img src={member2} />
                            <div className="aboutInner">
                                <p className="title">Anil Jain</p>
                                <div className="d-flex w-100 align-items-center">
                                                                <p className="created mb-0">You</p> <img className="dotIcon" src={dot} /> <p className="created mb-0"><span className="dateTitle">July 06, 2024</span></p>
                                                            </div>
                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise.</p>
                            </div>
                        </div>
                        <div className="membership-list">
                            <img src={member1} />
                            <div className="aboutInner">
                                <p className="title">Ganesh Mehta</p>
                                <div className="d-flex w-100 align-items-center">
                                                                <p className="created mb-0">You</p> <img className="dotIcon" src={dot} /> <p className="created mb-0"><span className="dateTitle">July 06, 2024</span></p>
                                                            </div>
                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise.</p>
                            </div>
                        </div>
                        <div className="membership-list">
                            <img src={member2} />
                            <div className="aboutInner">
                                <p className="title">Anil Jain</p>
                                <div className="d-flex w-100 align-items-center">
                                                                <p className="created mb-0">You</p> <img className="dotIcon" src={dot} /> <p className="created mb-0"><span className="dateTitle">July 06, 2024</span></p>
                                                            </div>
                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise.</p>
                            </div>
                        </div>
                        <div className="membership-list">
                            <img src={member1} />
                            <div className="aboutInner border-0">
                                <p className="title">Ganesh Mehta</p>
                                <div className="d-flex w-100 align-items-center">
                                                                <p className="created mb-0">You</p> <img className="dotIcon" src={dot} /> <p className="created mb-0"><span className="dateTitle">July 06, 2024</span></p>
                                                            </div>
                                <p className="desc">Earn career credentials from industry leaders that demonstrate your expertise.</p>
                            </div>
                        </div>
                    </div>
                    <div className="msgSend">
                                    <input placeholder="Enter your comment" />
                                    <img src={send} className="send" />
                                </div> */}
                </Grid>
            </Grid></>


       
    );
};

export default React.memo(Notes);
