import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Goals from "../../../../assets/images/goals.png";
import pdfDownload from "../../../../assets/images/pdfDownload.svg";
import deleteActivity from "../../../../assets/images/deleteActivity.svg";
import closeIcon from "../../../../assets/images/cross.svg";
import { LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import { GoChevronDown } from "react-icons/go";

const OutputGoals = ({ AddInputGoals, setGoals, editedKey, prevValue }) => {
  console.log(AddInputGoals);
  const [promisedata, setPromisedata] = useState([]);
  const [editingGoal, setEditingGoal] = useState(null); // Track which goal is being edited
  const [goalInput, setGoalInput] = useState(""); // To hold the input of the goal name
  const [measureOfSuccess, setMeasureOfSuccess] = useState(""); // To hold the input for measure of success

  // useEffect(() => {
  //   // Load the data for editing when the key is 'edit'
  //   if (editedKey === "edit" && AddInputGoals?.length > 0) {
  //     const goalToEdit = prevValue.find((goal) => goal.id === AddInputGoals[0]);
  //     if (goalToEdit) {
  //       setGoalInput(goalToEdit.goalName);
  //       setMeasureOfSuccess(goalToEdit.measureOfSuccess);
  //       setIsComplete(goalToEdit.isComplete);
  //       setGoalIndex(goalToEdit.id);
  //     }
  //   }
  // }, [editedKey, AddInputGoals, prevValue]);

  useEffect(() => {
    if (editedKey === "edit" && AddInputGoals?.length > 0) {
      const goalToEdit = prevValue[0]; // Assuming you are editing the first item for now
      if (goalToEdit) {
        setGoalInput(goalToEdit.goalName);
        setMeasureOfSuccess(goalToEdit.measureOfSuccess);
      }
    } else {
      setGoalInput("");
      setMeasureOfSuccess("");
      setEditingGoal(false);
    }
  }, [editedKey, prevValue]);

  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });

  const handleCancelClick = (event) => {
    setGoalInput("");
    setMeasureOfSuccess("");
    setGoals([]); // Reset the goals
    
  };

  const handleSaveGoal = () => {
    if (editingGoal !== null) {
      // Edit mode: Update the specific goal
      setGoalInput((prevGoals) =>
        prevGoals.map((goal, index) =>
          index === editingGoal
            ? { ...goal, name: goalInput, success: measureOfSuccess }
            : goal
        )
      );
      setEditingGoal(null); // Exit edit mode after saving
    } else {
      // Add mode: Create a new goal
      setGoalInput([
        ...AddInputGoals,
        { name: goalInput, success: measureOfSuccess },
      ]);
    }
    handleCancelClick(); // Clear the form after saving
  };

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  const [showTaskTypeDiv, setShowTaskTypeDiv] = useState({
    task: true,
    course: false,
    mentorship: false,
  });

  const handleTaskTypeRadioChange = (event) => {
    const { value } = event.target;
    setShowTaskTypeDiv({
      task: value === "task",
      course: value === "course",
      mentorship: value === "mentorship",
    });
  };

  const [showYesNoDiv, setShowYesNoDiv] = useState({
    yes: true,
    no: false,
  });

  const handleYesNoRadioChange = (event) => {
    const { value } = event.target;
    setShowYesNoDiv({
      yes: value === "yes",
      no: value === "no",
    });
  };

  const [competency, setCompetency] = useState("select");

  const handleCompetencyChange = (event) => {
    setCompetency(event.target.value);
  };

  const [aspiration, setAspiration] = useState("select");

  const handleAspirationChange = (event) => {
    setAspiration(event.target.value);
  };
  return (
    <Grid container className="output-goals-card">
      {/*----- 1. Output Goals -----*/}
      {AddInputGoals?.[0] === 1 && (
        <>
          <div className="d-flex w-100 justify-content-between">
            <Typography className="output-goals">
              {"1. Output Goals"}
            </Typography>
            <div className="flex-end">
                <div className="d-flex justify-content-between">
                  <img src={deleteActivity} />
                  <Typography className="delete-activity">
                    {"Delete this activity"}
                  </Typography>
                </div>
            </div>
          </div>

          {/* <Grid item xs={12} md={6} sm={6}>
                <Typography className="output-goals">{"1. Output Goals"}</Typography>
            </Grid>
            <Grid container item xs={12} md={6} sm={6}>
          
            <div className="d-flex w-100 justify-content-between">
           
                                        <img src={deleteActivity} />
                                        <Typography className="delete-activity">{"Delete this activity"}</Typography>
            </div>
          
            </Grid> */}

          <Grid item xs={1} md={1} sm={1}>
            <Grid className="mt-15">
              <img src={Goals} alt="Goals" height={10} width={10} />
              <Grid className="vertical-border" />
            </Grid>
          </Grid>

          {/*----- Add an Output Goal -----*/}
          <Grid item xs={11} md={11} sm={11}>
            <Typography className="add-output-goals mt-15">
              {editedKey == "edit" ? "Edit Output Goal" : "Add an Output Goal"}
            </Typography>

            {/*----- Goal Name -----*/}
            <Typography className="field-label mt-15">{"Goal Name"}</Typography>
            <TextField
              name="goalName"
              variant="outlined"
              size="small"
              fullWidth
              value={goalInput}
              onChange={(e) => setGoalInput(e.target.value)}
            />

            {/*----- Measure of Success -----*/}
            <Typography className="field-label mt-15">
              {"Measure of Success"}
            </Typography>
            <TextField
              name="measureOfSuccess"
              variant="outlined"
              // size='small'
              fullWidth
              multiline
              rows={4}
              value={measureOfSuccess}
              onChange={(e) => setMeasureOfSuccess(e.target.value)}
            />
            <Typography className="field-label mt-2 pt-1">
              {"(256 characters Left)"}
            </Typography>

            {/*----- Mark as Complete Checkbox -----*/}
            <FormGroup className="mt-15">
              <FormControlLabel
                className="markedLabel"
                control={<Checkbox defaultChecked />}
                label="Mark as Complete"
              />
            </FormGroup>
          </Grid>
          {/*----- Cancel & Save Goal Btn -----*/}
          <Grid item xs={12} md={12} sm={12} className="mt-15 ta-end">
            <Button className="cancel-btn" onClick={handleCancelClick}>
              {"Cancel"}
            </Button>
            <Button className="save-goal-btn">{"Save Goal"}</Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default React.memo(OutputGoals);
